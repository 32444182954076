import { forwardRef, ComponentPropsWithoutRef } from 'react';
import { Warehouse } from '@views/WarehouseDetails/WarehouseDetails';
import { Text } from '@components/Text';
import { useMediaQuery, useReadLocalStorage } from 'usehooks-ts';
import { INVENTORY_IMAGES_URL, WAREHOUSE_IMAGES_URL } from '@constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { getNoticeColors } from '@views/Noticeboard/utils';
import { Tooltip } from '@mui/material';
import {
  StyledWarehouseList,
  StyledWarehouseCard,
  StyledWarehouseCardImageContainer,
  StyledWarehouseCardInfo,
  StyledWarehouseLoading,
  StyledWarehouseLoadingIndicator,
} from './WarehouseList.styles';

interface WarehouseListProps extends ComponentPropsWithoutRef<'div'> {
  id?: string;
}

export const WarehouseList = forwardRef<HTMLDivElement, WarehouseListProps>(
  ({ children, id = 'warehouse-list' }, forwardedRef) => {
    return (
      <StyledWarehouseList id={id} ref={forwardedRef}>
        {children}
      </StyledWarehouseList>
    );
  }
);

const warehouseTooltip = (warehouseSummary: Warehouse) => {
  if (warehouseSummary.isDelisted) {
    return (
      <Tooltip title='Warehouse is delisted'>
        <ErrorIcon
          sx={{
            width: 16,
            height: 16,
            fill: getNoticeColors('error').fill,
            position: 'absolute',
            bottom: 5,
            right: 5,
          }}
        />
      </Tooltip>
    );
  }
  if (warehouseSummary.isDelisted) {
    return (
      <Tooltip title='Warehouse is delisted'>
        <ErrorIcon
          sx={{
            width: 16,
            height: 16,
            fill: getNoticeColors('error').fill,
            position: 'absolute',
            bottom: 5,
            right: 5,
          }}
        />
      </Tooltip>
    );
  }

  if (warehouseSummary.isLspBusinessDeactivated) {
    return (
      <Tooltip title='Warehouse belongs to a business which is currently deactivated'>
        <ErrorIcon
          sx={{
            width: 16,
            height: 16,
            fill: getNoticeColors('error').fill,
            position: 'absolute',
            bottom: 5,
            right: 5,
          }}
        />
      </Tooltip>
    );
  }
  if (warehouseSummary.isFull) {
    return (
      <Tooltip title='Warehouse is full'>
        <ErrorIcon
          sx={{
            width: 16,
            height: 16,
            fill: getNoticeColors('warning').fill,
            position: 'absolute',
            bottom: 5,
            right: 5,
          }}
        />
      </Tooltip>
    );
  }
  if (
    warehouseSummary.authenticity.authenticityLevel !== 'High' &&
    warehouseSummary.authenticity.authenticityLevel !== 'Very High' &&
    !(
      warehouseSummary.authenticity.wasSuccessfulAudit &&
      warehouseSummary.authenticity.authenticityLevel === 'Unvetted'
    )
  ) {
    return (
      <Tooltip title='Warehouse is quarantined'>
        <ErrorIcon
          sx={{
            width: 16,
            height: 16,
            fill: getNoticeColors('warning').fill,
            position: 'absolute',
            bottom: 5,
            right: 5,
          }}
        />
      </Tooltip>
    );
  }
  return (
    <Tooltip title='Warehouse is live'>
      <CheckCircleIcon
        sx={{
          width: 16,
          height: 16,
          fill: 'var(--colors-green9)',
          position: 'absolute',
          bottom: 5,
          right: 5,
        }}
      />
    </Tooltip>
  );
};

WarehouseList.displayName = 'WarehouseList';

interface WarehouseCardProps extends ComponentPropsWithoutRef<'div'> {
  warehouseSummary: Warehouse;
  orientation: 'vertical' | 'horizontal';
  onClick: () => void;
}

const warehouseImagePlaceholder = `/images/warehouse_details_placeholder.png`;

export const WarehouseCard = forwardRef<HTMLDivElement, WarehouseCardProps>(
  ({ warehouseSummary, orientation, onClick, ...props }, forwardedRef) => {
    const isLargeDesktop = useMediaQuery('(min-width: 75em)');
    const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');
    return (
      <StyledWarehouseCard
        orientation={orientation}
        onClick={onClick}
        {...props}
        ref={forwardedRef}
      >
        <StyledWarehouseCardImageContainer
          css={{
            backgroundImage: `url(${
              warehouseSummary.images && warehouseSummary.images.length
                ? `${WAREHOUSE_IMAGES_URL}/${warehouseSummary.images[0].imageLocation}/small/${warehouseSummary.images[0].remoteName}`
                : warehouseImagePlaceholder
            })`,

            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            aspectRatio: 1,
          }}
        />
        <StyledWarehouseCardInfo orientation={orientation}>
          <Text size={isLargeDesktop ? 'sm' : 'xs'} weight='bold'>
            {warehouseSummary.name}
          </Text>
          <Text size='xs'>
            <Text as='span' size='xs' weight='bold'>
              Address:{' '}
            </Text>
            {warehouseSummary.address}
          </Text>
          <Text size='xs'>
            <Text as='span' size='xs' weight='bold'>
              Business:{' '}
            </Text>
            {warehouseSummary.businessName
              ? warehouseSummary.businessName
              : warehouseSummary.userBusinessName}
          </Text>
          <Text size='xs'>
            <Text as='span' size='xs' weight='bold'>
              Available capacity:{' '}
            </Text>
            {warehouseSummary.currentlyAvailableCapacity}{' '}
            {warehouseSummary.storageCapacityUnitOfMeasure}
          </Text>
        </StyledWarehouseCardInfo>
        {warehouseTooltip(warehouseSummary)}
      </StyledWarehouseCard>
    );
  }
);

WarehouseCard.displayName = 'WarehouseCard';

export function WarehouseLoading() {
  return (
    <StyledWarehouseLoading>
      <StyledWarehouseLoadingIndicator />
      <Text
        size='xl'
        weight='bold'
        css={{ color: 'inherit', textAlign: 'center' }}
      >
        Fetching Warehouse details...
      </Text>
    </StyledWarehouseLoading>
  );
}

WarehouseLoading.displayName = 'WarehouseLoading';
