import {
  ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import Box from '@mui/material/Box';
import { SxProps, Typography } from '@mui/material';
import {
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { AxiosResponse } from 'axios';
import { BusinessProfile } from '@views/Profile/Profile';
import { StarterChecklistDto } from '@components/StarterChecklist/StarterChecklist';
import { StarterChecklist } from '@components/StarterChecklist';
import { Notification } from '@components/Notification';
import { NotificationType } from '@components/Notification/Notification';
import {
  backendVersionAtom,
  businessProfileAtom,
  hasResponseErrorAtom,
  responseErrorMessagesAtom,
  confirmationMessageAtom,
  isPublicPageAtom,
  notificationsAtom,
  showNotificationAtom,
  versionAtom,
} from '../../store/jotai';
import { useAuthHttp } from '../../hooks';
import {
  BACKEND_VERSION,
  STARTER_CHECKLIST_GET,
  GET_NOTIFICATIONS,
  VERSION,
  BUSINESS_PROFILE_GET,
} from '../../constants';

interface ViewProps extends ComponentPropsWithoutRef<'div'> {
  sx?: SxProps;
}

export function View({ children, sx, ...props }: ViewProps) {
  const authHttp = useAuthHttp();
  const [notifications, setNotifications] = useAtom(notificationsAtom);
  const [version, setVersionAtom] = useAtom(versionAtom);
  const [backendVersion, setBackendVersionAtom] = useAtom(backendVersionAtom);
  const [hasResponseError, setHasResponseError] = useAtom(hasResponseErrorAtom);
  const [responseErrorMessages, setResponseErrorMessages] = useAtom(
    responseErrorMessagesAtom
  );
  const [confirmationMessage, setConfirmationMessage] = useAtom(
    confirmationMessageAtom
  );
  const [starterChecklist, setStarterChecklist] =
    useState<StarterChecklistDto>();
  const [showNotification, setShowNotification] = useAtom(showNotificationAtom);
  const isPublicPage = useAtomValue(isPublicPageAtom);
  const setBusinessProfile = useSetAtom(businessProfileAtom);

  const fetchBusinessProfile = async () => {
    try {
      const { data }: AxiosResponse<BusinessProfile> = await authHttp.get(
        BUSINESS_PROFILE_GET
      );

      if (data) {
        setBusinessProfile(data.businessProfile);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    // if (import.meta.env.MODE === 'development') return;
    if (isPublicPage) return;
    async function fetchVersion() {
      try {
        const res = await fetch(VERSION);

        const data = await res.text();

        if (data) setVersionAtom(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error: ', error);
      }
    }
    async function fetchBackendVersion() {
      try {
        const res = await fetch(BACKEND_VERSION);

        const data = await res.text();

        if (data) setBackendVersionAtom(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error: ', error);
      }
    }
    async function fetchNotifications() {
      try {
        const { data } = await authHttp.post<{
          notifications: NotificationType[];
        }>(GET_NOTIFICATIONS, {
          pagination: {
            lastItem: null,
            itemsPerPage: 50,
          },
          isExcludingSilentNotifications: true,
        });

        if (data) setNotifications(data.notifications);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
    fetchVersion();
    fetchBackendVersion();
    fetchNotifications();
    fetchBusinessProfile();
  }, []);

  const fetchStarterChecklist = useCallback(async () => {
    try {
      const { data } = await authHttp.get(STARTER_CHECKLIST_GET);
      if (data.starterChecklist) {
        setStarterChecklist(data.starterChecklist);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching starter checklist:', error);
    }
  }, [authHttp]);

  useEffect(() => {
    if (isPublicPage) return;
    if (!starterChecklist) {
      fetchStarterChecklist();
    }
  }, [starterChecklist]);

  useEffect(() => {
    const notification = notifications[0];
    if (
      notification &&
      notification.notificationType === 'StarterChecklist.Updated'
    ) {
      fetchStarterChecklist();
    }
  }, [notifications]);

  useEffect(() => {
    if (notifications.length > 0) {
      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
  }, [showNotification]);

  return (
    <Box
      component='main'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        minHeight: {
          xs: 'calc(100vh - 4rem)',
          lg: 'calc(100vh - 5rem)',
        },
        p: 16,
        pt: {
          lg: 16,
        },
        px: {
          lg: 32,
        },
        pb: {
          lg: 32,
        },
        position: 'relative',
        ...sx,
      }}
      {...props}
    >
      {children}
      {import.meta.env.MODE !== 'development' && (
        <Typography
          fontSize='0.8rem'
          position='absolute'
          bottom='1%'
          right='1%'
          color='lightgray'
        >
          Version - FE: v{version} / BE: v{backendVersion}
        </Typography>
      )}
      {confirmationMessage && (
        <div className='rounded-md bg-green-50 p-4 fixed bottom-5 left-1/2 right-1/2 transform translate-x-[-50%] w-1/2 z-50'>
          <div className='flex'>
            <div className='flex-shrink-0'>
              <CheckCircleIcon
                aria-hidden='true'
                className='h-5 w-5 text-green-400'
              />
            </div>
            <div className='ml-3'>
              <div className='text-sm text-green-700'>
                <p>{confirmationMessage}</p>
              </div>
            </div>
            <div className='ml-auto pl-3'>
              <div className='-mx-1.5 -my-1.5'>
                <button
                  type='button'
                  className='inline-flex rounded-md bg-green-50 p-1.5 text-green-600 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50'
                  onClick={() => {
                    setConfirmationMessage('');
                  }}
                >
                  <span className='sr-only'>Dismiss</span>
                  <XMarkIcon aria-hidden='true' className='h-5 w-5' />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {hasResponseError && (
        <div className='rounded-md bg-red-50 p-4 fixed bottom-5 left-1/2 right-1/2 transform translate-x-[-50%] w-1/2 z-50'>
          <div className='flex'>
            <div className='flex-shrink-0'>
              <XCircleIcon
                aria-hidden='true'
                className='h-5 w-5 text-red-400'
              />
            </div>
            <div className='ml-3'>
              <div className='text-sm text-yellow-700'>
                {responseErrorMessages &&
                  responseErrorMessages.length === 1 && (
                    <p>{responseErrorMessages[0]}</p>
                  )}
                {responseErrorMessages && responseErrorMessages.length > 1 && (
                  <>
                    <p>Something has gone wrong:</p>
                    {responseErrorMessages.map((message) => (
                      // eslint-disable-next-line react/jsx-key
                      <p>- {message}</p>
                    ))}
                  </>
                )}
                {(!responseErrorMessages ||
                  responseErrorMessages.length < 1) && (
                  <p>
                    Something has gone wrong. If the error persists, please
                    contact us.
                  </p>
                )}
              </div>
            </div>
            <div className='ml-auto pl-3'>
              <div className='-mx-1.5 -my-1.5'>
                <button
                  type='button'
                  className='inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50'
                  onClick={() => {
                    setHasResponseError(false);
                    setResponseErrorMessages([]);
                  }}
                >
                  <span className='sr-only'>Dismiss</span>
                  <XMarkIcon aria-hidden='true' className='h-5 w-5' />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {starterChecklist && (
        <StarterChecklist starterChecklist={starterChecklist} />
      )}
      <Notification
        notification={notifications[0]}
        show={showNotification}
        setShow={() => setShowNotification(false)}
      />
    </Box>
  );
}

View.displayName = 'View';
