import { Heading } from '@components/Heading';
import { Autocomplete, Button, Stack, TextField } from '@mui/material';
import { View } from '@views/View';
import { useSearchParams } from 'react-router-dom';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useMediaQuery } from 'usehooks-ts';
import { SyntheticEvent, useEffect, useState } from 'react';
// import Map from './Map';
import { HERE_APIKEY, WAREHOUSE_SEARCH_URL } from '../../constants';
import { useAuthHttp } from '../../hooks';
import { FiltersSideDrawer } from './FiltersSideDrawer';
import { Mapbox } from './Mapbox';

const WAREHOUSE_OPERATIONS = [
  'Import/Export Documentation',
  'Container Devanning and Sub-sorting',
  'Shrink Wrapping',
  'Waste Disposal',
  'Flexible Operations Schedule',
];

const ADDITIONAL_SERVICES = [
  'Re-packaging, Customised Labelling, Light Assembly & Documentation',
  'Re-processing and Quality Control Programs',
  'Return processing and Management',
  'Contract Packing',
  'Procurement and Management of All Packaging Materials',
  'Product Kitting and Repackaging',
  'Contract Manufacturing',
  'Hazardous Goods handling',
  'Special Projects',
];

const SYSTEMS = ['WMS', 'TMS', 'WiFi', 'API', 'EDI', 'Other'];

const SAFETY_AND_SECURITY = [
  '24/7 Security',
  '24/7 Access',
  'Bunded',
  'Caged',
  'Sprinklered',
  'Alarmed',
  'Patrolled',
  'Fenced Yard',
  'CCTV',
  'ESFR Sprinkler System',
];

const filtersIcon = <FilterListIcon fontSize='small' />;

export function WarehouseSearch() {
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const authHttp = useAuthHttp();
  const [location, setLocation] = useState<{
    lat: number | null;
    lon: number | null;
  }>({
    lat: null,
    lon: null,
  });
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [mapPosition, setMapPosition] = useState<{
    lat: number;
    lon: number;
  } | null>({ lat: 54.3781, lon: -2.436 });
  const [zoomLevel, setZoomLevel] = useState(5);
  const [warehouseData, setWarehouseData] = useState(null);
  const [searchParams] = useSearchParams();
  const [currency, setCurrency] = useState('');
  const [specialCapabilities, setSpecialCapabilities] = useState<string[]>([]);
  const [valueAddingServices, setValueAddingServices] = useState<string[]>([]);
  const [systemsAndSecurity, setSystemsAndSecurity] = useState<string[]>([]);
  const [fromPrice, setFromPrice] = useState('');
  const [toPrice, setToPrice] = useState('');
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  // const [coreService, setCoreService] = useState('leasableStorageSpaceOnly');

  const locationParam = searchParams.get('location') || '';
  const latParam = searchParams.get('lat') || null;
  const lngParam = searchParams.get('lng') || null;

  const handleFetchWarehouseData = async (
    _location?: {
      lat: number;
      lon: number;
    },
    radius = 250
  ) => {
    try {
      const { data: fetchedWarehouseData } = await authHttp.post(
        WAREHOUSE_SEARCH_URL,
        {
          location: _location ?? location,
          distanceUnit: 'km',
          radius,
          pagination: {
            pageNumber: 0,
            itemsPerPage: 5000,
          },
          warehouseOperations: valueAddingServices.filter((service) =>
            WAREHOUSE_OPERATIONS.includes(service)
          ),
          additionalServices: valueAddingServices.filter((service) =>
            ADDITIONAL_SERVICES.includes(service)
          ),
          storageCapabilites: specialCapabilities,
          systemsAvailable: systemsAndSecurity.filter((system) =>
            SYSTEMS.includes(system)
          ),
          safetyAndSecurity: systemsAndSecurity.filter((system) =>
            SAFETY_AND_SECURITY.includes(system)
          ),
          currency,
          minSquareFeetStoragePricePerWeek: fromPrice
            ? Number(fromPrice)
            : null,
          maxSquareFeetStoragePricePerWeek: toPrice ? Number(toPrice) : null,
        }
      );

      if (fetchedWarehouseData) setWarehouseData(fetchedWarehouseData);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error: ', error);
    }
  };

  const handleInputChange = (
    event: SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => {
    setInputValue(newInputValue);
  };

  const handleFetchSuggestions = (input: string) => {
    fetch(
      `https://autosuggest.search.hereapi.com/v1/autosuggest?apiKey=${HERE_APIKEY}&q=${input}&at=52.93175,12.77165`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.items) {
          const items = data.items.map(
            (item: { id: any; address: { label: any } }) => ({
              id: item.id,
              label: item.address.label,
            })
          );
          setOptions(items);
        }
      })
      .catch((error) => {
        console.error('Error fetching suggestions:', error);
      });
  };

  const handleOptionSelect = async (event: any, value: any) => {
    if (value && value.id) {
      try {
        const response = await fetch(
          `https://lookup.search.hereapi.com/v1/lookup?id=${value.id}&apiKey=${HERE_APIKEY}`
        );

        const data = await response.json();

        if (data) {
          const { position } = data;

          if (position) {
            setMapPosition({ lat: position.lat, lon: position.lng });
            setZoomLevel(10);
            const { lat, lon } = position;

            setLocation({
              lat,
              lon,
            });

            handleFetchWarehouseData({ lat, lon });
          }
        }
      } catch (error) {
        console.error('Error fetching position information:', error);
      }
    }
  };

  useEffect(() => {
    if (!latParam || !lngParam) return;
    handleFetchWarehouseData({ lat: Number(latParam), lon: Number(lngParam) });
  }, [latParam, lngParam]);

  useEffect(() => {
    if (warehouseData) return;
    handleFetchWarehouseData({ lat: 54.3781, lon: -2.436 }, 150000);
  }, [warehouseData]);

  const handleFilterClick = () => {
    setIsFiltersOpen(false);
    handleFetchWarehouseData();
  };

  return (
    <View>
      <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
        Warehouse Search
      </Heading>
      <Stack spacing={20}>
        <Stack sx={{ flexDirection: { xs: 'column', md: 'row' } }} gap={24}>
          <Autocomplete
            freeSolo
            disablePortal
            options={options}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            filterOptions={(options, state) => options}
            onInputChange={(event, newInputValue) => {
              handleInputChange(event, newInputValue);
              handleFetchSuggestions(newInputValue);
            }}
            onChange={handleOptionSelect}
            renderInput={(params) => (
              <TextField {...params} label='Enter required location/region' />
            )}
            defaultValue={{ label: locationParam }}
            sx={{ flex: '2' }}
          />
          <Button
            onClick={() => setIsFiltersOpen(true)}
            sx={{ width: { xs: '100%', md: '150px' } }}
            variant='contained'
            endIcon={filtersIcon}
          >
            Filters
          </Button>
        </Stack>
        <Mapbox
          warehouseData={warehouseData}
          mapPosition={
            mapPosition ??
            (latParam !== null && lngParam !== null
              ? { lat: Number(latParam), lon: Number(lngParam) }
              : null)
          }
          zoomLevel={zoomLevel}
        />
      </Stack>
      <FiltersSideDrawer
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        isDesktop={isDesktop}
        currency={currency}
        setCurrency={setCurrency}
        specialCapabilities={specialCapabilities}
        setSpecialCapabilities={setSpecialCapabilities}
        valueAddingServices={valueAddingServices}
        setValueAddingServices={setValueAddingServices}
        systemsAndSecurity={systemsAndSecurity}
        setSystemsAndSecurity={setSystemsAndSecurity}
        fromPrice={fromPrice}
        setFromPrice={setFromPrice}
        toPrice={toPrice}
        setToPrice={setToPrice}
        handleFilterClick={handleFilterClick}
      />
    </View>
  );
}
