import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@store';
import { useCloseDialog } from '@hooks';
import {
  setSelectedInboundProcess as _setSelectedInboundProcess,
  setSelectedProcessChildType as _setSelectedProcessChildType,
} from '@features/supplyChain';
import { InboundProcess } from '@features/supplyChain/types';

export const useInboundProcess = () => {
  const dispatch = useAppDispatch();

  const {
    supplyChain: { inboundProcesses, selectedProcessChildType },
  } = useAppSelector((state) => state.supplyChain);

  const hasMoreInboundData = useAppSelector(
    (state) => state.supplyChain.supplyChain.hasMoreInboundData
  );

  // const [selectedInboundProcess, setSelectedInboundProcess] =
  //   useState<InboundProcess | null>(null);

  const {
    supplyChain: { selectedInboundProcess },
  } = useAppSelector((state) => state.supplyChain);

  const setSelectedInboundProcess = (payload: InboundProcess | null) => {
    dispatch(_setSelectedInboundProcess(payload));
  };

  const setSelectedProcessChildType = (payload: string | null) => {
    dispatch(_setSelectedProcessChildType(payload));
  };

  const selectedInboundProcessHasDescription = Boolean(
    selectedInboundProcess &&
      selectedInboundProcess?.description &&
      selectedInboundProcess?.description?.length > 0
  );

  const getInboundScrollParent = () =>
    document.getElementById('inbound-list') as HTMLElement;

  const handleScrollToTopOfInbound = () => {
    getInboundScrollParent().scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [isViewShipmentNoteDialogOpen, setIsViewShipmentNoteDialogOpen] =
    useState(false);

  const handleCloseViewShipmentNote = useCloseDialog(
    setIsViewShipmentNoteDialogOpen
  );

  const [
    isViewGoodsReceivedNoteDialogOpen,
    setIsViewGoodsReceivedNoteDialogOpen,
  ] = useState(false);

  const handleCloseViewGoodsReceivedNote = useCloseDialog(
    setIsViewGoodsReceivedNoteDialogOpen
  );

  return {
    inboundProcesses,
    hasMoreInboundData,
    selectedInboundProcess,
    selectedProcessChildType,
    setSelectedInboundProcess,
    selectedInboundProcessHasDescription,
    handleScrollToTopOfInbound,
    isViewShipmentNoteDialogOpen,
    setIsViewShipmentNoteDialogOpen,
    handleCloseViewShipmentNote,
    isViewGoodsReceivedNoteDialogOpen,
    setIsViewGoodsReceivedNoteDialogOpen,
    handleCloseViewGoodsReceivedNote,
    setSelectedProcessChildType,
  };
};
