/* eslint-disable no-nested-ternary */
import { Button } from '@components/Button';
import { Dropzone } from '@components/Dropzone';
import { Heading } from '@components/Heading';
import { InputGroup } from '@components/InputGroup';
import { Label } from '@components/Label';
import { Select, SelectItem } from '@components/Select';
import { Text } from '@components/Text';
import { Thumbnail, ThumbnailGrid } from '@components/Thumbnail';
import { UserImage } from '@forms/client/AddProductForm/types';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
} from '@mui/material';
import { ChangeEvent, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MdAdd, MdDelete } from 'react-icons/md';
import { useMediaQuery } from 'usehooks-ts';
import { COUNTRY_CODES, WAREHOUSE_IMAGES_URL } from '../../../../constants';

const TEMPERATURE_CONTROLS = [
  {
    label: 'Ambient',
    value: 'Ambient',
  },
  {
    label: 'Chilled',
    value: 'Chilled',
  },
  {
    label: 'Frozen',
    value: 'Frozen',
  },
  {
    label: 'Mixed',
    value: 'Mixed',
  },
];

const TEMPERATURE_MONITORING = [
  'Temperature Monitoring',
  'Humidity Monitoring',
  'Total Control System',
];

const CAPACITY_UOM = [
  {
    label: 'Pallets',
    value: 'Pallets',
  },
  {
    label: 'Sq.ft',
    value: 'Square Foot',
  },
  {
    label: 'Sq.m',
    value: 'Square Metre',
  },
];

const OFFICE_TYPES = [
  {
    label: 'Off dock',
    value: 'offDock',
  },
  {
    label: 'Quay',
    value: 'quay',
  },
];

const SERVICE_TIME_UNITS = [
  {
    label: 'Week(s)',
    value: 'Week',
  },
  {
    label: 'Month(s)',
    value: 'Month',
  },
  {
    label: 'Year(s)',
    value: 'Year',
  },
];

export function CapacityAndCharacteristics() {
  const { control, getValues, setValue, watch, register } = useFormContext();
  const watchCapacityUoM = watch('storageCapacityUnitOfMeasure');
  const watchServiceTimeUnit = watch('storageTimeUnitOfMeasure');
  const watchHasOnsiteOffice = watch('hasOnsiteOffice');
  const watchMaxStorageCapacity = watch('maxStorageCapacity');
  const watchMaxStorageCapacityAvailable = watch('maxStorageCapacityAvailable');
  // For some reason we need a watch for siteImages to actually react in the UI. Dont delete
  const watchSiteImages = watch('siteImages');
  const watchImages = watch('images');
  const isSmall = useMediaQuery('(min-width: 36em)');
  const isDesktop = useMediaQuery('(min-width: 62em)');

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    const temperatureMonitoring = getValues(
      'temperatureMonitoringCapeabilities'
    );
    if (checked) {
      setValue('temperatureMonitoringCapeabilities', [
        ...temperatureMonitoring,
        value,
      ]);
    } else {
      const filteredAnswers = (temperatureMonitoring as string[]).filter(
        (answer) => answer !== value
      );

      setValue('temperatureMonitoringCapeabilities', filteredAnswers);
    }
  };

  // useEffect(() => {
  //   setValue('maxStorageCapacityAvailable', watchMaxStorageCapacity);
  //   setValue('currentlyAvailableCapacity', watchMaxStorageCapacityAvailable);
  // }, [watchMaxStorageCapacity, watchMaxStorageCapacityAvailable]);

  useEffect(() => {
    if (watchHasOnsiteOffice) return;
    setValue('siteOfficePhoneNumber', '');
  }, [watchHasOnsiteOffice]);

  return (
    <Stack spacing={16}>
      <Heading as='h3' size='sm'>
        Capacity & Characteristics
      </Heading>
      <InputGroup
        direction='column'
        css={{
          gap: '$space16',
          padding: '$space12 $space0',
        }}
      >
        <Label
          htmlFor='temperatureControl'
          css={{ fontSize: '$textmd', fontWeight: 'bold' }}
        >
          Temperature control *
        </Label>
        <Controller
          name='temperatureControlTypes'
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <Select
                onValueChange={field.onChange}
                defaultValue={getValues(`temperatureControlTypes`)}
                onBlur={field.onBlur}
                placeholder='Please select'
                css={{ width: '25%' }}
                isInvalid={Boolean(error)}
              >
                {TEMPERATURE_CONTROLS.map((tempControl) => (
                  <SelectItem key={tempControl.label} value={tempControl.value}>
                    {`${tempControl.label}`}
                  </SelectItem>
                ))}
              </Select>
            );
          }}
        />
      </InputGroup>
      <InputGroup
        direction='column'
        css={{
          gap: '$space16',
          padding: '$space2',
        }}
      >
        <Label
          htmlFor='temperatureControl'
          css={{ fontSize: '$textmd', fontWeight: 'bold' }}
        >
          Temperature monitoring
        </Label>
        <Controller
          name='temperatureMonitoringCapeabilities'
          control={control}
          render={({ field }) => {
            return (
              <FormGroup>
                {TEMPERATURE_MONITORING.map((option) => (
                  <FormControlLabel
                    key={option}
                    control={
                      <Checkbox
                        checked={
                          (field.value as string[])?.includes(option) || false
                        }
                        onChange={handleCheckboxChange}
                        name={field.name}
                        value={option}
                      />
                    }
                    label={option}
                  />
                ))}
              </FormGroup>
            );
          }}
        />
      </InputGroup>
      <Heading as='h4' size='xs'>
        Capacity availability
      </Heading>
      <InputGroup
        direction='column'
        css={{
          gap: '$space16',
          padding: '$space12 $space0',
        }}
      >
        <Label
          htmlFor='storageCapacityUnitOfMeasure'
          css={{ fontSize: '$textmd', fontWeight: 'bold' }}
        >
          Capacity UoM (Unit of Measure) *
        </Label>
        <Controller
          name='storageCapacityUnitOfMeasure'
          control={control}
          render={({ field }) => {
            return (
              <Select
                onValueChange={field.onChange}
                defaultValue={
                  getValues(`storageCapacityUnitOfMeasure`) ?? 'pallets'
                }
                onBlur={field.onBlur}
                placeholder='Capacity UoM'
                css={{ width: '25%' }}
              >
                {CAPACITY_UOM.map((capacityUoM) => (
                  <SelectItem key={capacityUoM.label} value={capacityUoM.value}>
                    {`${capacityUoM.label}`}
                  </SelectItem>
                ))}
              </Select>
            );
          }}
        />
      </InputGroup>
      <Controller
        name='maxStorageCapacity'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label={`Max storage capacity (in ${
              watchCapacityUoM ?? 'pallets or sq.ft or sq.m'
            }) *`}
            placeholder={`Max storage capacity (in ${
              watchCapacityUoM ?? 'pallets or sq.ft or sq.m'
            }) *`}
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='maxStorageCapacityAvailable'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label={`Max capacity available for shared use via FLOX (in ${
              watchCapacityUoM ?? 'pallets or sq.ft or sq.m'
            }) *`}
            placeholder={`Max capacity available for shared use via FLOX (in ${
              watchCapacityUoM ?? 'pallets or sq.ft or sq.m'
            }) *`}
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='currentlyAvailableCapacity'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label={`Remaining available for new clients (in ${watchCapacityUoM}) *`}
            placeholder={`Remaining available for new clients (in ${watchCapacityUoM}) *`}
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Heading as='h4' size='xs'>
        Service terms
      </Heading>
      <Controller
        name='minStorageCapacityThreshold'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label={`Minimum capacity requirement (in ${watchCapacityUoM}) *`}
            placeholder={`Minimum capacity requirement (in ${watchCapacityUoM}) *`}
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <InputGroup
        direction='column'
        css={{
          gap: '$space16',
          padding: '$space12 $space0',
        }}
      >
        <Label
          htmlFor='storageTimeUnitOfMeasure'
          css={{ fontSize: '$textmd', fontWeight: 'bold' }}
        >
          Service time unit
        </Label>
        <Controller
          name='storageTimeUnitOfMeasure'
          control={control}
          render={({ field }) => {
            return (
              <Select
                onValueChange={field.onChange}
                defaultValue={getValues(`storageTimeUnitOfMeasure`) ?? 'weeks'}
                onBlur={field.onBlur}
                placeholder='Service time unit'
                css={{ width: '25%' }}
              >
                {SERVICE_TIME_UNITS.map((unit) => (
                  <SelectItem key={unit.label} value={unit.value}>
                    {`${unit.label}`}
                  </SelectItem>
                ))}
              </Select>
            );
          }}
        />
      </InputGroup>
      <Controller
        name='minStorageTimeThreshold'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label={`Minimum term (in ${watchServiceTimeUnit}) *`}
            placeholder={`Minimum term (in ${watchServiceTimeUnit}) *`}
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <InputGroup
        direction='column'
        css={{
          gap: '$space16',
          padding: '$space12 $space0',
        }}
      >
        <Label
          htmlFor='officeType'
          css={{ fontSize: '$textmd', fontWeight: 'bold' }}
        >
          Facilities
        </Label>
        <Controller
          name='officeType'
          control={control}
          render={({ field }) => {
            return (
              <Select
                onValueChange={field.onChange}
                defaultValue={getValues(`officeType`) ?? 'offDock'}
                onBlur={field.onBlur}
                placeholder='Office type'
                css={{ width: '25%' }}
              >
                {OFFICE_TYPES.map((type) => (
                  <SelectItem key={type.label} value={type.value}>
                    {`${type.label}`}
                  </SelectItem>
                ))}
              </Select>
            );
          }}
        />
      </InputGroup>
      <Controller
        name='loadingDocks'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='# of Loading bays *'
            placeholder='# of Loading bays *'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <InputGroup
        direction='row'
        css={{
          gap: '$space16',
          padding: '$space2',
        }}
      >
        <Controller
          name='hasOnsiteOffice'
          control={control}
          render={({ field }) => {
            return (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={(
                        event: ChangeEvent<HTMLInputElement>,
                        checked: boolean
                      ) => {
                        setValue('hasOnsiteOffice', checked, {
                          shouldValidate: true,
                        });
                      }}
                      name={field.name}
                    />
                  }
                  label='Onsite office'
                />
              </FormGroup>
            );
          }}
        />
      </InputGroup>
      {watchHasOnsiteOffice && (
        <InputGroup css={{ gap: '$space0' }}>
          <Label htmlFor='onsiteOfficePhoneNumber' visuallyHidden>
            Onsite office phone number
          </Label>
          <div className='w-full'>
            <div className='flex rounded-lg bg-white outline outline-1 -outline-offset-1 outline-gray-300 has-[input:focus-within]:outline has-[input:focus-within]:outline-2 has-[input:focus-within]:-outline-offset-2 has-[input:focus-within]:outline-gray-600'>
              <div className='grid shrink-0 grid-cols-1 focus-within:relative'>
                <Controller
                  name='onsitePhoneCountry'
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <select
                      {...field}
                      id='onsitePhoneCountry'
                      autoComplete='onsitePhoneCountry'
                      aria-label='Country'
                      className='col-start-1 row-start-1 w-full appearance-none rounded-lg py-1.5 pl-3 pr-7 text-base text-gray-500 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-gray-600 focus:shadow-none focus:ring-offset-0 focus:ring-0 sm:text-sm/6 border-none'
                    >
                      {COUNTRY_CODES.map((country) => (
                        <option
                          value={country.country_code}
                          key={country.country_code}
                        >
                          {country.country_code} +{country.phone_code}
                        </option>
                      ))}
                    </select>
                  )}
                />
              </div>
              <Controller
                name='onsiteOfficePhoneNumber'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    // label='Site office phone number'
                    placeholder='Site office phone number'
                    sx={{
                      width: '100%',
                    }}
                  />
                )}
              />
            </div>
          </div>
        </InputGroup>
      )}
      <div style={{ width: '100%' }}>
        <Text as='h4' size='lg' weight='bold' css={{ mb: '$space16' }}>
          Site images
        </Text>
        <ThumbnailGrid>
          {(getValues().siteImages.length > 0 ||
            getValues().images?.length > 0) && (
            <>
              {getValues()
                .siteImages.map((img: UserImage) => ({
                  preview: URL.createObjectURL(img.image),
                  title: img.title,
                  id: img.id,
                }))
                .concat(
                  getValues().images
                    ? getValues().images.map((img: any) => ({
                        preview: `${WAREHOUSE_IMAGES_URL}/${img.imageLocation}/small/${img.remoteName}`,
                        title: img.title,
                        id: img.id,
                      }))
                    : []
                )
                .map((img: any, index: number) => (
                  <Thumbnail
                    key={`${img.title}${index.toString()}`}
                    src={img.preview}
                  >
                    <Button
                      size='xs'
                      action='danger'
                      leftIcon={<MdDelete size='1rem' />}
                      onClick={() => {
                        if (
                          getValues().siteImages.some(
                            (i: any) => i.id === img.id
                          )
                        ) {
                          setValue(
                            'siteImages',
                            getValues().siteImages.filter(
                              (i: any) => i.id !== img.id
                            )
                          );
                        }

                        if (
                          getValues().images.some((i: any) => i.id === img.id)
                        ) {
                          setValue(
                            'images',
                            getValues().images.filter(
                              (i: any) => i.id !== img.id
                            )
                          );

                          setValue(
                            'deletedImagesIds',
                            getValues().deletedImagesIds.concat(img.id)
                          );
                        }
                      }}
                      css={{
                        position: 'absolute',
                        bottom: '$space8',
                        right: '$space8',
                      }}
                    >
                      {/* Delete */}
                    </Button>
                  </Thumbnail>
                ))}
            </>
          )}
          <InputGroup css={{ gap: '$space0', mb: '$space16' }}>
            <>
              <Label htmlFor='images' visuallyHidden>
                Images
              </Label>
              <Controller
                name='siteImages'
                control={control}
                render={() => {
                  return (
                    <Dropzone
                      {...register('siteImages')}
                      id='images'
                      primaryAction={
                        <Button
                          size={isDesktop ? 'xs' : isSmall ? 'sm' : 'xs'}
                          leftIcon={
                            <MdAdd size={isSmall ? '1.25rem' : '1rem'} />
                          }
                        >
                          Add image
                        </Button>
                      }
                      onDrop={(acceptedFiles: any) => {
                        setValue('siteImages', [
                          ...getValues().siteImages,
                          ...acceptedFiles.map((file: File): UserImage => {
                            return {
                              id: crypto.randomUUID(),
                              title: file.name,
                              image: file,
                            };
                          }),
                        ]);
                      }}
                    />
                  );
                }}
              />
            </>
          </InputGroup>
        </ThumbnailGrid>
      </div>
      <Controller
        name='generalDescription'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='General description'
            placeholder='General description'
            sx={{
              width: '100%',
            }}
            multiline
            rows={4}
            maxRows={4}
            inputProps={{
              maxLength: 500,
            }}
          />
        )}
      />
    </Stack>
  );
}
