import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@store';
import { useCloseDialog } from '@hooks';
import { OutboundProcess } from '@features/supplyChain/types';
import { setSelectedOutboundProcess as _setSelectedOutboundProcess } from '@features/supplyChain';

export const useOutboundProcess = () => {
  const dispatch = useAppDispatch();

  const {
    supplyChain: { outboundProcesses },
  } = useAppSelector((state) => state.supplyChain);

  const hasMoreOutboundData = useAppSelector(
    (state) => state.supplyChain.supplyChain.hasMoreOutboundData
  );

  const {
    supplyChain: { selectedOutboundProcess },
  } = useAppSelector((state) => state.supplyChain);

  const setSelectedOutboundProcess = (payload: OutboundProcess | null) => {
    dispatch(_setSelectedOutboundProcess(payload));
  };

  const selectedOutboundProcessHasDescription = Boolean(
    selectedOutboundProcess &&
      selectedOutboundProcess?.description &&
      selectedOutboundProcess?.description?.length > 0
  );

  const getOutboundScrollParent = () =>
    document.getElementById('outbound-list') as HTMLElement;

  const handleScrollToTopOfOutbound = () => {
    getOutboundScrollParent().scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [isViewDespatchNoteDialogOpen, setIsViewDespatchNoteDialogOpen] =
    useState(false);

  const handleCloseViewDespatchNote = useCloseDialog(
    setIsViewDespatchNoteDialogOpen
  );

  const [
    isViewGoodsReceivedNoteDialogOpen,
    setIsViewGoodsReceivedNoteDialogOpen,
  ] = useState(false);

  const handleCloseViewGoodsReceivedNote = useCloseDialog(
    setIsViewGoodsReceivedNoteDialogOpen
  );

  const [
    isViewPickingConfirmationDialogOpen,
    setIsViewPickingConfirmationDialogOpen,
  ] = useState(false);

  const handleCloseViewPickingConfirmation = useCloseDialog(
    setIsViewPickingConfirmationDialogOpen
  );

  const [
    isViewOutboundShipmentNoteDialogOpen,
    setIsViewOutboundShipmentNoteDialogOpen,
  ] = useState(false);

  const handleCloseViewOutboundShipmentNote = useCloseDialog(
    setIsViewOutboundShipmentNoteDialogOpen
  );

  const [isViewProofOfDeliveryDialogOpen, setIsViewProofOfDeliveryDialogOpen] =
    useState(false);

  const handleCloseViewProofOfDelivery = useCloseDialog(
    setIsViewProofOfDeliveryDialogOpen
  );

  return {
    outboundProcesses,
    hasMoreOutboundData,
    selectedOutboundProcess,
    setSelectedOutboundProcess,
    selectedOutboundProcessHasDescription,
    handleScrollToTopOfOutbound,
    isViewDespatchNoteDialogOpen,
    setIsViewDespatchNoteDialogOpen,
    handleCloseViewDespatchNote,
    isViewGoodsReceivedNoteDialogOpen,
    setIsViewGoodsReceivedNoteDialogOpen,
    handleCloseViewGoodsReceivedNote,
    isViewPickingConfirmationDialogOpen,
    setIsViewPickingConfirmationDialogOpen,
    handleCloseViewPickingConfirmation,
    isViewOutboundShipmentNoteDialogOpen,
    setIsViewOutboundShipmentNoteDialogOpen,
    handleCloseViewOutboundShipmentNote,
    isViewProofOfDeliveryDialogOpen,
    setIsViewProofOfDeliveryDialogOpen,
    handleCloseViewProofOfDelivery,
  };
};
