import { useCallback, useEffect, useState } from 'react';
import { Text } from '@components/Text';
import { useAuthHttp, useReactHookForm } from '@hooks';
import {
  GET_TWO_FACTOR_SECRET_URL,
  ENABLE_TWO_FACTOR_AUTH_URL,
} from '@constants';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/material';
import Button from '@components/NewButton/Button';
import { ErrorMessage } from '@components/ErrorMessage';
import { QrCode } from '@components/QrCode/QrCode';
import { defaultValues, validationSchema } from './form';

interface Enable2FAFormProps {
  haveSetUp2Fa: boolean;
  setHaveSetUp2Fa: (is2FaEnabled: boolean) => void;
}

export type Enable2FAFormValues = {
  authenticatorCode: string; // Code from authenticator app. Currently only supports Google Auth
};

interface TwoFaSecret {
  secret: string;
  secretBase64Encoded: string;
}

export function Enable2FAForm({
  haveSetUp2Fa,
  setHaveSetUp2Fa,
}: Enable2FAFormProps) {
  const authHttp = useAuthHttp();

  const [qrCode, setQrCode] = useState('');
  const [twoFaSecret, setTwoFaSecret] = useState<TwoFaSecret>({
    secret: '',
    secretBase64Encoded: '',
  });
  const [showFailureResponseMessage, setShowFailureResponseMessage] =
    useState(false);

  const { handleSubmit, control, isSubmitting, getValues, trigger, formState } =
    useReactHookForm<Enable2FAFormValues>({
      defaultValues,
      validationSchema,
    });

  useEffect(() => {
    async function fetch2FASecret() {
      try {
        const { data } = await authHttp.get(GET_TWO_FACTOR_SECRET_URL);
        if (
          data.twoFaSecret.secret &&
          data.twoFaSecret.secretBase64Encoded &&
          data.qrCode
        ) {
          setQrCode(data.qrCode);
          setTwoFaSecret(data.twoFaSecret);
        } else {
          throw new DOMException(); // handle this gracefully
        }
      } catch (error) {
        console.error('error: ', error);
      }
    }
    if (!twoFaSecret.secret || !twoFaSecret.secretBase64Encoded || !qrCode) {
      fetch2FASecret();
    }
  }, []);

  const focusTextFieldCallbackRef = useCallback(
    (inputElement: HTMLDivElement) => {
      setTimeout(() => {
        inputElement?.querySelector('input')?.focus();
      }, 1);
    },
    []
  );

  const onSubmit = async (values: Enable2FAFormValues) => {
    // eslint-disable-next-line no-console
    console.log('Submitted form');
    // eslint-disable-next-line no-console
    console.log(`Authenticator code: ${values.authenticatorCode}`);

    const { errors } = formState;

    // eslint-disable-next-line no-console
    console.log(getValues(), errors);
    const isValid = await trigger();

    if (isValid) {
      try {
        // eslint-disable-next-line no-console
        console.log(`Valid: ${values}`);

        const { status, data } = await authHttp.post(
          ENABLE_TWO_FACTOR_AUTH_URL,
          {
            code: values.authenticatorCode,
            twoFaSecret,
          }
        );

        if (status === 200) {
          const userString = localStorage.getItem('user');
          if (userString !== null) {
            const user = JSON.parse(userString);
            user.twoFactorMethodIds = data.recoveryCodes?.twoFactorMethodIds;
            localStorage.setItem('user', JSON.stringify(user));

            setHaveSetUp2Fa(true);
            setShowFailureResponseMessage(false);
          }
        } else {
          setShowFailureResponseMessage(true);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error: ', error);
        setShowFailureResponseMessage(true);
      }
    }
  };

  if (!haveSetUp2Fa) {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className='twoFaForm'>
        <Stack spacing={24} p={6}>
          <Stack alignItems='center'>
            <QrCode link={qrCode} />
          </Stack>
          <Text>
            To enable 2FA, scan this QR code using Google Authenticator and
            enter the 6-digit code below.
          </Text>
          <Controller
            name='authenticatorCode'
            control={control}
            render={({ field, fieldState: { error, isTouched } }) => (
              <TextField
                {...field}
                name={field.name}
                error={Boolean(error && isTouched)}
                helperText={Boolean(error && isTouched) && error?.message}
                placeholder='Authenticator App Code (required)'
                ref={focusTextFieldCallbackRef}
              />
            )}
          />
          {showFailureResponseMessage && (
            <ErrorMessage>Something went wrong. Please try again</ErrorMessage>
          )}
          <Button
            type='submit'
            intent='primary'
            size='sm'
            label={isSubmitting ? 'Submitting...' : 'Submit'}
            disabled={isSubmitting}
          />
        </Stack>
      </form>
    );
  }
  return (
    <div className='twoFaSetupSuccess'>
      2 Factor Authentication set up successfully.
    </div>
  );
}
