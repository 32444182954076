/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAtomValue } from 'jotai';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { MdCheck } from 'react-icons/md';
import Button from '@components/NewButton/Button';
import { Heading } from '@components/Heading';
import { useMediaQuery } from 'usehooks-ts';
import {
  Modal,
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@components/Modal';
import {
  AUTHORISE_BANK_ACCOUNT,
  GET_BANK_DETAILS,
  GET_SUPPORTED_BANKS,
  REVOKE_BANK_ACCOUNT_CONSENT,
  UPDATE_BANK_DETAILS,
} from '../../constants';
import { useAuthHttp } from '../../hooks';
import { businessProfileAtom } from '../../store/jotai';

const schema = yup.object().shape({
  sortCode: yup
    .string()
    .required('Sort code is required')
    .matches(/^\d{6}$/, 'Sort code must be 6 digits'),
  accountNumber: yup
    .string()
    .required('Account number is required')
    .matches(/^\d{8}$/, 'Account number must be 8 digits'),
  accountName: yup.string().required('Account name is required'),
  bicSwift: yup.string().required('BIC/SWIFT is required'),
  iban: yup.string().required('IBAN is required'),
  bank: yup
    .object()
    .shape({
      bankName: yup.string().required('Bank name is required'),
      // branchCode: yup.string().required('Branch code is required'),
      bankInstitutionId: yup
        .string()
        .required('Bank institution ID is required'),
    })
    .required(),
});

type BankAccount = {
  id: string;
  sortCode: string;
  accountNumber: string;
  swiftCode: string;
  iban: string;
  accountName: string;
  bankName: string;
  branchCode: string;
  bankInstitutionId: string;
};

export default function FinanceForm({
  hasConnectedBankAccount,
}: {
  hasConnectedBankAccount: boolean;
}) {
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const authHttp = useAuthHttp();
  const businessProfile = useAtomValue(businessProfileAtom);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [supportedBanks, setSupportedBanks] = useState([]);
  const [bankAccount, setBankAccount] = useState<BankAccount | null>(null);
  const [isConsentModalOpen, setIsConsentModalOpen] = useState(false);
  const [
    isRevokeBankAccountConsentModalOpen,
    setIsRevokeBankAccountConsentModalOpen,
  ] = useState(false);
  const [isAuthorisingBankAccount, setIsAuthorisingBankAccount] =
    useState(false);
  const [isRevoking, setIsRevoking] = useState(false);

  const defaultValues = {
    sortCode: '',
    accountNumber: '',
    bicSwift: '',
    iban: '',
    accountName: '',
    bank: {
      bankName: 'Select a bank',
      branchCode: '',
      bankInstitutionId: '',
    },
  };

  const methods = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { ...defaultValues },
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = methods;

  const onSubmit = async () => {
    setIsConsentModalOpen(true);
  };

  const handleAuthoriseBankAccount = async () => {
    setIsAuthorisingBankAccount(true);
    try {
      const { data } = await authHttp.post(
        AUTHORISE_BANK_ACCOUNT,
        {
          baseUrl: window.location.origin,
          redirectUrl: 'profile/update-financial-information',
        },
        { headers: { 'Content-Type': 'application/json' } }
      );
      if (data.bankAccountAuthorisationUrl) {
        window.location.href = data.bankAccountAuthorisationUrl;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsAuthorisingBankAccount(false);
    }
  };

  const onConfirm = async () => {
    const values = getValues();
    setIsFormSubmitting(true);
    try {
      const { data } = await authHttp.post(
        UPDATE_BANK_DETAILS,
        {
          bankInfo: {
            businessId: businessProfile?.id || '',
            sortCode: values.sortCode,
            accountNumber: values.accountNumber,
            swiftCode: values.bicSwift,
            iban: values.iban,
            accountName: values.accountName,
            ...values.bank,
          },
        },
        { headers: { 'Content-Type': 'application/json' } }
      );
      if (data) {
        setBankAccount(data.bankAccount);
        handleAuthoriseBankAccount();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsFormSubmitting(false);
    }
  };

  const fetchBankDetails = async () => {
    try {
      const { data } = await authHttp.get(GET_BANK_DETAILS);

      if (data.bankAccount) {
        setBankAccount(data.bankAccount);
        reset({
          sortCode: data.bankAccount.sortCode,
          accountNumber: data.bankAccount.accountNumber,
          bicSwift: data.bankAccount.swiftCode,
          iban: data.bankAccount.iban,
          accountName: data.bankAccount.accountName,
          bank: {
            bankName: data.bankAccount.bankName,
            branchCode: data.bankAccount.branchCode,
            bankInstitutionId: data.bankAccount.bankInstitutionId,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSupportedBanks = async () => {
    try {
      const { data } = await authHttp.get(GET_SUPPORTED_BANKS);

      if (data) {
        setSupportedBanks(
          data.supportedBanks.map((bank: any) => ({
            bankName: bank.name,
            branchCode: bank.branchCode,
            bankInstitutionId: bank.id,
          }))
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRevokeConsent = async () => {
    setIsRevoking(true);
    try {
      const { data } = await authHttp.post(
        REVOKE_BANK_ACCOUNT_CONSENT,
        {},
        { headers: { 'Content-Type': 'application/json' } }
      );
      if (data) {
        setBankAccount(data.bankAccount);
        setIsRevokeBankAccountConsentModalOpen(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsRevoking(false);
    }
  };

  const getDatePlus90Days = () => {
    const today = new Date();
    today.setDate(today.getDate() + 90);
    return today.toLocaleDateString();
  };

  useEffect(() => {
    if (!businessProfile) return;
    fetchBankDetails();
    fetchSupportedBanks();
  }, [businessProfile]);

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ width: '100%' }}
        >
          <Stack spacing={16} width={isDesktop ? '33%' : '100%'}>
            <Heading as='h3' size='xs'>
              Bank Details
            </Heading>
            <Controller
              name='bank'
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={supportedBanks}
                  getOptionLabel={(option) => option.bankName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='bank'
                      label='Bank'
                      variant='outlined'
                      fullWidth
                      error={!!errors.bank}
                      // helperText={errors.bank ? errors.bank.message : ''}
                    />
                  )}
                  onChange={(_, data) => field.onChange(data)}
                />
              )}
            />

            <Controller
              name='accountName'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Account Name'
                  fullWidth
                  variant='outlined'
                  error={!!errors.accountNumber}
                  helperText={
                    errors.accountName ? errors.accountName.message : ''
                  }
                />
              )}
            />

            <Controller
              name='sortCode'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Sort Code'
                  fullWidth
                  variant='outlined'
                  error={!!errors.sortCode}
                  helperText={errors.sortCode ? errors.sortCode.message : ''}
                />
              )}
            />

            <Controller
              name='accountNumber'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Account Number'
                  fullWidth
                  variant='outlined'
                  error={!!errors.accountNumber}
                  helperText={
                    errors.accountNumber ? errors.accountNumber.message : ''
                  }
                />
              )}
            />

            <Controller
              name='bicSwift'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='BIC/SWIFT'
                  fullWidth
                  variant='outlined'
                  error={!!errors.bicSwift}
                  helperText={errors.bicSwift ? errors.bicSwift.message : ''}
                />
              )}
            />

            <Controller
              name='iban'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='IBAN'
                  fullWidth
                  variant='outlined'
                  error={!!errors.iban}
                  helperText={errors.iban ? errors.iban.message : ''}
                />
              )}
            />

            <Button
              type='submit'
              icon={
                !methods.formState.isDirty ? (
                  <MdCheck size='1.25rem' />
                ) : (
                  <div style={{ display: 'none' }} />
                )
              }
              disabled={isFormSubmitting}
              size='sm'
              intent={
                !bankAccount || methods.formState.isDirty
                  ? 'primary'
                  : 'success'
              }
              label={
                isFormSubmitting
                  ? 'Submitting...'
                  : !bankAccount
                  ? 'Submit'
                  : !methods.formState.isDirty
                  ? 'Verified'
                  : 'Update'
              }
            />
            <Button
              size='sm'
              intent='secondary'
              onClick={() => setIsRevokeBankAccountConsentModalOpen(true)}
              disabled={!hasConnectedBankAccount || !bankAccount}
              label='Revoke Consent'
            />
          </Stack>
        </form>
      </FormProvider>
      <Modal
        open={isConsentModalOpen}
        onOpenChange={(isOpen: boolean) => {
          setIsConsentModalOpen(isOpen);
        }}
      >
        <ModalContainer preventCloseOnOutsideClick>
          <ModalHeader title='Update bank details' />
          <ModalContent>
            <div className='space-y-4'>
              <p>
                We have partnered with Yapily Connect to access your bank data
                at {getValues().bank.bankName}.
              </p>
              <p>
                You will now be securely redirected to
                {getValues().bank.bankName} to give access to the following
                information:
                <ul>
                  <li>Account(s) details</li>
                  <li>Balances</li>
                  <li>Transaction history</li>
                </ul>
              </p>
              <p>
                By using the service, you agree to Yapily Connect accessing your
                bank data, the
                <a
                  href='https://www.yapily.com/legal/end-user-terms?_gl=1*6m3mg2*_gcl_au*NzU2MzY1NTYxLjE3MzE0OTQ1MTg.'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-blue-500 visited:text-purple-500'
                >
                  &nbsp;Terms & Conditions&nbsp;
                </a>
                and
                <a
                  href='https://www.yapily.com/legal/privacy-policy?_gl=1*6m3mg2*_gcl_au*NzU2MzY1NTYxLjE3MzE0OTQ1MTg.'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-blue-500 visited:text-purple-500'
                >
                  &nbsp;Privacy Notice
                </a>
                .
              </p>
              <p>This consent will be valid until {getDatePlus90Days()}.</p>
            </div>
          </ModalContent>
          <ModalFooter>
            <Button
              intent='danger'
              size='lg'
              onClick={() => setIsConsentModalOpen(false)}
              label='Cancel'
            />
            <Button
              intent='primary'
              size='lg'
              onClick={onConfirm}
              disabled={isAuthorisingBankAccount}
              label={
                isFormSubmitting
                  ? 'Submitting...'
                  : isAuthorisingBankAccount
                  ? 'Authorising'
                  : 'Confirm'
              }
            />
          </ModalFooter>
        </ModalContainer>
      </Modal>
      <Modal
        open={isRevokeBankAccountConsentModalOpen}
        onOpenChange={(isOpen: boolean) => {
          setIsRevokeBankAccountConsentModalOpen(isOpen);
        }}
      >
        <ModalContainer preventCloseOnOutsideClick>
          <ModalHeader title='Withdrawal of Consent for Payment Processing' />
          <ModalContent>
            <div className='space-y-4'>
              <p>
                Are you sure you want to revoke consent for{' '}
                {bankAccount?.bankName}?
              </p>
              <p>
                We acknowledge your right to withdraw your consent for our
                payment processing platform* at any time. Please note that
                without your consent, we will be unable to process payments
                against your invoices and authorise subsequent shipments. Please
                confirm, if you wish to proceed with withdrawing your consent:
              </p>
              <p className='text-sm'>
                Note: *) Yapily Connect Ltd, Authorised Payment Institution, FCA
                Registered 827001.
              </p>
            </div>
          </ModalContent>
          <ModalFooter>
            <Button
              intent='primary'
              size='lg'
              onClick={() => setIsRevokeBankAccountConsentModalOpen(false)}
              label='Cancel'
            />
            <Button
              intent='danger'
              size='lg'
              onClick={handleRevokeConsent}
              label={isRevoking ? 'Revoking...' : 'Revoke Consent'}
            />
          </ModalFooter>
        </ModalContainer>
      </Modal>
    </>
  );
}
