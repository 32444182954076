import {
  BoardLane,
  BoardLaneHeader,
  BoardLaneItemCount,
  BoardLaneTitleButton,
  BoardLaneTitleContainer,
} from '@components/Board';
import InfiniteScroll from 'react-infinite-scroller';
import { ReactNode } from 'react';
import {
  InfiniteScrollEndMessage,
  InfiniteScrollErrorMessage,
  InfiniteScrollLoading,
} from '@components/InfiniteScroll';

export function Swimlane<T extends { id: string }>({
  title,
  name,
  items,
  hasMoreData,
  isLoadingError,
  loadMore,
  swimlaneItem,
  handleScrollToTop,
  addButtonElement,
  eventListElement,
}: {
  title: string;
  name: string;
  items: T[];
  hasMoreData: boolean | null;
  isLoadingError: boolean | null;
  loadMore: () => void;
  swimlaneItem: (item: T) => ReactNode;
  handleScrollToTop: () => void;
  addButtonElement?: ReactNode;
  eventListElement: ReactNode;
}) {
  const scrollParentId = `${name}-list`;
  return (
    <BoardLane>
      <BoardLaneHeader>
        <BoardLaneTitleContainer>
          <BoardLaneTitleButton>{title}</BoardLaneTitleButton>
          {addButtonElement && addButtonElement}
        </BoardLaneTitleContainer>
        <BoardLaneItemCount itemCount={items.length} />
      </BoardLaneHeader>
      <InfiniteScroll
        element={eventListElement}
        initialLoad
        loadMore={loadMore}
        hasMore={
          hasMoreData === null || (hasMoreData === true && !isLoadingError)
        }
        useWindow={false}
        getScrollParent={() => document.getElementById(scrollParentId)}
        loader={
          <InfiniteScrollLoading
            key={`${name}-loader`}
            loadingMessage={`Loading ${name} items...`}
          />
        }
      >
        {items.length > 0 && items.map((item) => swimlaneItem(item))}
        {hasMoreData === false && (
          <InfiniteScrollEndMessage
            isVisible={items.length > 25}
            direction='column'
            endMessageText={`End of ${name} items`}
            handleScrollToTop={handleScrollToTop}
          />
        )}
        {isLoadingError && (
          <InfiniteScrollErrorMessage
            errorMessage={`Sorry, there was an error fetching your ${name} items.`}
          />
        )}
      </InfiniteScroll>
    </BoardLane>
  );
}
