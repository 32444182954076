/* eslint-disable no-nested-ternary */
import { Label } from '@components/Label';
import { Text } from '@components/Text';
import { ThumbnailGrid, Thumbnail } from '@components/Thumbnail';
import { Dropzone } from '@components/Dropzone';
import { Button } from '@components/Button';
import {
  MdAdd,
  MdDelete,
  MdOutlineDelete,
  MdOutlineImage,
} from 'react-icons/md';
import {
  Select,
  SelectItem,
  SelectGroup,
  SelectLabel,
  SelectSeparator,
} from '@components/Select';
import {
  VOLUME_UNIT_GROUPS,
  INVENTORY_IMAGES_URL,
  SUPPORTED_CURRENCY_GROUPS,
} from '@constants';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { ErrorMessage } from '@components/ErrorMessage';
import { InputGroup } from '@components/InputGroup';
import { StyledPriceGrid } from '@views/ProductMaster/ProductMaster.styles';
import { ChangeEvent, useEffect } from 'react';
import { AddProductFormValues, ProductImage, UserImage } from '../types';

interface StepOneProps {
  watch: UseFormWatch<AddProductFormValues>;
  register: UseFormRegister<AddProductFormValues>;
  errors: FieldErrors<AddProductFormValues>;
  touchedFields: any;
  getValues: UseFormGetValues<AddProductFormValues>;
  setValue: UseFormSetValue<AddProductFormValues>;
  setFocus: UseFormSetFocus<AddProductFormValues>;
  fields: FieldArrayWithId<AddProductFormValues, 'product.costPrices', 'id'>[];
  append: UseFieldArrayAppend<AddProductFormValues, 'product.costPrices'>;
  remove: UseFieldArrayRemove;
  control: Control<AddProductFormValues>;
  isSmall: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export function StepOne({
  watch,
  register,
  errors,
  touchedFields,
  getValues,
  setValue,
  setFocus,
  fields,
  append,
  remove,
  control,
  isSmall,
  isTablet,
  isDesktop,
}: StepOneProps) {
  const [imageValues, costPrices] = watch([
    'productImages',
    'product.images',
    'skuImages',
    'skuBarcodeImages',
    'product.costPrices',
    'barcodeImage',
    'product.barcodeImage',
  ]);

  useEffect(() => {
    const currentIndex = fields.length - 1;
    setFocus(`product.costPrices.${currentIndex}.currency`);
  }, [append, fields.length, setFocus]);

  return (
    <Stack spacing={16}>
      <Controller
        name='product.productId'
        control={control}
        render={({ field, fieldState: { error, isTouched } }) => (
          <TextField
            {...field}
            error={Boolean(error && isTouched)}
            helperText={error?.message}
            variant='outlined'
            label='Product ID (required)'
            placeholder='Product ID (required)'
            multiline={!isDesktop}
            {...(!isDesktop && {
              InputLabelProps: {
                shrink: true,
              },
            })}
            fullWidth
          />
        )}
      />
      <Controller
        name='product.barcode'
        control={control}
        render={({ field, fieldState: { error, isTouched } }) => (
          <TextField
            {...field}
            error={Boolean(error && isTouched)}
            helperText={error?.message}
            variant='outlined'
            label='Barcode'
            placeholder='Barcode'
            multiline={!isDesktop}
            {...(!isDesktop && {
              InputLabelProps: {
                shrink: true,
              },
            })}
            fullWidth
          />
        )}
      />
      <Controller
        name='product.name'
        control={control}
        render={({ field, fieldState: { error, isTouched } }) => (
          <TextField
            {...field}
            error={Boolean(error && isTouched)}
            helperText={error?.message}
            variant='outlined'
            label='Name (required)'
            placeholder='Name (required)'
            multiline={!isDesktop}
            {...(!isDesktop && {
              InputLabelProps: {
                shrink: true,
              },
            })}
            fullWidth
          />
        )}
      />
      <Controller
        name='product.brand'
        control={control}
        render={({ field, fieldState: { error, isTouched } }) => (
          <TextField
            {...field}
            error={Boolean(error && isTouched)}
            helperText={error?.message}
            variant='outlined'
            label='Brand'
            placeholder='Brand'
            multiline={!isDesktop}
            {...(!isDesktop && {
              InputLabelProps: {
                shrink: true,
              },
            })}
            fullWidth
          />
        )}
      />
      <Controller
        name='product.category'
        control={control}
        render={({ field, fieldState: { error, isTouched } }) => (
          <TextField
            {...field}
            error={Boolean(error && isTouched)}
            helperText={error?.message}
            variant='outlined'
            label='Category'
            placeholder='Category'
            multiline={!isDesktop}
            {...(!isDesktop && {
              InputLabelProps: {
                shrink: true,
              },
            })}
          />
        )}
      />
      <Controller
        name='product.subCategory'
        control={control}
        render={({ field, fieldState: { error, isTouched } }) => (
          <TextField
            {...field}
            error={Boolean(error && isTouched)}
            helperText={error?.message}
            variant='outlined'
            label='Subcategory'
            placeholder='Subcategory'
            multiline={!isDesktop}
            {...(!isDesktop && {
              InputLabelProps: {
                shrink: true,
              },
            })}
            fullWidth
          />
        )}
      />
      <Controller
        name='product.description'
        control={control}
        render={({ field, fieldState: { error, isTouched } }) => (
          <TextField
            {...field}
            multiline
            minRows={2}
            error={Boolean(error && isTouched)}
            helperText={error?.message}
            label='Description'
            placeholder='Description'
            {...(!isDesktop && {
              InputLabelProps: {
                shrink: true,
              },
            })}
            variant='outlined'
            inputProps={{
              sx: {
                resize: 'vertical',
              },
            }}
            fullWidth
          />
        )}
      />
      <Stack direction='row' spacing={16}>
        <div style={{ width: '30%' }}>
          <Controller
            name='product.netVolumeUnitOfMeasure'
            control={control}
            render={({ field }) => {
              return (
                <Select
                  onValueChange={field.onChange}
                  {...(getValues().product.netVolumeUnitOfMeasure !== '' && {
                    defaultValue: getValues().product.netVolumeUnitOfMeasure,
                  })}
                  onBlur={field.onBlur}
                  placeholder='Net volume unit'
                >
                  {VOLUME_UNIT_GROUPS.map((volumeGroup, volumeGroupIndex) => (
                    <>
                      <SelectGroup key={volumeGroup.groupName}>
                        <SelectLabel>{volumeGroup.groupName}</SelectLabel>
                        {volumeGroup.volumeUnits.map((volumeUnit) => (
                          <SelectItem
                            key={volumeUnit.value}
                            value={volumeUnit.value}
                          >
                            {`${volumeUnit.label}`}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                      {volumeGroupIndex < VOLUME_UNIT_GROUPS.length - 1 && (
                        <SelectSeparator />
                      )}
                    </>
                  ))}
                </Select>
              );
            }}
          />
        </div>
        <div style={{ width: '100%' }}>
          <Controller
            name='product.netVolume'
            control={control}
            render={({ field, fieldState: { error, isTouched } }) => (
              <TextField
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                {...field}
                error={Boolean(error && isTouched)}
                helperText={error?.message}
                variant='outlined'
                label='Net volume (required)'
                placeholder='Net volume (required)'
                multiline={!isDesktop}
                {...(!isDesktop && {
                  InputLabelProps: {
                    shrink: true,
                  },
                })}
                fullWidth
              />
            )}
          />
        </div>
      </Stack>
      {/* COST PRICES */}
      <Stack
        direction={isDesktop ? 'row' : 'column'}
        spacing={isDesktop ? 24 : 0}
      >
        <div style={{ width: '50%' }}>
          <Text as='h4' size='lg' weight='bold' css={{ mb: '$space16' }}>
            Cost prices
          </Text>
          <StyledPriceGrid>
            {fields.map((item, index) => {
              return (
                <Stack key={item.id} spacing={16}>
                  <Stack direction='row' spacing={16}>
                    <Text
                      as='h4'
                      size='md'
                      weight='bold'
                      css={{ minWidth: '4rem', mb: '$space8' }}
                    >
                      {`Cost price ${index + 1}`}
                    </Text>
                    <InputGroup css={{ gap: '$space0' }}>
                      <Label
                        htmlFor={`product.costPrices.${index}.currency`}
                        visuallyHidden
                      >
                        Currency
                      </Label>
                      <Controller
                        name={`product.costPrices.${index}.currency`}
                        control={control}
                        render={({ field, fieldState }) => {
                          return (
                            <>
                              <Select
                                ref={field.ref}
                                onValueChange={field.onChange}
                                {...(getValues().product.costPrices[index]
                                  .currency !== '' && {
                                  defaultValue:
                                    getValues().product.costPrices[index]
                                      .currency,
                                })}
                                isInvalid={
                                  fieldState.error && fieldState.isTouched
                                }
                                onBlur={field.onBlur}
                                placeholder='Currency...'
                                css={{
                                  mb:
                                    fieldState.error &&
                                    fieldState.isTouched &&
                                    '$space8',
                                }}
                              >
                                {SUPPORTED_CURRENCY_GROUPS.map(
                                  (currencyGroup, currencyGroupIndex) => (
                                    <>
                                      <SelectGroup
                                        key={currencyGroup.groupName}
                                      >
                                        <SelectLabel>
                                          {currencyGroup.groupName}
                                        </SelectLabel>
                                        {currencyGroup.currencies.map(
                                          (currency) => (
                                            <SelectItem
                                              key={currency.code}
                                              value={currency.code}
                                            >
                                              {`${currency.code}`}
                                            </SelectItem>
                                          )
                                        )}
                                      </SelectGroup>
                                      {currencyGroupIndex <
                                        SUPPORTED_CURRENCY_GROUPS.length -
                                          1 && <SelectSeparator />}
                                    </>
                                  )
                                )}
                              </Select>
                              {fieldState.error && fieldState.isTouched && (
                                <ErrorMessage>
                                  {fieldState.error.message}
                                </ErrorMessage>
                              )}
                            </>
                          );
                        }}
                      />
                    </InputGroup>
                  </Stack>
                  <Stack spacing={16}>
                    <Controller
                      name={`product.costPrices.${index}.amount`}
                      control={control}
                      render={({ field, fieldState: { error, isTouched } }) => (
                        <TextField
                          inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                          }}
                          {...field}
                          error={Boolean(error && isTouched)}
                          helperText={error?.message}
                          variant='outlined'
                          label='Amount (required)'
                          placeholder='Amount (required)'
                          multiline={!isDesktop}
                          {...(!isDesktop && {
                            InputLabelProps: {
                              shrink: true,
                            },
                          })}
                          fullWidth
                        />
                      )}
                    />
                    <Controller
                      name={`product.costPrices.${index}.comment`}
                      control={control}
                      render={({ field, fieldState: { error, isTouched } }) => (
                        <TextField
                          {...field}
                          multiline
                          minRows={2}
                          error={Boolean(error && isTouched)}
                          helperText={error?.message}
                          label='Comment'
                          placeholder='Comment'
                          {...(!isDesktop && {
                            InputLabelProps: {
                              shrink: true,
                            },
                          })}
                          variant='outlined'
                          inputProps={{
                            sx: {
                              resize: 'vertical',
                            },
                          }}
                          fullWidth
                        />
                      )}
                    />
                  </Stack>
                  <Button
                    leftIcon={<MdOutlineDelete size='1.5rem' />}
                    isFullWidth
                    onClick={() => remove(index)}
                  >
                    Remove
                  </Button>
                </Stack>
              );
            })}
            {/* <StyledAddPriceContainer> */}
            <Button
              size='md'
              leftIcon={<MdAdd size='1.5rem' />}
              onClick={() =>
                append(
                  {
                    amount: null,
                    currency: '',
                    comment: '',
                  },
                  {
                    shouldFocus: true,
                    focusIndex: 0,
                    focusName: `product.costPrices.${fields.length - 1}.amount`,
                  }
                )
              }
              disabled={
                errors.product?.costPrices &&
                Object.keys(errors.product.costPrices).length > 0
              }
            >
              Add cost price
            </Button>
            {/* </StyledAddPriceContainer> */}
          </StyledPriceGrid>
        </div>
        <Stack direction='row' sx={{ width: '100%' }}>
          <div style={{ width: '100%' }}>
            <Text as='h4' size='lg' weight='bold' css={{ mb: '$space16' }}>
              Product image
            </Text>
            <div style={{ width: '75%' }}>
              {getValues().product.images.length > 0 ||
              getValues().productImages?.length > 0 ? (
                <>
                  {getValues()
                    .productImages.map((img: UserImage) => ({
                      preview: URL.createObjectURL(img.image),
                      title: img.title,
                      id: img.id,
                    }))
                    .concat(
                      getValues().product.images.map((img: ProductImage) => ({
                        preview: `${INVENTORY_IMAGES_URL}/${img.imageLocation}/small/${img.remoteName}`,
                        title: img.title,
                        id: img.id,
                      }))
                    )
                    .map((img, index) => (
                      <Thumbnail
                        key={`${img.title}${index.toString()}`}
                        src={img.preview}
                      >
                        <Button
                          size='xs'
                          action='danger'
                          leftIcon={<MdDelete size='1rem' />}
                          onClick={() => {
                            if (
                              getValues().productImages.some(
                                (i: any) => i.id === img.id
                              )
                            ) {
                              setValue(
                                'productImages',
                                getValues().productImages.filter(
                                  (i: any) => i.id !== img.id
                                )
                              );
                            }

                            if (
                              getValues().product.images.some(
                                (i: any) => i.id === img.id
                              )
                            ) {
                              setValue(
                                'product.images',
                                getValues().product.images.filter(
                                  (i: any) => i.id !== img.id
                                )
                              );

                              setValue(
                                'deletedProductImageIds',
                                getValues().deletedProductImageIds.concat(
                                  img.id
                                )
                              );
                            }
                          }}
                          css={{
                            position: 'absolute',
                            bottom: '$space8',
                            right: '$space8',
                          }}
                        >
                          {/* Delete */}
                        </Button>
                      </Thumbnail>
                    ))}
                </>
              ) : (
                <InputGroup css={{ gap: '$space0', mb: '$space16' }}>
                  <>
                    <Label htmlFor='images' visuallyHidden>
                      Images
                    </Label>
                    <Controller
                      name='productImages'
                      control={control}
                      render={() => {
                        return (
                          <Dropzone
                            {...register('productImages')}
                            id='images'
                            primaryAction={
                              <Button
                                size={isDesktop ? 'xs' : isSmall ? 'sm' : 'xs'}
                                leftIcon={
                                  <MdAdd size={isSmall ? '1.25rem' : '1rem'} />
                                }
                              >
                                Add image
                              </Button>
                            }
                            onDrop={(acceptedFiles: any) => {
                              setValue('productImages', [
                                ...getValues().productImages,
                                ...acceptedFiles.map(
                                  (file: File): UserImage => {
                                    return {
                                      id: crypto.randomUUID(),
                                      title: file.name,
                                      image: file,
                                    };
                                  }
                                ),
                              ]);
                            }}
                          />
                        );
                      }}
                    />
                  </>
                </InputGroup>
              )}
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <Text as='h4' size='lg' weight='bold' css={{ mb: '$space16' }}>
              Barcode Image
            </Text>
            <div style={{ width: '75%' }}>
              {getValues().product.barcodeImage.id ||
              getValues().barcodeImage.image ? (
                <Thumbnail
                  key={`${getValues().barcodeImage.title}}`}
                  src={
                    getValues().barcodeImage.image
                      ? URL.createObjectURL(getValues().barcodeImage.image)
                      : `${INVENTORY_IMAGES_URL}/${
                          getValues().product.barcodeImage.imageLocation
                        }/small/${getValues().product.barcodeImage.remoteName}`
                  }
                >
                  <Button
                    size='xs'
                    action='danger'
                    leftIcon={<MdDelete size='1rem' />}
                    onClick={() => {
                      if (getValues().barcodeImage) {
                        // Clear the local barcode image
                        setValue('barcodeImage', {
                          id: '',
                          title: '',
                          image: undefined as unknown as File,
                        });
                      }
                      if (getValues().product.barcodeImage.id) {
                        // Mark the existing barcode image for deletion
                        setValue('product.barcodeImage', {
                          id: '',
                          title: '',
                          originalFileName: '',
                          remoteName: '',
                          imageLocation: '',
                        });
                        setValue(
                          'deletedProductImageIds',
                          getValues().deletedProductImageIds.concat(
                            getValues().product.barcodeImage.id
                          )
                        );
                      }
                    }}
                    css={{
                      position: 'absolute',
                      bottom: '$space8',
                      right: '$space8',
                    }}
                  >
                    {/* Delete */}
                  </Button>
                </Thumbnail>
              ) : (
                <InputGroup css={{ gap: '$space0', mb: '$space16' }}>
                  <>
                    <Label htmlFor='images' visuallyHidden>
                      Images
                    </Label>
                    <Controller
                      name='barcodeImage'
                      control={control}
                      render={() => {
                        return (
                          <Dropzone
                            {...register('barcodeImage')}
                            id='images'
                            primaryAction={
                              <Button
                                size={isDesktop ? 'xs' : isSmall ? 'sm' : 'xs'}
                                leftIcon={
                                  <MdAdd size={isSmall ? '1.25rem' : '1rem'} />
                                }
                              >
                                Add image
                              </Button>
                            }
                            onDrop={(acceptedFile: any) => {
                              setValue('barcodeImage', {
                                id: crypto.randomUUID(),
                                title: acceptedFile[0].name,
                                image: acceptedFile[0],
                              });
                            }}
                          />
                        );
                      }}
                    />
                  </>
                </InputGroup>
              )}
            </div>
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
}

StepOne.displayName = 'StepOne';
