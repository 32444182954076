/* eslint-disable no-nested-ternary */
import { ReactNode, useEffect, useState } from 'react';
import { Heading } from '@components/Heading';
import { Button } from '@components/Button';
import {
  Modal,
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@components/Modal';
import { EmptyState } from '@components/EmptyState';
import { MdAdd, MdOutlineInfo } from 'react-icons/md';
import { View } from '@views/View';
import { useDocumentTitle, useMediaQuery } from 'usehooks-ts';
import { WarehouseCard, WarehouseList } from '@components/WarehouseList';
import InfiniteScroll from 'react-infinite-scroller';
import { InfiniteScrollLoading } from '@components/InfiniteScroll';
import { Alert, Divider, Snackbar, Typography } from '@mui/material';
import { WarehouseFormValues } from '@forms/manager/AddWarehouseForm/WarehouseForm';
import { WarehouseForm } from '@forms/manager/AddWarehouseForm';
import { useAtom } from 'jotai';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BusinessProfile } from '@views/Profile/Profile';
import { Warehouse } from '@views/WarehouseDetails/WarehouseDetails';
import { StyledWarehouseMasterHeaderContainer } from './WarehouseMaster.styles';
import { useInventory } from './hooks/useInventory';
import {
  businessProfileAtom,
  isWarehouseModalOpenAtom,
  notificationsAtom,
} from '../../store/jotai';
import {
  BUSINESS_PROFILE_AGREE_WAREHOUSE_TANDCS,
  COUNTRY_CODES,
  LSP_WAREHOUSES_URL,
  WAREHOUSE_CREATE,
} from '../../constants';
import { useAuthHttp } from '../../hooks';
import { useAuth } from '../../context';

export function WarehouseMaster() {
  const [isAddWarehouseModalOpen, setIsAddWarehouseModalOpen] = useAtom(
    isWarehouseModalOpenAtom
  );
  const [businessProfile, setBusinessProfile] = useAtom(businessProfileAtom);
  const [notifications, setNotifications] = useAtom(notificationsAtom);

  const [isWarehousesLoading, setIsWarehousesLoading] = useState(true);
  const [hasCreatedWarehouse, setHasCreatedWarehouse] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isAgreeing, setIsAgreeing] = useState(false);
  const [isAgreeToWarehouseTandCsOpen, setIsAgreeToWarehouseTandCsOpen] =
    useState(false);

  const [isNoBusinessProfileOpen, setIsNoBusinessProfileOpen] = useState(false);

  const { user } = useAuth();
  const authHttp = useAuthHttp();
  const authHttpFormData = useAuthHttp('multipart/form-data');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const isSmall = useMediaQuery('(min-width: 36em)');
  const isTablet = useMediaQuery('(min-width: 48em)');
  const isDesktop = useMediaQuery('(min-width: 62em)');

  const createWarehouse = searchParams.get('createWarehouse') || '';
  const temperatureControlledParam =
    searchParams.get('temperatureControlled') || undefined;
  const maxStorageCapacityMetersParam =
    searchParams.get('maxStorageCapacityMeters') || '';
  const maxStorageCapacityPalletsParam =
    searchParams.get('maxStorageCapacityPallets') || '';
  const availableStorageCapacityMetersParam =
    searchParams.get('availableStorageCapacityMeters') || '';
  const availableStorageCapacityPalletsParam =
    searchParams.get('availableStorageCapacityPallets') || '';
  const minServiceTermMonthsParam =
    searchParams.get('minServiceTermMonths') || '';
  const minServiceCapacityParam = searchParams.get('minServiceCapacity') || '';
  const ownFleetParam = searchParams.get('ownFleet') || '';
  const warehouseParam = searchParams.get('warehouse') || '';
  const distributionParam = searchParams.get('distribution') || '';
  const nameParam = searchParams.get('name') || '';
  const phoneParam = searchParams.get('phone') || '';
  const emailParam = searchParams.get('email') || '';

  useDocumentTitle('FLOX - Warehouses');

  const { getProductsScrollParent } = useInventory();

  const fetchWarehouses = async () => {
    try {
      const { data } = await authHttp.post(LSP_WAREHOUSES_URL, {
        pagination: {
          itemsPerPage: isDesktop ? 100 : 25,
        },
        isIncludingDelistedWarehouses: null,
      });

      if (data) setWarehouses(data.warehouses);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsWarehousesLoading(false);
      setIsAddWarehouseModalOpen(false);
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSnackbarOpen(false);
  };

  const handleAgreeToWarehouseTandCs = async () => {
    setIsAgreeing(true);
    try {
      const { data } = await authHttp.post<BusinessProfile>(
        BUSINESS_PROFILE_AGREE_WAREHOUSE_TANDCS,
        {}
      );
      if (data.businessProfile.warehouseTandCAgreement.hasAgreed) {
        setIsAgreeing(false);
        setBusinessProfile(data.businessProfile);
        setIsAgreeToWarehouseTandCsOpen(false);
        setIsAddWarehouseModalOpen(true);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsAgreeing(false);
    }
  };

  const handleGoToBusinessProfile = () => {
    setIsAgreeToWarehouseTandCsOpen(false);
    navigate('/profile');
  };

  useEffect(() => {
    fetchWarehouses();
  }, []);

  useEffect(() => {
    const notification = notifications[0];
    if (
      notification &&
      [
        'Warehouse.Created',
        'Warehouse.Updated',
        'Warehouse.Deleted',
        'Warehouse.Delisted',
      ].includes(notification.notificationType)
    ) {
      fetchWarehouses();
    }
  }, [notifications]);

  useEffect(() => {
    if (!hasCreatedWarehouse) return;
    searchParams.delete('createWarehouse');
    fetchWarehouses();
  }, [hasCreatedWarehouse]);

  useEffect(() => {
    if (!createWarehouse) return;
    setIsAddWarehouseModalOpen(true);
  }, [createWarehouse]);

  useEffect(() => {
    if (!isAddWarehouseModalOpen) return;
    setHasCreatedWarehouse(false);
  }, [isAddWarehouseModalOpen]);

  const handleOnSubmit = async (data: WarehouseFormValues) => {
    setIsSubmitting(true);
    try {
      const { data: createWarehouseData } = await authHttpFormData.post(
        WAREHOUSE_CREATE,
        {
          warehouse: {
            name: data.name,
            description: data.description,
            address: data.address,
            city: data.city,
            country: data.country,
            location: data.location,
            temperatureControlTypes: data.temperatureControlTypes,
            temperatureMonitoringCapeabilities:
              data.temperatureMonitoringCapeabilities,
            loadingDocks: data.loadingDocks,
            isOffDock: data.officeType === 'offDock',
            isQuay: data.officeType === 'quay',
            hasOnsiteOffice: data.hasOnsiteOffice,
            onsiteOfficePhoneNumber: {
              countryCode: COUNTRY_CODES.find(
                (country) => country.country_code === data.onsitePhoneCountry
              )?.country_code,
              countryNumberPrefix: COUNTRY_CODES.find(
                (country) => country.country_code === data.onsitePhoneCountry
              )?.phone_code.toString(),
              number: data.onsiteOfficePhoneNumber,
            },
            maxStorageCapacity: data.maxStorageCapacity,
            maxStorageCapacityAvailable: data.maxStorageCapacityAvailable,
            currentlyAvailableCapacity: data.currentlyAvailableCapacity,
            minStorageCapacityThreshold: data.minStorageCapacityThreshold,
            minStorageTimeThreshold: data.minStorageTimeThreshold,
            storageTimeUnitOfMeasure: data.storageTimeUnitOfMeasure,
            storageCapacityUnitOfMeasure: data.storageCapacityUnitOfMeasure,
            currency: data.currency,
            palletReceiptAndPutawayPrice: data.palletReceiptAndPutAwayPrice,
            palletPickingAndDespatchPrice: data.palletPickingAndDespatchPrice,
            palletLayerPickingAndDespatchPrice:
              data.palletLayerPickingAndDespatchPrice,
            palletStoragePricePerWeek: data.palletStoragePricePerWeek,
            casePickingAndDespatchPrice: data.casePickingAndDespatchPrice,
            subsequentCasePickingAndDespatchPrice:
              data.subsequentCasePickingAndDespatchPrice,
            itemPickingAndDespatchPrice: data.itemPickingAndDespatchPrice,
            subsequentItemPickingAndDespatchPrice:
              data.subsequentItemPickingAndDespatchPrice,
            containerHandlingPrice: data.containerHandlingPrice,
            squareFeetStoragePricePerWeek: data.palletStoragePricePerWeek,
            squareMetreStoragePricePerWeek: data.palletStoragePricePerWeek,
            demurragePricePerDay: data.demurragePricePerDay,
            palletReturnsPrice: data.palletReturnsPrice,
            itemReturnsPrice: data.itemReturnsPrice,
            caseReturnsPrice: data.caseReturnsPrice,
            auxiliaryServicePricePerManHour:
              data.auxiliaryServicePricePerManHour,
            additionalSurchargesPercentage: data.additionalSurchargesPercentage,
            numberOfShifts: data.numberOfShifts,
            minHeadcount: data.minHeadcount,
            maxHeadcount: data.maxHeadcount,
            standardBusinessHoursType: data.standardBusinessHoursType,
            hasFlexibleBusinessHours: data.hasFlexibleBusinessHours,
            customBusinessHours:
              data.standardBusinessHoursType === 'Other'
                ? {
                    monday_open: data.isMondayClosed
                      ? 'closed'
                      : data.mondayOpen,
                    monday_close: data.isMondayClosed
                      ? 'closed'
                      : data.mondayClose,
                    tuesday_open: data.isTuesdayClosed
                      ? 'closed'
                      : data.tuesdayOpen,
                    tuesday_close: data.isTuesdayClosed
                      ? 'closed'
                      : data.tuesdayClose,
                    wednesday_open: data.isWednesdayClosed
                      ? 'closed'
                      : data.wednesdayOpen,
                    wednesday_close: data.isWednesdayClosed
                      ? 'closed'
                      : data.wednesdayClose,
                    thursday_open: data.isThursdayClosed
                      ? 'closed'
                      : data.thursdayOpen,
                    thursday_close: data.isThursdayClosed
                      ? 'closed'
                      : data.thursdayClose,
                    friday_open: data.isFridayClosed
                      ? 'closed'
                      : data.fridayOpen,
                    friday_close: data.isFridayClosed
                      ? 'closed'
                      : data.fridayClose,
                    saturday_open: data.isSaturdayClosed
                      ? 'closed'
                      : data.saturdayOpen,
                    saturday_close: data.isSaturdayClosed
                      ? 'closed'
                      : data.saturdayClose,
                    sunday_open: data.isSundayClosed
                      ? 'closed'
                      : data.sundayOpen,
                    sunday_close: data.isSundayClosed
                      ? 'closed'
                      : data.sundayClose,
                  }
                : {},
            vehicleLoadingTimeWindows: {
              monday_open: data.hasNoOperationsOnMonday
                ? 'closed'
                : data.loadingMondayOpen,
              monday_close: data.hasNoOperationsOnMonday
                ? 'closed'
                : data.loadingMondayClose,
              tuesday_open: data.hasNoOperationsOnTuesday
                ? 'closed'
                : data.loadingTuesdayOpen,
              tuesday_close: data.hasNoOperationsOnTuesday
                ? 'closed'
                : data.loadingTuesdayClose,
              wednesday_open: data.hasNoOperationsOnWednesday
                ? 'closed'
                : data.loadingWednesdayOpen,
              wednesday_close: data.hasNoOperationsOnWednesday
                ? 'closed'
                : data.loadingWednesdayClose,
              thursday_open: data.hasNoOperationsOnThursday
                ? 'closed'
                : data.loadingThursdayOpen,
              thursday_close: data.hasNoOperationsOnThursday
                ? 'closed'
                : data.loadingThursdayClose,
              friday_open: data.hasNoOperationsOnFriday
                ? 'closed'
                : data.loadingFridayOpen,
              friday_close: data.hasNoOperationsOnFriday
                ? 'closed'
                : data.loadingFridayClose,
              saturday_open: data.hasNoOperationsOnSaturday
                ? 'closed'
                : data.loadingSaturdayOpen,
              saturday_close: data.hasNoOperationsOnSaturday
                ? 'closed'
                : data.loadingSaturdayClose,
              sunday_open: data.hasNoOperationsOnSunday
                ? 'closed'
                : data.loadingSundayOpen,
              sunday_close: data.hasNoOperationsOnSunday
                ? 'closed'
                : data.loadingSundayClose,
            },
            dispatchCutoffTime: data.despatchCutoffTime,
            eavesHeightUnitOfMeasure: data.structuralUoM,
            minEavesHeightFeet:
              data.structuralUoM === 'Foot'
                ? data.minEavesHeightFeet
                : data.minEavesHeightFeet
                ? data.minEavesHeightFeet * 3.2808
                : null,
            minEavesHeightMetres:
              data.structuralUoM === 'Metre'
                ? data.minEavesHeightFeet
                : data.minEavesHeightFeet
                ? data.minEavesHeightFeet / 3.2808
                : null,
            constructionYear: data.constructionYear,
            grossInternalAreaUnitOfMeasure:
              data.structuralUoM === 'Foot' ? 'Square Foot' : 'Square Metre',
            grossInternalAreaSquareFeet:
              data.structuralUoM === 'Square Foot'
                ? data.grossInternalAreaSquareFeet
                : data.grossInternalAreaSquareFeet
                ? data.grossInternalAreaSquareFeet * 10.764
                : null,
            grossInternalAreaSquareMetres:
              data.structuralUoM === 'Square Metre'
                ? data.grossInternalAreaSquareFeet
                : data.grossInternalAreaSquareFeet
                ? data.grossInternalAreaSquareFeet / 10.764
                : null,
            mezzanineAreaUnitOfMeasure:
              data.structuralUoM === 'Foot' ? 'Square Foot' : 'Square Metre',
            mezzanineAreaSquareFeet:
              data.structuralUoM === 'Square Foot'
                ? data.mezzanineAreaSquareFeet
                : data.mezzanineAreaSquareFeet
                ? data.mezzanineAreaSquareFeet * 10.764
                : null,
            mezzanineAreaSquareMetres:
              data.structuralUoM === 'Square Metre'
                ? data.mezzanineAreaSquareFeet
                : data.mezzanineAreaSquareFeet
                ? data.mezzanineAreaSquareFeet / 10.764
                : null,
            isRackingAvailable: data.isRackingAvailable,
            individualRackDepth: data.individualRackDepth,
            individualRackHeight: data.individualRackHeight,
            individualRackWidth: data.individualRackWidth,
            totalRackHeight: data.totalRackHeight,
            storageCapabilities: data.storageCapabilities,
            materialHandlingEquipment: data.materialHandlingEquipment,
            yardSpaceUnitOfMeasure:
              data.accessSpaceUoM === 'Foot' ? 'Square Foot' : 'Square Metre',
            yardSpaceSquareMetres:
              data.accessSpaceUoM === 'Foot'
                ? data.yardSpace
                  ? data.yardSpace / 10.764
                  : null
                : data.yardSpace,
            yardSpaceSquareFeet:
              data.accessSpaceUoM === 'Foot'
                ? data.yardSpace
                : data.yardSpace
                ? data.yardSpace * 10.764
                : null,
            vehicleAccessHeightUnitOfMeasure: data.accessSpaceUoM,
            vehicleAccessHeightFeet:
              data.accessSpaceUoM === 'Foot'
                ? data.vehicleAccessHeight
                : data.vehicleAccessHeight
                ? data.vehicleAccessHeight * 3.2808
                : null,
            vehicleAccessHeightMetres:
              data.accessSpaceUoM === 'Foot'
                ? data.vehicleAccessHeight
                  ? data.vehicleAccessHeight / 3.2808
                  : null
                : data.vehicleAccessHeight,
            leveledLoadingDocks: data.leveledLoadingDocks,
            doubleHeightLoadingDocks: data.doubleHeightLoadingDocks,
            carParkingSpaces: data.carParkingSpaces,
            lorryParkingSpaces: data.lorryParkingSpaces,
            sectorExperience: data.sectorExperience,
            noteableCustomers: [
              data.notableCustomer1,
              data.notableCustomer2,
              data.notalbeCustomer3,
              data.notableCustomer4,
            ],
            warehouseOperations: data.warehouseOperations,
            fulfilmentServices: data.fulfilmentServices,
            fleet: {
              MultiAxleArticMoreThan26t: data.MultiAxleArticMoreThan26t,
              ThreeAxleRigidLessThan26t: data.TwoAxleRigidLessThan26t,
              TwoAxleRigid18t: data.TwoAxleRigid18t,
              TwoAxleRigidLessThan7AndHalf5t:
                data.TwoAxleRigidLessThan7AndHalf5t,
              LgvLessThan3AndHalft: data.LgvLessThan3AndHalft,
              VanLessThan1AndHalft: data.VanLessThan1AndHalft,
              SpecialOrOther: data.SpecialOrOther,
            },
            additionalServices: data.additionalServices,
            safetyAndSecurity: data.safetyAndSecurity,
            systemsAvailable: data.systemsAvailable,
            accreditationsAndMemberships:
              data.accreditationsAndMemberships.includes('None')
                ? []
                : data.accreditationsAndMemberships,
          },
          images: data.siteImages,
        },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      if (createWarehouseData) {
        fetchWarehouses();
        setHasCreatedWarehouse(true);
        setIsSnackbarOpen(true);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const formParams = {
    temperatureControlTypes: temperatureControlledParam,
    maxStorageCapacity: maxStorageCapacityPalletsParam,
    currentlyAvailableCapacity: availableStorageCapacityPalletsParam,
    minStorageTimeThreshold: minServiceTermMonthsParam,
    storageTimeUnitOfMeasure: 'Month',
    minStorageCapacityThreshold: minServiceCapacityParam,
    hasOwnFleet: ownFleetParam === 'Own fleet',
    businessContactName: nameParam,
    businessContactPhoneNumber: phoneParam,
    businessContactEmail: emailParam,
  };

  // const activeWarehouses = warehouses.filter((warehouse) => warehouse.isActive);
  // const delistedWarehouses = warehouses.filter(
  //   (warehouse) => !warehouse.isActive
  // );

  return (
    <View>
      <StyledWarehouseMasterHeaderContainer>
        <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
          Warehouse master
        </Heading>
        <Button
          variant='primary'
          action='cta'
          size={isDesktop ? 'md' : 'sm'}
          leftIcon={<MdAdd size='1.5rem' />}
          onClick={() => {
            if (!businessProfile) {
              setIsNoBusinessProfileOpen(true);
            } else if (
              !businessProfile.warehouseTandCAgreement?.hasAgreed &&
              businessProfile
            ) {
              setIsAgreeToWarehouseTandCsOpen(true);
            } else {
              setIsAddWarehouseModalOpen(true);
            }
          }}
        >
          Add Warehouse
        </Button>
      </StyledWarehouseMasterHeaderContainer>
      <InfiniteScroll
        element={WarehouseList as unknown as ReactNode}
        initialLoad
        loadMore={() => {}}
        hasMore={false}
        useWindow={false}
        getScrollParent={getProductsScrollParent}
        loader={
          <InfiniteScrollLoading
            key='warehouse-loader'
            loadingMessage='Loading warehouses...'
            sx={{ gridColumn: '1 / -1' }}
          />
        }
      >
        {isWarehousesLoading && !warehouses.length && (
          <InfiniteScrollLoading
            key='warehouse-loader'
            loadingMessage='Loading warehouses...'
            sx={{ gridColumn: '1 / -1' }}
          />
        )}
        {!isWarehousesLoading && !warehouses.length && (
          <EmptyState
            icon={<MdOutlineInfo size='8rem' />}
            title='No warehouses found.'
            subtitle='Add a warehouse to get started.'
            primaryAction={
              <Button
                action='cta'
                leftIcon={<MdAdd size='1.5rem' />}
                onClick={() => {
                  if (!businessProfile) {
                    setIsNoBusinessProfileOpen(true);
                  } else if (
                    !businessProfile.warehouseTandCAgreement?.hasAgreed &&
                    businessProfile
                  ) {
                    setIsAgreeToWarehouseTandCsOpen(true);
                  } else {
                    setIsAddWarehouseModalOpen(true);
                  }
                }}
              >
                Add a warehouse
              </Button>
            }
            css={{ gridColumn: '1 / -1' }}
          />
        )}
        {warehouses
          .filter((warehouse) => !warehouse.isDelisted)
          .map((warehouse, index) => (
            <WarehouseCard
              {...(index === 0 && { id: 'first' })}
              key={warehouse.warehouseId}
              warehouseSummary={warehouse}
              orientation={isTablet ? 'horizontal' : 'vertical'}
              onClick={() => navigate(`/warehouse/${warehouse.warehouseId}`)}
            />
          ))}
        <br />
      </InfiniteScroll>
      {warehouses.some((w) => w.isDelisted) && (
        <>
          <Divider />
          <Heading as='h1' size={isDesktop ? 'sm' : 'xs'} className=''>
            Delisted Warehouses
          </Heading>
          <InfiniteScroll
            element={WarehouseList as unknown as ReactNode}
            initialLoad
            loadMore={() => {}}
            hasMore={false}
            useWindow={false}
            getScrollParent={getProductsScrollParent}
          >
            {warehouses
              .filter((warehouse) => warehouse.isDelisted)
              .map((warehouse, index) => (
                <WarehouseCard
                  {...(index === 0 && { id: 'first' })}
                  key={warehouse.warehouseId}
                  warehouseSummary={warehouse}
                  orientation={isTablet ? 'horizontal' : 'vertical'}
                  onClick={() =>
                    navigate(`/warehouse/${warehouse.warehouseId}`)
                  }
                />
              ))}
          </InfiniteScroll>
        </>
      )}
      <Modal
        open={isAddWarehouseModalOpen}
        onOpenChange={(isOpen: boolean) => {
          setIsAddWarehouseModalOpen(isOpen);
        }}
      >
        <ModalContainer preventCloseOnOutsideClick>
          <ModalHeader title='Add Warehouse' />
          <WarehouseForm
            prefilledValues={temperatureControlledParam ? formParams : {}}
            handleOnSubmit={handleOnSubmit}
            isSubmitting={isSubmitting}
          />
        </ModalContainer>
      </Modal>
      <Modal
        open={isAgreeToWarehouseTandCsOpen}
        onOpenChange={(isOpen: boolean) => {
          setIsAgreeToWarehouseTandCsOpen(isOpen);
        }}
      >
        <ModalContainer preventCloseOnOutsideClick>
          <ModalHeader title='Agree to Warehouse Terms and Conditions' />
          <ModalContent>
            <iframe
              src='https://www.flox.is/standard-conditions-of-warehousing-services'
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
                minHeight: '50vh',
              }}
              title='Terms and Conditions'
            />
          </ModalContent>
          <ModalFooter>
            <Button
              variant='primary'
              onClick={handleAgreeToWarehouseTandCs}
              disabled={isAgreeing}
            >
              {isAgreeing
                ? 'Agreeing...'
                : 'I agree to the terms and conditions'}
            </Button>
          </ModalFooter>
        </ModalContainer>
      </Modal>
      <Modal
        open={isNoBusinessProfileOpen}
        onOpenChange={(isOpen: boolean) => {
          setIsNoBusinessProfileOpen(isOpen);
        }}
      >
        <ModalContainer preventCloseOnOutsideClick>
          <ModalHeader title='Action Required: Finalise Your Corporate Details' />
          <ModalContent>
            <Typography variant='body1'>
              To agree to the Warehouse T&Cs and complete your first warehouse,
              we need you to finalise your corporate details. Please update your
              Business Profile by clicking the button below.
            </Typography>
          </ModalContent>
          <ModalFooter>
            <Button variant='primary' onClick={handleGoToBusinessProfile}>
              Take me to my Business Profile
            </Button>
          </ModalFooter>
        </ModalContainer>
      </Modal>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity='info'
          sx={{ width: '100%' }}
        >
          Your warehouse profile is being processed. To ensure your prospective
          clients access accurate and up-to-date information, we may need
          additional details or verify certain data for your profile. If
          required, we&apos;ll reach out via email. Once cleared, your service
          listing will go live on FLOX. Thank you!
        </Alert>
      </Snackbar>
    </View>
  );
}
