/* eslint-disable no-nested-ternary */
import { Text } from '@components/Text';
import { Input } from '@components/Input';
import { InputGroup } from '@components/InputGroup';
import { Label } from '@components/Label';
import {
  DIMENSION_UNITS,
  SKU_NAMES,
  SUPPORTED_CURRENCY_GROUPS,
  WEIGHT_UNITS,
  INVENTORY_IMAGES_URL,
} from '@constants';
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormWatch,
  useFormContext,
  get,
  UseFormGetFieldState,
} from 'react-hook-form';
import {
  Select,
  SelectItem,
  SelectGroup,
  SelectLabel,
  SelectSeparator,
} from '@components/Select';
import { Textarea } from '@components/Textarea';
import { ErrorMessage } from '@components/ErrorMessage';
import { RadioGroup, RadioCard, RadioItem } from '@components/Radio';
import {
  StyledPriceGrid,
  StyledPriceGridItem,
} from '@views/ProductMaster/ProductMaster.styles';
import { Button } from '@components/Button';
import {
  MdAdd,
  MdDelete,
  MdOutlineDelete,
  MdOutlineImage,
} from 'react-icons/md';
import { ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react';
import { Dropzone } from '@components/Dropzone';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { Thumbnail, ThumbnailGrid } from '@components/Thumbnail';
import { StyledSKUEditor } from './SKUEditor.styles';
import {
  AddProductFormValues,
  ProductImage,
  SKU,
  SKUImage,
  UserImage,
} from '../../types';
import { SKUSummary } from '../SKUSummary';

interface SKUEditorProps {
  sku: SKU;
  register: UseFormRegister<AddProductFormValues>;
  control: Control<AddProductFormValues>;
  getValues: UseFormGetValues<AddProductFormValues>;
  setValue: UseFormSetValue<AddProductFormValues>;
  setFocus: UseFormSetFocus<AddProductFormValues>;
  getFieldState: UseFormGetFieldState<AddProductFormValues>;
  watch: UseFormWatch<AddProductFormValues>;
  errors: FieldErrors<AddProductFormValues>;
  touchedFields: any;
  isSmall: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  edit?: boolean;
  index: number;
  remove: () => void;
  setSelectedSkuIndex: Dispatch<SetStateAction<number | null>>;
}

export function SKUEditor({
  sku,
  register,
  control,
  getValues,
  setValue,
  setFocus,
  getFieldState,
  watch,
  errors,
  touchedFields,
  isSmall,
  isTablet,
  isDesktop,
  edit = false,
  index,
  remove,
  setSelectedSkuIndex,
}: SKUEditorProps) {
  const values = watch([
    `product.skus.${index}.unitOfMeasure`,
    `product.skus.${index}.dimensions.dimensionsUnitOfMeasure`,
    `product.skus.${index}.dimensions.width`,
    `product.skus.${index}.dimensions.height`,
    `product.skus.${index}.dimensions.length`,
  ]);

  const hasValidCubeInputs =
    // values[1] !== '' &&
    values[2] !== null && values[3] !== null && values[4] !== null;

  const calculateCube = (width: number, height: number, length: number) =>
    (width * height * length).toFixed(2);

  const {
    fields: listPriceFields,
    append: appendListPrice,
    remove: removeListPrice,
  } = useFieldArray({
    control,
    name: `product.skus.${index}.listPrices`,
  });

  const {
    fields: purchasePriceFields,
    append: appendPurchasePrice,
    remove: removePurchasePrice,
  } = useFieldArray({
    control,
    name: `product.skus.${index}.purchasePrices`,
  });

  useEffect(() => {
    const currentIndex = listPriceFields.length - 1;
    setFocus(`product.skus.${index}.listPrices.${currentIndex}.currency`);
  }, [appendListPrice, index, listPriceFields.length, setFocus]);

  useEffect(() => {
    const currentIndex = purchasePriceFields.length - 1;
    setFocus(`product.skus.${index}.purchasePrices.${currentIndex}.currency`);
  }, [appendPurchasePrice, index, purchasePriceFields.length, setFocus]);

  const hasListPriceError = getFieldState(
    `product.skus.${index}.listPrices`
  ).error;

  const hasPurchasePriceError = getFieldState(
    `product.skus.${index}.purchasePrices`
  ).error;

  const skuImage = getValues().skuImages.find(
    (_skuImage) =>
      _skuImage.id === getValues().product.skus[index].productImageId
  );

  useEffect(() => {
    const { skuImages } = getValues();
    const targetSkuImage = skuImages.find(
      (img) => img.id === getValues().product.skus[index].productImageId
    );

    if (!targetSkuImage) return;
    const updatedSkuImage = {
      ...targetSkuImage,
      skuId: getValues().product.skus[index].skuId,
    };

    Object.assign(targetSkuImage, updatedSkuImage);
  }, [getValues().product.skus[index].skuId]);

  const SKUTitle = `${getValues().product.name} - ${
    getValues().product.skus[index].name
  }`;

  return edit ? (
    <StyledSKUEditor>
      {/* SKU DETAILS */}
      <Text as='h4' size='lg' weight='bold' css={{ mb: '$space16' }}>
        {getValues().product.skus[index].name
          ? SKUTitle
          : `SKU ${index + 1} details`}
      </Text>
      <Stack spacing={16} sx={{ mb: 16 }}>
        <Controller
          name={`product.skus.${index}.skuId`}
          control={control}
          render={({ field, fieldState: { error, isTouched } }) => (
            <TextField
              {...field}
              error={Boolean(error && isTouched)}
              helperText={error?.message}
              variant='outlined'
              label='SKU id (required)'
              placeholder='SKU id (required)'
              multiline={!isDesktop}
              {...(!isDesktop && {
                InputLabelProps: {
                  shrink: true,
                },
              })}
            />
          )}
        />
        <Controller
          name={`product.skus.${index}.barcode`}
          control={control}
          render={({ field, fieldState: { error, isTouched } }) => (
            <TextField
              {...field}
              error={Boolean(error && isTouched)}
              helperText={error?.message}
              variant='outlined'
              label='SKU Barcode'
              placeholder='SKU Barcode'
              multiline={!isDesktop}
              {...(!isDesktop && {
                InputLabelProps: {
                  shrink: true,
                },
              })}
            />
          )}
        />
        <Controller
          name={`product.skus.${index}.name`}
          control={control}
          render={({ field, fieldState: { error, isTouched } }) => (
            <TextField
              {...field}
              error={Boolean(error && isTouched)}
              helperText={error?.message}
              variant='outlined'
              label='Name'
              placeholder='Name'
              multiline={!isDesktop}
              {...(!isDesktop && {
                InputLabelProps: {
                  shrink: true,
                },
              })}
              fullWidth
            />
          )}
        />
        <InputGroup
          css={{
            gap: '$space0',
            mb:
              errors.product?.skus &&
              errors.product.skus[index]?.unitOfMeasure &&
              touchedFields.product?.skus &&
              touchedFields.product.skus[index]?.unitOfMeasure
                ? '$space8'
                : '$space0',
          }}
        >
          <Label htmlFor={`product.skus.${index}.unitOfMeasure`} visuallyHidden>
            Unit Of Measure
          </Label>
          <Controller
            name={`product.skus.${index}.unitOfMeasure`}
            control={control}
            render={({ field }) => {
              return (
                <Select
                  ref={field.ref}
                  {...(getValues().product.skus[index].unitOfMeasure !== '' && {
                    defaultValue: getValues().product.skus[index].unitOfMeasure,
                  })}
                  onValueChange={(value: string) => {
                    field.onChange(value);
                    if (value === 'Item') {
                      setValue(`product.skus.${index}.amountOfProductInSku`, 1);
                    } else {
                      setValue(
                        `product.skus.${index}.amountOfProductInSku`,
                        null
                      );
                    }
                  }}
                  onBlur={field.onBlur}
                  placeholder='Unit of Measure (required)'
                  isInvalid={
                    errors.product?.skus &&
                    errors.product.skus[index]?.unitOfMeasure &&
                    touchedFields.product?.skus &&
                    touchedFields.product.skus[index]?.unitOfMeasure
                  }
                  css={{
                    gap: '$space0',
                    mb:
                      errors.product?.skus &&
                      errors.product.skus[index]?.unitOfMeasure &&
                      touchedFields.product?.skus &&
                      touchedFields.product.skus[index]?.unitOfMeasure
                        ? '$space8'
                        : '$space0',
                  }}
                >
                  {SKU_NAMES.map((skuName) => (
                    <SelectItem key={skuName.label} value={skuName.value}>
                      {`${skuName.label}`}
                    </SelectItem>
                  ))}
                </Select>
              );
            }}
          />
          {errors.product?.skus &&
            errors.product.skus[index]?.unitOfMeasure &&
            touchedFields.product?.skus &&
            touchedFields.product.skus[index]?.unitOfMeasure && (
              <ErrorMessage>
                {errors.product?.skus[index]?.unitOfMeasure?.message}
              </ErrorMessage>
            )}
        </InputGroup>
        <Controller
          name={`product.skus.${index}.description`}
          control={control}
          render={({ field, fieldState: { error, isTouched } }) => (
            <TextField
              {...field}
              multiline
              minRows={2}
              error={Boolean(error && isTouched)}
              helperText={error?.message}
              label='Description'
              placeholder='Description'
              {...(!isDesktop && {
                InputLabelProps: {
                  shrink: true,
                },
              })}
              variant='outlined'
              inputProps={{
                sx: {
                  resize: 'vertical',
                },
              }}
            />
          )}
        />
        <Controller
          name={`product.skus.${index}.amountOfProductInSku`}
          control={control}
          render={({ field, fieldState: { error, isTouched } }) => (
            <TextField
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              {...field}
              value={
                getValues().product.skus[index].amountOfProductInSku === null
                  ? ''
                  : getValues().product.skus[index].amountOfProductInSku
              }
              error={Boolean(error && isTouched)}
              helperText={error?.message}
              variant='outlined'
              label='Amount of product in SKU (required)'
              placeholder='Amount of product in SKU (required)'
              multiline={!isDesktop}
              {...(!isDesktop && {
                InputLabelProps: {
                  shrink: true,
                },
              })}
              fullWidth
            />
          )}
        />
      </Stack>

      {/* SKU PRODUCT IMAGE */}
      <Text as='h4' size='lg' weight='bold' css={{ mb: '$space16' }}>
        {getValues().product.skus[index].name
          ? SKUTitle
          : `SKU ${index + 1} image`}
      </Text>
      <ThumbnailGrid>
        {getValues().product.skus[index].images.length > 0 ||
        getValues().skuImages?.length > 0 ? (
          <>
            {getValues()
              .skuImages.map((img: UserImage) => ({
                preview: URL.createObjectURL(img.image),
                title: img.title,
                id: img.id,
              }))
              .concat(
                getValues().product.skus[index].images.map(
                  (img: ProductImage) => ({
                    preview: `${INVENTORY_IMAGES_URL}/${img.imageLocation}/small/${img.remoteName}`,
                    title: img.title,
                    id: img.id,
                  })
                )
              )
              .map((img, keyIndex) => (
                <Thumbnail
                  key={`${img.title}${keyIndex.toString()}`}
                  src={img.preview}
                >
                  <Button
                    size='xs'
                    action='danger'
                    leftIcon={<MdDelete size='1rem' />}
                    onClick={() => {
                      if (
                        getValues().skuImages.some((i: any) => i.id === img.id)
                      ) {
                        setValue(
                          'skuImages',
                          getValues().skuImages.filter(
                            (i: any) => i.id !== img.id
                          )
                        );
                      }

                      if (
                        getValues().product.skus[index].images.some(
                          (i: any) => i.id === img.id
                        )
                      ) {
                        setValue(
                          `product.skus.${index}.images`,
                          getValues().product.skus[index].images.filter(
                            (i: any) => i.id !== img.id
                          )
                        );

                        setValue(
                          'deletedSkuImageIds',
                          getValues().deletedSkuImageIds.concat(img.id)
                        );
                      }
                    }}
                    css={{
                      position: 'absolute',
                      bottom: '$space8',
                      right: '$space8',
                    }}
                  >
                    {/* Delete */}
                  </Button>
                </Thumbnail>
              ))}
          </>
        ) : (
          <InputGroup css={{ gap: '$space0', mb: '$space16', width: '75%' }}>
            <>
              <Label htmlFor='images' visuallyHidden>
                Images
              </Label>
              <Controller
                name='skuImages'
                control={control}
                render={() => {
                  return (
                    <Dropzone
                      {...register('skuImages')}
                      id='images'
                      primaryAction={
                        <Button
                          size={isDesktop ? 'xs' : isSmall ? 'sm' : 'xs'}
                          leftIcon={
                            <MdAdd size={isSmall ? '1.25rem' : '1rem'} />
                          }
                        >
                          Add image
                        </Button>
                      }
                      onDrop={(acceptedFiles: any) => {
                        const randomID = crypto.randomUUID();
                        setValue(
                          `product.skus.${index}.productImageId`,
                          randomID
                        );
                        setValue('skuImages', [
                          ...getValues().skuImages,
                          ...acceptedFiles.map((file: File): SKUImage => {
                            return {
                              skuId: getValues().product.skus[index].skuId
                                ? getValues().product.skus[index].skuId
                                : '',
                              id: randomID,
                              title: file.name,
                              image: file,
                              imageLocation: '',
                              remoteName: '',
                            };
                          }),
                        ]);
                      }}
                    />
                  );
                }}
              />
            </>
          </InputGroup>
        )}
      </ThumbnailGrid>

      {/* SKU BARCODE IMAGE */}
      <Text as='h4' size='lg' weight='bold' css={{ mb: '$space16' }}>
        {getValues().product.skus[index].name
          ? `${SKUTitle} barcode image`
          : `SKU ${index + 1} barcode image`}
      </Text>
      <ThumbnailGrid>
        {getValues().product.skus[index].barcodeImages.length > 0 ||
        getValues().skuBarcodeImages?.length > 0 ? (
          <>
            {getValues()
              .skuBarcodeImages.map((img: UserImage) => ({
                preview: URL.createObjectURL(img.image),
                title: img.title,
                id: img.id,
              }))
              .concat(
                getValues().product.skus[index].barcodeImages.map(
                  (img: ProductImage) => ({
                    preview: `${INVENTORY_IMAGES_URL}/${img.imageLocation}/small/${img.remoteName}`,
                    title: img.title,
                    id: img.id,
                  })
                )
              )
              .map((img, keyIndex) => (
                <Thumbnail
                  key={`${img.title}${keyIndex.toString()}`}
                  src={img.preview}
                >
                  <Button
                    size='xs'
                    action='danger'
                    leftIcon={<MdDelete size='1rem' />}
                    onClick={() => {
                      if (
                        getValues().skuBarcodeImages.some(
                          (i: any) => i.id === img.id
                        )
                      ) {
                        setValue(
                          'skuBarcodeImages',
                          getValues().skuBarcodeImages.filter(
                            (i: any) => i.id !== img.id
                          )
                        );
                      }

                      if (
                        getValues().product.skus[index].barcodeImages.some(
                          (i: any) => i.id === img.id
                        )
                      ) {
                        setValue(
                          `product.skus.${index}.barcodeImages`,
                          getValues().product.skus[index].barcodeImages.filter(
                            (i: any) => i.id !== img.id
                          )
                        );

                        setValue(
                          'deletedSkuImageIds',
                          getValues().deletedSkuImageIds.concat(img.id)
                        );
                      }
                    }}
                    css={{
                      position: 'absolute',
                      bottom: '$space8',
                      right: '$space8',
                    }}
                  >
                    {/* Delete */}
                  </Button>
                </Thumbnail>
              ))}
          </>
        ) : (
          <InputGroup css={{ gap: '$space0', mb: '$space16', width: '75%' }}>
            <>
              <Label htmlFor='images' visuallyHidden>
                Images
              </Label>
              <Controller
                name='skuBarcodeImages'
                control={control}
                render={() => {
                  return (
                    <Dropzone
                      {...register('skuBarcodeImages')}
                      id='images'
                      primaryAction={
                        <Button
                          size={isDesktop ? 'xs' : isSmall ? 'sm' : 'xs'}
                          leftIcon={
                            <MdAdd size={isSmall ? '1.25rem' : '1rem'} />
                          }
                        >
                          Add image
                        </Button>
                      }
                      onDrop={(acceptedFiles: any) => {
                        const randomID = crypto.randomUUID();
                        setValue('skuBarcodeImages', [
                          ...getValues().skuBarcodeImages,
                          ...acceptedFiles.map((file: File): SKUImage => {
                            return {
                              skuId: getValues().product.skus[index].skuId
                                ? getValues().product.skus[index].skuId
                                : '',
                              id: randomID,
                              title: file.name,
                              image: file,
                              imageLocation: '',
                              remoteName: '',
                            };
                          }),
                        ]);
                      }}
                    />
                  );
                }}
              />
            </>
          </InputGroup>
        )}
      </ThumbnailGrid>

      {/* DIMENSIONS */}
      <InputGroup direction='row' css={{ gap: '$space16', mb: '$space16' }}>
        <Label
          htmlFor={`product.skus.${index}.unitOfMeasureOtherName`}
          css={{ fontSize: '$textmd', fontWeight: 'bold' }}
        >
          Dimensions
        </Label>
        <Controller
          name={`product.skus.${index}.dimensions.dimensionsUnitOfMeasure`}
          control={control}
          render={({ field }) => {
            return (
              <Select
                onValueChange={field.onChange}
                defaultValue={
                  getValues().product.skus[index].dimensions
                    .dimensionsUnitOfMeasure
                }
                onBlur={field.onBlur}
                placeholder='Unit of measure'
                css={{ width: '7rem' }}
              >
                {DIMENSION_UNITS.map((dimensionUnit) => (
                  <SelectItem
                    key={dimensionUnit.label}
                    value={dimensionUnit.value}
                  >
                    {`${dimensionUnit.label}`}
                  </SelectItem>
                ))}
              </Select>
            );
          }}
        />
      </InputGroup>
      <Stack spacing={16} sx={{ mb: 16 }}>
        <Controller
          name={`product.skus.${index}.dimensions.length`}
          control={control}
          render={({ field, fieldState: { error, isTouched } }) => (
            <TextField
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              {...field}
              error={Boolean(error && isTouched)}
              helperText={error?.message}
              variant='outlined'
              label='Length (required)'
              placeholder='Length (required)'
              multiline={!isDesktop}
              {...(!isDesktop && {
                InputLabelProps: {
                  shrink: true,
                },
              })}
              fullWidth
            />
          )}
        />
        <Controller
          name={`product.skus.${index}.dimensions.width`}
          control={control}
          render={({ field, fieldState: { error, isTouched } }) => (
            <TextField
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              {...field}
              error={Boolean(error && isTouched)}
              helperText={error?.message}
              variant='outlined'
              label='Width (required)'
              placeholder='Width (required)'
              multiline={!isDesktop}
              {...(!isDesktop && {
                InputLabelProps: {
                  shrink: true,
                },
              })}
              fullWidth
            />
          )}
        />
        <Controller
          name={`product.skus.${index}.dimensions.height`}
          control={control}
          render={({ field, fieldState: { error, isTouched } }) => (
            <TextField
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              {...field}
              error={Boolean(error && isTouched)}
              helperText={error?.message}
              variant='outlined'
              label='Height (required)'
              placeholder='Height (required)'
              multiline={!isDesktop}
              {...(!isDesktop && {
                InputLabelProps: {
                  shrink: true,
                },
              })}
              fullWidth
            />
          )}
        />
      </Stack>
      {hasValidCubeInputs && (
        <Text size='sm' weight='regular' css={{ mb: '$space16' }}>
          <Text as='span' size='sm' weight='bold'>
            Total cube:{' '}
          </Text>{' '}
          {calculateCube(
            values[2] as number,
            values[3] as number,
            values[4] as number
          )}
          {values[1]}
          <sup>3</sup>
        </Text>
      )}
      {/* WEIGHT */}
      <InputGroup direction='row' css={{ gap: '$space16', mb: '$space16' }}>
        <Label
          htmlFor={`product.skus.${index}.grossWeightUnitOfMeasure`}
          css={{ fontSize: '$textmd', fontWeight: 'bold' }}
        >
          Gross weight
        </Label>
        <Controller
          name={`product.skus.${index}.grossWeightUnitOfMeasure`}
          control={control}
          render={({ field }) => {
            return (
              <Select
                onValueChange={field.onChange}
                defaultValue={
                  getValues().product.skus[index].grossWeightUnitOfMeasure ||
                  'Kg'
                }
                onBlur={field.onBlur}
                placeholder='Unit of measure'
                css={{ width: '7rem' }}
              >
                {WEIGHT_UNITS.map((weightUnit) => (
                  <SelectItem key={weightUnit.label} value={weightUnit.value}>
                    {`${weightUnit.label}`}
                  </SelectItem>
                ))}
              </Select>
            );
          }}
        />
      </InputGroup>
      <Controller
        name={`product.skus.${index}.grossWeight`}
        control={control}
        render={({ field, fieldState: { error, isTouched } }) => (
          <TextField
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            {...field}
            error={Boolean(error && isTouched)}
            helperText={error?.message}
            variant='outlined'
            label='Gross weight (required)'
            placeholder='Gross weight (required)'
            multiline={!isDesktop}
            {...(!isDesktop && {
              InputLabelProps: {
                shrink: true,
              },
            })}
            fullWidth
            sx={{
              mb: 16,
            }}
          />
        )}
      />
      {/* LIST PRICES */}
      <Stack
        direction={isDesktop ? 'row' : 'column'}
        spacing={isDesktop ? 24 : 0}
      >
        <div style={{ width: '100%' }}>
          <Text as='h4' size='lg' weight='bold' css={{ mb: '$space16' }}>
            List prices
          </Text>
          <StyledPriceGrid>
            {listPriceFields.map((item, listPriceIndex) => {
              const currencyError = getFieldState(
                `product.skus.${index}.listPrices.${listPriceIndex}.currency`
              ).error;

              const currencyTouched = getFieldState(
                `product.skus.${index}.listPrices.${listPriceIndex}.currency`
              ).isTouched;

              const amountError = getFieldState(
                `product.skus.${index}.listPrices.${listPriceIndex}.amount`
              ).error;

              const amountTouched = getFieldState(
                `product.skus.${index}.listPrices.${listPriceIndex}.amount`
              ).isTouched;

              return (
                <StyledPriceGridItem key={item.id}>
                  <InputGroup direction='row' css={{ gap: '$space16' }}>
                    <Text
                      as='h4'
                      size='md'
                      weight='bold'
                      css={{ mb: '$space8', minWidth: '4rem' }}
                    >
                      {`List price ${listPriceIndex + 1}`}
                    </Text>

                    <InputGroup css={{ gap: '$space0' }}>
                      <Label
                        htmlFor={`product.skus.${index}.listPrices.${listPriceIndex}.currency`}
                        visuallyHidden
                      >
                        Currency
                      </Label>
                      <Controller
                        name={`product.skus.${index}.listPrices.${listPriceIndex}.currency`}
                        control={control}
                        render={({ field }) => {
                          return (
                            <>
                              <Select
                                ref={field.ref}
                                onValueChange={field.onChange}
                                {...(getValues().product.skus[index].listPrices[
                                  listPriceIndex
                                ].currency !== '' && {
                                  defaultValue:
                                    getValues().product.skus[index].listPrices[
                                      listPriceIndex
                                    ].currency,
                                })}
                                isInvalid={currencyError && currencyTouched}
                                onBlur={field.onBlur}
                                placeholder='Currency...'
                                css={{
                                  mb:
                                    currencyError &&
                                    currencyTouched &&
                                    '$space8',
                                }}
                              >
                                {SUPPORTED_CURRENCY_GROUPS.map(
                                  (currencyGroup, currencyGroupIndex) => (
                                    <>
                                      <SelectGroup
                                        key={currencyGroup.groupName}
                                      >
                                        <SelectLabel>
                                          {currencyGroup.groupName}
                                        </SelectLabel>
                                        {currencyGroup.currencies.map(
                                          (currency) => (
                                            <SelectItem
                                              key={currency.code}
                                              value={currency.code}
                                            >
                                              {`${currency.code}`}
                                            </SelectItem>
                                          )
                                        )}
                                      </SelectGroup>
                                      {currencyGroupIndex <
                                        SUPPORTED_CURRENCY_GROUPS.length -
                                          1 && <SelectSeparator />}
                                    </>
                                  )
                                )}
                              </Select>
                              {currencyError && currencyTouched && (
                                <ErrorMessage>
                                  Currency is required.
                                </ErrorMessage>
                              )}
                            </>
                          );
                        }}
                      />
                    </InputGroup>
                  </InputGroup>
                  <Stack spacing={16}>
                    <Controller
                      name={`product.skus.${index}.listPrices.${listPriceIndex}.amount`}
                      control={control}
                      render={({ field, fieldState: { error, isTouched } }) => (
                        <TextField
                          inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                          }}
                          {...field}
                          error={Boolean(error && isTouched)}
                          helperText={error?.message}
                          variant='outlined'
                          label='Amount (required)'
                          placeholder='Amount (required)'
                          multiline={!isDesktop}
                          {...(!isDesktop && {
                            InputLabelProps: {
                              shrink: true,
                            },
                          })}
                          fullWidth
                        />
                      )}
                    />
                    <Controller
                      name={`product.skus.${index}.listPrices.${listPriceIndex}.comment`}
                      control={control}
                      render={({ field, fieldState: { error, isTouched } }) => (
                        <TextField
                          {...field}
                          multiline
                          minRows={2}
                          error={Boolean(error && isTouched)}
                          helperText={Boolean(error?.message)}
                          label='Comment'
                          placeholder='Comment'
                          {...(!isDesktop && {
                            InputLabelProps: {
                              shrink: true,
                            },
                          })}
                          variant='outlined'
                          inputProps={{
                            sx: {
                              resize: 'vertical',
                            },
                          }}
                          fullWidth
                        />
                      )}
                    />
                  </Stack>
                  <Button
                    leftIcon={<MdOutlineDelete size='1.5rem' />}
                    isFullWidth
                    onClick={() => removeListPrice(listPriceIndex)}
                  >
                    Remove
                  </Button>
                </StyledPriceGridItem>
              );
            })}
            {/* <StyledAddPriceContainer> */}
            <Button
              size='md'
              leftIcon={<MdAdd size='1.5rem' />}
              onClick={() =>
                appendListPrice(
                  {
                    amount: null,
                    currency: '',
                    comment: '',
                  },
                  {
                    shouldFocus: true,
                    focusIndex: 0,
                    focusName: `product.skus.${index}.listPrices.${
                      listPriceFields.length - 1
                    }.amount`,
                  }
                )
              }
              disabled={
                hasListPriceError && Object.keys(hasListPriceError).length > 0
              }
            >
              Add list price
            </Button>
            {/* </StyledAddPriceContainer> */}
          </StyledPriceGrid>
        </div>
        {/* PURCHASE PRICES */}
        <div style={{ width: '100%' }}>
          <Text as='h4' size='lg' weight='bold' css={{ mb: '$space16' }}>
            Purchase prices
          </Text>
          <StyledPriceGrid>
            {purchasePriceFields.map((item, purchasePriceIndex) => {
              const currencyError = getFieldState(
                `product.skus.${index}.purchasePrices.${purchasePriceIndex}.currency`
              ).error;

              const currencyTouched = getFieldState(
                `product.skus.${index}.purchasePrices.${purchasePriceIndex}.currency`
              ).isTouched;

              const amountError = getFieldState(
                `product.skus.${index}.purchasePrices.${purchasePriceIndex}.amount`
              ).error;

              const amountTouched = getFieldState(
                `product.skus.${index}.purchasePrices.${purchasePriceIndex}.amount`
              ).isTouched;
              return (
                <StyledPriceGridItem key={item.id}>
                  <InputGroup direction='row' css={{ gap: '$space16' }}>
                    <Text
                      as='h4'
                      size='sm'
                      weight='bold'
                      css={{ mb: '$space8' }}
                    >
                      {`Purchase price ${purchasePriceIndex + 1}`}
                    </Text>
                    <InputGroup css={{ gap: '$space0' }}>
                      <Label
                        htmlFor={`product.skus.${index}.purchasePrices.${purchasePriceIndex}.currency`}
                        visuallyHidden
                      >
                        Currency
                      </Label>
                      <Controller
                        name={`product.skus.${index}.purchasePrices.${purchasePriceIndex}.currency`}
                        control={control}
                        render={({ field }) => {
                          return (
                            <>
                              <Select
                                ref={field.ref}
                                onValueChange={field.onChange}
                                {...(getValues().product.skus[index]
                                  .purchasePrices[purchasePriceIndex]
                                  .currency !== '' && {
                                  defaultValue:
                                    getValues().product.skus[index]
                                      .purchasePrices[purchasePriceIndex]
                                      .currency,
                                })}
                                isInvalid={currencyError && currencyTouched}
                                onBlur={field.onBlur}
                                placeholder='Currency...'
                                css={{
                                  mb:
                                    currencyError &&
                                    currencyTouched &&
                                    '$space8',
                                }}
                              >
                                {SUPPORTED_CURRENCY_GROUPS.map(
                                  (currencyGroup, currencyGroupIndex) => (
                                    <>
                                      <SelectGroup
                                        key={currencyGroup.groupName}
                                      >
                                        <SelectLabel>
                                          {currencyGroup.groupName}
                                        </SelectLabel>
                                        {currencyGroup.currencies.map(
                                          (currency) => (
                                            <SelectItem
                                              key={currency.code}
                                              value={currency.code}
                                            >
                                              {`${currency.code}`}
                                            </SelectItem>
                                          )
                                        )}
                                      </SelectGroup>
                                      {currencyGroupIndex <
                                        SUPPORTED_CURRENCY_GROUPS.length -
                                          1 && <SelectSeparator />}
                                    </>
                                  )
                                )}
                              </Select>
                              {currencyError && currencyTouched && (
                                <ErrorMessage>
                                  Currency is required.
                                </ErrorMessage>
                              )}
                            </>
                          );
                        }}
                      />
                    </InputGroup>
                  </InputGroup>
                  <Stack spacing={16}>
                    <Controller
                      name={`product.skus.${index}.purchasePrices.${purchasePriceIndex}.amount`}
                      control={control}
                      render={({ field, fieldState: { error, isTouched } }) => (
                        <TextField
                          inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                          }}
                          {...field}
                          error={Boolean(error && isTouched)}
                          helperText={error?.message}
                          variant='outlined'
                          label='Amount (required)'
                          placeholder='Amount (required)'
                          multiline={!isDesktop}
                          {...(!isDesktop && {
                            InputLabelProps: {
                              shrink: true,
                            },
                          })}
                          fullWidth
                        />
                      )}
                    />
                    <Controller
                      name={`product.skus.${index}.purchasePrices.${purchasePriceIndex}.comment`}
                      control={control}
                      render={({ field, fieldState: { error, isTouched } }) => (
                        <TextField
                          {...field}
                          multiline
                          minRows={2}
                          error={Boolean(error && isTouched)}
                          helperText={Boolean(error?.message)}
                          label='Comment'
                          placeholder='Comment'
                          {...(!isDesktop && {
                            InputLabelProps: {
                              shrink: true,
                            },
                          })}
                          variant='outlined'
                          inputProps={{
                            sx: {
                              resize: 'vertical',
                            },
                          }}
                          fullWidth
                        />
                      )}
                    />
                  </Stack>
                  <Button
                    leftIcon={<MdOutlineDelete size='1.5rem' />}
                    isFullWidth
                    onClick={() => removePurchasePrice(purchasePriceIndex)}
                  >
                    Remove
                  </Button>
                </StyledPriceGridItem>
              );
            })}
            {/* <StyledAddPriceContainer> */}
            <Button
              size='md'
              leftIcon={<MdAdd size='1.5rem' />}
              onClick={() =>
                appendPurchasePrice(
                  {
                    amount: null,
                    currency: '',
                    comment: '',
                  },
                  {
                    shouldFocus: true,
                    focusIndex: 0,
                    focusName: `product.skus.${index}.purchasePrices.${
                      purchasePriceFields.length - 1
                    }.amount`,
                  }
                )
              }
              css={{ mb: '$space16' }}
              disabled={
                hasPurchasePriceError &&
                Object.keys(hasPurchasePriceError).length > 0
              }
            >
              Add purchase price
            </Button>
            {/* </StyledAddPriceContainer> */}
          </StyledPriceGrid>
        </div>
      </Stack>
      <Button
        size='md'
        action='danger'
        leftIcon={<MdDelete size='1.5rem' />}
        isFullWidth
        onClick={remove}
      >
        Delete SKU
      </Button>
    </StyledSKUEditor>
  ) : (
    <SKUSummary
      getValues={getValues}
      remove={remove}
      skuIndex={index}
      setSelectedSkuIndex={setSelectedSkuIndex}
    />
  );
}
