/* eslint-disable no-nested-ternary */
import { Heading } from '@components/Heading';
import { Text } from '@components/Text';
import { View } from '@views/View';
import { useAuth } from '@context';
import { useAtomValue } from 'jotai';
import { useMediaQuery } from 'usehooks-ts';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Stack, TextField } from '@mui/material';
import Button from '@components/NewButton/Button';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MdAddCircle, MdCheck } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DialogModal } from '@components/DialogModal';
import { Enable2FAForm } from '@forms/Enable2FAForm/Enable2FAForm';
import { InputGroup } from '@components/InputGroup';
import { Label } from '@components/Label';
import {
  BUSINESS_PROFILE_CREATE,
  BUSINESS_PROFILE_UPDATE,
  COUNTRY_CODES,
} from '../../constants';
import { useAuthHttp } from '../../hooks';
import { businessProfileAtom } from '../../store/jotai';
import FinanceForm from './FinanceForm';
import { classNames } from '../../utils/classes';

const schema = Yup.object({
  businessNumber: Yup.string().required('Company number is required'),
  businessAddress: Yup.string().required('Registered address is required'),
  vatNumber: Yup.string().required('VAT number is required'),
  businessIndustry: Yup.string().nullable(),
  businessWebsite: Yup.string().nullable(),
  businessContactName: Yup.string().required('Contact name is required'),
  country: Yup.string().required('Please select a country.'),
  businessContactPhoneNumber: Yup.string()
    .matches(/^(?:0|\+?44)(?:\d\s?){9,10}$/g, {
      message: 'Please enter a valid phone number',
      excludeEmptyString: true,
    })
    .required('Business contact phone number is required'),
  businessContactEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Business contact email is required'),
});

export type UpdateBusinessProfileFormValues = {
  businessName: string;
  businessNumber: string;
  businessAddress: string;
  vatNumber: string;
  businessIndustry: string;
  businessWebsite: string;
  businessContactName: string;
  country: string;
  businessContactPhoneNumber: string;
  businessContactEmail: string;
};

export type BusinessProfile = {
  businessProfile: {
    id: string;
    businessType: string;
    businessSize: string;
    contactName: string;
    targetCustomerType: string;
    numberOfPalletsInAndOutPerWeek: number;
    averageShipmentSizePerWeek: number;
    averageShipmentType: string;
    teamSize: string;
    address: string;
    companyNumber: string;
    industry: string;
    website: string;
    contactEmail: string;
    contactPhoneNumber: {
      countryCode: 'string';
      countryNumberPrefix: 'string';
      number: 'string';
    };
    hasConnectedBankAccount: boolean;
    vatNumber: string;
    warehouseTandCAgreement: {
      hasAgreed: boolean;
      agreedById: string;
      agreedByEmailAddress: string;
      agreedByName: string;
      agreementDate: string;
    };
  };
};

export function Profile() {
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const { user } = useAuth();
  const authHttp = useAuthHttp();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [
    isUpdateBusinessProfileFormSubmitting,
    setIsUpdateBusinessProfileFormSubmitting,
  ] = useState(false);
  const [
    hasUpdateBusinessProfileFormSubmitted,
    setHasUpdateBusinessProfileFormSubmitted,
  ] = useState(false);
  const businessProfile = useAtomValue(businessProfileAtom);
  const [isBusinessProfileComplete, setIsBusinessProfileComplete] = useState(
    !!businessProfile?.companyNumber &&
      !!businessProfile?.address &&
      !!businessProfile?.contactEmail &&
      !!businessProfile?.contactName
  );
  const [is2faModalOpen, setIs2faModalOpen] = useState(false);
  const [haveSetUp2Fa, setHaveSetUp2Fa] = useState(
    user?.twoFactorMethodIds !== undefined && user.twoFactorMethodIds.length > 0
  );

  const warehouseIDParam = searchParams.get('warehouseID') || '';

  const defaultValues = {
    businessName: user?.businessName || '',
    businessNumber: businessProfile?.companyNumber || '',
    businessAddress: businessProfile?.address || '',
    businessIndustry: businessProfile?.industry || '',
    businessWebsite: businessProfile?.website || '',
    businessContactName: businessProfile?.contactName || '',
    country: businessProfile?.contactPhoneNumber?.countryCode || '',
    businessContactPhoneNumber:
      businessProfile?.contactPhoneNumber.number || '',
    businessContactEmail: businessProfile?.contactEmail || '',
    vatNumber: businessProfile?.vatNumber || '',
  };

  const methods = useForm<UpdateBusinessProfileFormValues>({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { ...defaultValues },
  });
  const { handleSubmit, control, reset } = methods;

  const handleUpdateBusinessProfileFormSubmit = async (
    values: UpdateBusinessProfileFormValues
  ) => {
    setIsUpdateBusinessProfileFormSubmitting(true);
    try {
      const { data } = await authHttp.post<BusinessProfile>(
        businessProfile ? BUSINESS_PROFILE_UPDATE : BUSINESS_PROFILE_CREATE,
        {
          businessProfile: {
            address: values.businessAddress,
            companyNumber: values.businessNumber,
            industry: values.businessIndustry,
            website: values.businessWebsite,
            contactName: values.businessContactName,
            contactEmail: values.businessContactEmail,
            contactPhoneNumber: {
              countryCode: COUNTRY_CODES.find(
                (country) => country.country_code === values.country
              )?.country_code,
              countryNumberPrefix: COUNTRY_CODES.find(
                (country) => country.country_code === values.country
              )?.phone_code.toString(),
              number: values.businessContactPhoneNumber,
            },
            vatNumber: values.vatNumber,
          },
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (data) {
        setHasUpdateBusinessProfileFormSubmitted(true);
        if (warehouseIDParam) navigate(`/warehouse/${warehouseIDParam}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsUpdateBusinessProfileFormSubmitting(false);
    }
  };

  useEffect(() => {
    reset({
      businessNumber: businessProfile?.companyNumber || '',
      businessAddress: businessProfile?.address || '',
      businessIndustry: businessProfile?.industry || '',
      businessWebsite: businessProfile?.website || '',
      businessContactName: businessProfile?.contactName || '',
      country: businessProfile?.contactPhoneNumber?.countryCode || '',
      businessContactPhoneNumber:
        businessProfile?.contactPhoneNumber.number || '',
      businessContactEmail: businessProfile?.contactEmail || '',
      vatNumber: businessProfile?.vatNumber || '',
    });
    setIsBusinessProfileComplete(
      !!businessProfile?.companyNumber &&
        !!businessProfile?.address &&
        !!businessProfile?.contactEmail &&
        !!businessProfile?.contactName
    );
  }, [businessProfile]);

  return (
    <View>
      <Stack spacing={36}>
        <Stack spacing={12}>
          <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
            Profile
          </Heading>
          <Text>
            <strong>Business name: </strong>
            {user?.businessName}
          </Text>
          <Text>
            <strong>Name: </strong>
            {`${user?.firstName} ${user?.lastName}`}
          </Text>
          <Text>
            <strong>Email: </strong>
            {user?.email}
          </Text>
          <Text>
            <strong>Mobile phone number: </strong>
            {user?.mobilePhoneNumber}
          </Text>
          {!haveSetUp2Fa && (
            <Stack spacing={16} width={isDesktop ? '33%' : '100%'}>
              <Button
                label='Enable 2FA'
                onClick={() => setIs2faModalOpen(true)}
                size='sm'
              />
            </Stack>
          )}
          {haveSetUp2Fa && (
            <Text>2 Factor Authentication is enabled for this account</Text>
          )}
          <Stack
            spacing={16}
            width={isDesktop ? '33%' : '100%'}
            justifyContent='center'
            alignItems='center'
          >
            <DialogModal
              open={is2faModalOpen}
              setOpen={setIs2faModalOpen}
              onClose={() => setIs2faModalOpen(false)}
              title='Enable 2 Factor Authentication'
              id='enable-2fa-modal'
              fullWidth={false}
            >
              <Enable2FAForm
                haveSetUp2Fa={haveSetUp2Fa}
                setHaveSetUp2Fa={setHaveSetUp2Fa}
              />
            </DialogModal>
          </Stack>
        </Stack>
        {user?.roles.includes('BusinessUserManager') && (
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(handleUpdateBusinessProfileFormSubmit)}
              noValidate
              style={{ width: '100%' }}
            >
              <Stack spacing={16} width={isDesktop ? '33%' : '100%'}>
                <Heading as='h3' size='xs'>
                  Business Profile
                </Heading>
                {/* <Controller
                  name='businessName'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Company name *'
                      placeholder='Company name *'
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                /> */}
                <Controller
                  name='businessNumber'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Company number *'
                      placeholder='Company number *'
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                />
                <Controller
                  name='businessAddress'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Registered address *'
                      placeholder='Registered address *'
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                />
                <Controller
                  name='vatNumber'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='VAT *'
                      placeholder='GB123 456 789'
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                />
                <Controller
                  name='businessIndustry'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Company industry'
                      placeholder='Company industry'
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                />
                <Controller
                  name='businessWebsite'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Corporate website'
                      placeholder='Corporate website'
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                />
                <Controller
                  name='businessContactName'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Contact Name *'
                      placeholder='Contact Name *'
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                />
                <InputGroup css={{ gap: '$space0' }}>
                  <Label htmlFor='businessContactPhoneNumber' visuallyHidden>
                    Business contact phone number
                  </Label>
                  <div className='w-full'>
                    <div
                      className={classNames(
                        isUpdateBusinessProfileFormSubmitting
                          ? 'outline-gray-200'
                          : '',
                        'flex rounded-lg bg-white outline outline-1 -outline-offset-1 outline-gray-300 has-[input:focus-within]:outline has-[input:focus-within]:outline-2 has-[input:focus-within]:-outline-offset-2 has-[input:focus-within]:outline-gray-600'
                      )}
                    >
                      <div className='grid shrink-0 grid-cols-1 focus-within:relative'>
                        <Controller
                          name='country'
                          control={control}
                          defaultValue=''
                          render={({ field }) => (
                            <select
                              {...field}
                              id='country'
                              autoComplete='country'
                              aria-label='Country'
                              disabled={isUpdateBusinessProfileFormSubmitting}
                              className='col-start-1 row-start-1 w-full appearance-none rounded-lg py-1.5 pl-3 pr-7 text-base text-gray-500 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-gray-600 focus:shadow-none focus:ring-offset-0 focus:ring-0 sm:text-sm/6 border-none'
                            >
                              {COUNTRY_CODES.map((country) => (
                                <option
                                  value={country.country_code}
                                  key={country.country_code}
                                >
                                  {country.country_code} +{country.phone_code}
                                </option>
                              ))}
                            </select>
                          )}
                        />
                      </div>
                      <Controller
                        name='businessContactPhoneNumber'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={Boolean(error)}
                            helperText={Boolean(error) && error?.message}
                            variant='outlined'
                            // label='Corporate contact phone number *'
                            placeholder='Corporate contact phone number *'
                            sx={{
                              width: '100%',
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </InputGroup>
                <Controller
                  name='businessContactEmail'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Corporate contact email *'
                      placeholder='Corporate contact email *'
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                />
                {/* <Controller
                  name='businessContactName'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Contact name *'
                      placeholder='Contact name *'
                      sx={{
                        width: '100%',
                      }}
                    />
                  )}
                /> */}
                {hasUpdateBusinessProfileFormSubmitted ? (
                  <Button
                    type='submit'
                    icon={<MdCheck size='1.25rem' />}
                    disabled={isUpdateBusinessProfileFormSubmitting}
                    size='sm'
                    intent='success'
                    label={
                      isUpdateBusinessProfileFormSubmitting
                        ? 'Submitting...'
                        : 'Verified'
                    }
                  />
                ) : businessProfile && methods.formState.isDirty ? (
                  <Button
                    type='submit'
                    disabled={isUpdateBusinessProfileFormSubmitting}
                    size='sm'
                    intent='primary'
                    label={
                      isUpdateBusinessProfileFormSubmitting
                        ? 'Submitting...'
                        : 'Update'
                    }
                  />
                ) : businessProfile && isBusinessProfileComplete ? (
                  <Button
                    type='submit'
                    icon={<MdCheck size='1.25rem' />}
                    disabled={isUpdateBusinessProfileFormSubmitting}
                    size='sm'
                    intent='success'
                    label={
                      isUpdateBusinessProfileFormSubmitting
                        ? 'Submitting...'
                        : 'Verified'
                    }
                  />
                ) : (
                  <Button
                    type='submit'
                    icon={<MdAddCircle size='1.25rem' className='ml-1' />}
                    disabled={isUpdateBusinessProfileFormSubmitting}
                    size='sm'
                    intent='primary'
                    label={
                      isUpdateBusinessProfileFormSubmitting
                        ? 'Submitting...'
                        : 'Submit'
                    }
                  />
                )}
              </Stack>
            </form>
          </FormProvider>
        )}
        {user?.roles.includes('FinanceAdmin') && (
          <>
            <FinanceForm
              hasConnectedBankAccount={businessProfile?.hasConnectedBankAccount}
            />
            <div>
              Yapily Connect Ltd, Authorised Payment Institution, FCA Registered
              827001.
            </div>
          </>
        )}
      </Stack>
    </View>
  );
}
