import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { cva, type VariantProps } from 'class-variance-authority';
import { ReactElement } from 'react';

import { twMerge } from 'tailwind-merge';

const button = cva(
  [
    'justify-center',
    'inline-flex',
    'items-center',
    'rounded-xl',
    'text-center',
    'border',
    'border-floxBlue',
    'transition-colors',
    'delay-50',
  ],
  {
    variants: {
      intent: {
        primary: [
          'bg-primary',
          'text-white',
          'border-0',
          'border-radius-2',
          'hover:enabled:bg-blue-800',
        ],
        secondary: [
          'bg-transparent',
          'text-primary',
          'border-primary',
          'hover:enabled:bg-blue-50',
        ],
        success: [
          'bg-success',
          'text-white',
          'border-0',
          'border-radius-2',
          'hover:enabled:bg-green-800',
        ],
        warning: [
          'bg-warning',
          'text-white',
          'border-0',
          'border-radius-2',
          'hover:enabled:bg-yellow-700',
        ],
        danger: [
          'bg-danger',
          'text-white',
          'border-0',
          'border-radius-2',
          'hover:enabled:bg-red-700',
        ],
      },
      size: {
        sm: ['min-w-20', 'h-full', 'min-h-10', 'text-sm', 'py-1.5', 'px-4'],
        lg: ['min-w-32', 'h-full', 'min-h-12', 'text-lg', 'py-2.5', 'px-6'],
      },
      disabled: {
        true: [
          'bg-gray-300',
          'text-gray-500',
          'cursor-not-allowed',
          'border-gray-300',
        ],
        false: [],
      },
    },
    defaultVariants: {
      intent: 'primary',
      size: 'lg',
      disabled: false,
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof button> {
  label: string;
  icon?: ReactElement;
  disabled?: boolean;
}

export default function Button({
  label,
  icon,
  className,
  intent,
  size,
  disabled,
  ...props
}: ButtonProps) {
  return (
    <button
      type='button'
      className={twMerge(button({ intent, size, disabled }), className)}
      disabled={disabled}
      {...props}
    >
      {label}
      {icon && (
        <span aria-hidden='true' className='-mr-0.5 h-5 w-5'>
          {icon}
        </span>
      )}
    </button>
  );
}
