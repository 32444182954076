import { FLOX_LOGO_BLUE_SRC } from '@constants';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import '../../../tailwind.css';

const menuItems = [
  {
    label: 'Services',
    subMenu: [
      { label: 'Marketplace', href: '/services/marketplace' },
      {
        label: 'Buyers',
        tertiaryMenu: [
          { label: 'Warehousing', href: '/services/buyers/warehousing' },
          { label: 'Transportation', href: '/services/buyers/transportation' },
          { label: 'E2E visibility', href: '/services/buyers/e2e-visibility' },
          {
            label: 'Stock management',
            href: '/services/buyers/stock-management',
          },
        ],
      },
      {
        label: 'LSPs',
        tertiaryMenu: [
          { label: '3PLs', href: '/services/lsps/3pls' },
          {
            label: 'Warehousing providers',
            href: '/services/lsps/warehousing-providers',
          },
          {
            label: 'Hauliers / Transport',
            href: '/services/lsps/hauliers-transport',
          },
        ],
      },
    ],
  },
  { label: 'Pricing', href: '/pricing' },
  {
    label: 'Company',
    subMenu: [
      { label: 'About us', href: '/company/about-us' },
      { label: 'FAQs', href: '/company/faqs' },
      { label: 'Legals', href: '/company/legals' },
    ],
  },
  {
    label: 'Resources',
    subMenu: [
      { label: 'Blog', href: '/resources/blog' },
      { label: 'Help Centre', href: '/resources/help-centre' },
      { label: 'Maturity Assessment', href: '/resources/maturity-assessment' },
    ],
  },
];

export function HubspotMenu() {
  const [hoveredSubMenu, setHoveredSubMenu] = useState<any>({});
  return (
    <div className='flex items-center border justify-between px-4 py-4 bg-white border-gray-200 rounded shadow-lg font-inter'>
      <a href='/' className='flex shrink-0 items-center'>
        <img
          alt='FLOX'
          src={`https://stage.flox.is${FLOX_LOGO_BLUE_SRC}`}
          className='h-8 w-auto'
        />
      </a>
      <ul className='space-x-4 hidden lg:flex'>
        {menuItems.map((item) => (
          <Popover
            className='relative'
            key={item.label}
            onMouseLeave={() => setHoveredSubMenu({})}
          >
            {item.subMenu ? (
              <>
                <PopoverButton className='inline-flex items-center gap-x-1 text-md text-gray-900'>
                  <span>{item.label}</span>
                  <ChevronDownIcon aria-hidden='true' className='w-5 h-5' />
                </PopoverButton>

                <PopoverPanel
                  transition
                  className='absolute left-0 z-10 mt-8 w-fit bg-white border border-gray-200 rounded shadow-lg'
                >
                  <div className='flex p-4 text-sm text-gray-900 w-full'>
                    <div>
                      {item.subMenu?.map((subMenuItem) => (
                        <div className='relative' key={subMenuItem.label}>
                          <a
                            href={subMenuItem.href}
                            className='flex items-center justify-between p-2 hover:text-primary hover:bg-primary-100 rounded w-48'
                            onMouseEnter={() => setHoveredSubMenu(subMenuItem)}
                          >
                            {subMenuItem.label}
                            <ChevronRightIcon
                              aria-hidden='true'
                              className='w-5 h-5'
                            />
                          </a>
                        </div>
                      ))}
                    </div>
                    <div>
                      {hoveredSubMenu.tertiaryMenu?.map(
                        (tertiaryMenuItem: { label: string; href: string }) => (
                          <div
                            className='relative'
                            key={tertiaryMenuItem.label}
                          >
                            <a
                              href={tertiaryMenuItem.href}
                              className='flex items-center justify-between p-2 hover:text-primary hover:bg-primary-100 rounded w-48'
                            >
                              {tertiaryMenuItem.label}
                            </a>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </PopoverPanel>
              </>
            ) : (
              <a
                href={item.href}
                className='inline-flex items-center gap-x-1 text-md text-gray-900'
              >
                <span>{item.label}</span>
              </a>
            )}
          </Popover>
        ))}
      </ul>
      <div className='flex items-center space-x-4'>
        <Popover className='sm:hidden sm:relative'>
          <PopoverButton className='inline-flex items-center text-md text-gray-900 pt-2'>
            <img
              src='https://stage.flox.is/images/menu.svg'
              alt='Menu'
              className='w-8 h-8'
            />
          </PopoverButton>

          <PopoverPanel
            transition
            className='absolute left-0 top-0 z-10 w-screen h-screen bg-white border border-gray-200 rounded shadow-lg sm:left-full sm:transform sm:-translate-x-full sm:mt-8 sm:w-fit sm:h-auto sm:mt-20'
          >
            <div className='flex flex-col p-4 text-sm text-gray-900 w-full'>
              <div className='flex flex-col sm:flex-row'>
                {menuItems.map((item) => (
                  <div key={item.label} className='flex flex-col mr-8'>
                    <a
                      href={item.href}
                      className='text-lg text-gray-900 text-center border-b-2 border-gray-100 pb-2'
                    >
                      {item.label}
                    </a>
                    {item.subMenu && (
                      <div className='flex p-4 text-sm text-gray-900 w-full'>
                        <div>
                          {item.subMenu?.map((subMenuItem) => (
                            <div className='relative' key={subMenuItem.label}>
                              <a
                                href={subMenuItem.href}
                                className='flex items-center justify-between p-2 hover:text-primary hover:bg-primary-100 rounded w-48'
                                onMouseEnter={() =>
                                  setHoveredSubMenu(subMenuItem)
                                }
                              >
                                {subMenuItem.label}
                                <ChevronRightIcon
                                  aria-hidden='true'
                                  className='w-5 h-5'
                                />
                              </a>
                            </div>
                          ))}
                        </div>
                        {item.label === 'Services' && (
                          <div className='flex flex-col min-w-48 w-fit ml-2 sm:ml-4'>
                            {hoveredSubMenu.tertiaryMenu?.map(
                              (tertiaryMenuItem: {
                                label: string;
                                href: string;
                              }) => (
                                <div
                                  className='relative'
                                  key={tertiaryMenuItem.label}
                                >
                                  <a
                                    href={tertiaryMenuItem.href}
                                    className='flex items-center justify-between p-2 hover:text-primary hover:bg-primary-100 rounded w-48'
                                  >
                                    {tertiaryMenuItem.label}
                                  </a>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <a
                href='/list-services'
                className='text-sm font-semibold text-white bg-primary px-4 py-2 rounded ml-4 w-fit'
              >
                List Services
              </a>
            </div>
          </PopoverPanel>
        </Popover>
        <div className='flex items-center space-x-4'>
          <a
            href='/list-services'
            className='hidden sm:block text-sm font-semibold text-white bg-primary px-4 py-2 rounded'
          >
            List Services
          </a>
          <a
            href='https://app.flox.is/login'
            className='ml-4 text-sm font-semibold text-primary border border-primary px-4 py-2 rounded'
          >
            Login
          </a>
        </div>
      </div>
    </div>
  );
}
