import { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import { MAPBOX_APIKEY } from '@constants';

export function Mapbox({
  warehouse,
  interactive = true,
}: {
  warehouse: any | null;
  interactive?: boolean;
}) {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);

  useEffect(() => {
    if (!warehouse) return;
    if (!mapContainerRef.current) return;
    mapboxgl.accessToken = MAPBOX_APIKEY;

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [warehouse.location.lon, warehouse.location.lat],
      zoom: 12,
      interactive,
    });

    new mapboxgl.Marker({ color: 'red' })
      .setLngLat([warehouse.location.lon, warehouse.location.lat])
      .addTo(mapRef.current);
  }, [mapContainerRef.current]);

  return (
    <div
      style={{ height: '750px' }}
      ref={mapContainerRef}
      className='map-container'
    />
  );
}
