import { useState } from 'react';
import { Button } from '@components/Button';
import { Text } from '@components/Text';
import { MdAdd } from 'react-icons/md';
import {
  Control,
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormGetFieldState,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { INVENTORY_IMAGES_URL } from '@constants';
import { useReadLocalStorage } from 'usehooks-ts';
import { AddProductFormValues, ProductImage, UserImage } from '../types';
import { SKUEditor } from '../components/SKUEditor';
import {
  StyledPalletisingCardHeader,
  StyledPalletisingCardHeaderImageContainer,
  StyledPalletisingCardHeaderTitleContainer,
} from '../components/Palletising/Palletising.styles';
import { formatVolumeUnit } from '../utils';

interface StepTwoProps {
  watch: UseFormWatch<AddProductFormValues>;
  register: UseFormRegister<AddProductFormValues>;
  errors: FieldErrors<AddProductFormValues>;
  touchedFields: any;
  getValues: UseFormGetValues<AddProductFormValues>;
  setValue: UseFormSetValue<AddProductFormValues>;
  setFocus: UseFormSetFocus<AddProductFormValues>;
  getFieldState: UseFormGetFieldState<AddProductFormValues>;
  skusFields: FieldArrayWithId<AddProductFormValues, 'product.skus', 'id'>[];
  appendSku: UseFieldArrayAppend<AddProductFormValues, 'product.skus'>;
  removeSku: UseFieldArrayRemove;
  control: Control<AddProductFormValues>;
  isSmall: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export function StepTwo({
  watch,
  register,
  errors,
  touchedFields,
  getValues,
  setValue,
  setFocus,
  getFieldState,
  skusFields,
  appendSku,
  removeSku,
  control,
  isSmall,
  isTablet,
  isDesktop,
}: StepTwoProps) {
  const [selectedSkuIndex, setSelectedSkuIndex] = useState<number | null>(null);

  const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');

  const existingImages = getValues()
    .productImages.map((img: UserImage) => ({
      preview: URL.createObjectURL(img.image),
      title: img.title,
      id: img.id,
    }))
    .concat(
      getValues().product.images.map((img: ProductImage) => ({
        preview: `${INVENTORY_IMAGES_URL}/${img.imageLocation}/small/${img.remoteName}`,
        title: img.title,
        id: img.id,
      }))
    );

  return (
    <>
      <StyledPalletisingCardHeader css={{ mb: '$space16' }}>
        <StyledPalletisingCardHeaderImageContainer
          css={{
            backgroundImage: `url(${
              existingImages.length > 0 && existingImages[0]?.preview
                ? existingImages[0].preview
                : `/images/product-placeholder-${
                    darkMode ? 'dark' : 'light'
                  }.svg`
            })`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            aspectRatio: 1,
          }}
        />
        <StyledPalletisingCardHeaderTitleContainer>
          <Text size='sm' weight='bold'>
            Product Name:{' '}
            <Text as='span' size='sm' weight='regular'>
              {getValues().product.name}
            </Text>
          </Text>
          <Text size='sm' weight='bold'>
            Product ID:{' '}
            <Text as='span' size='sm' weight='regular'>
              {getValues().product.productId}
            </Text>
          </Text>
          {getValues().product.netVolume &&
            !Number.isNaN(getValues().product.netVolume) && (
              <Text size='sm' weight='bold'>
                Volume:{' '}
                <Text as='span' size='sm' weight='regular'>
                  {getValues().product.netVolume}
                  {formatVolumeUnit(getValues().product.netVolumeUnitOfMeasure)}
                </Text>
              </Text>
            )}
        </StyledPalletisingCardHeaderTitleContainer>
      </StyledPalletisingCardHeader>
      {skusFields.map((item, index) => {
        return (
          <SKUEditor
            key={item.id}
            sku={item}
            register={register}
            control={control}
            watch={watch}
            getValues={getValues}
            setValue={setValue}
            setFocus={setFocus}
            getFieldState={getFieldState}
            errors={errors}
            touchedFields={touchedFields}
            isSmall={isSmall}
            isTablet={isTablet}
            isDesktop={isDesktop}
            edit={skusFields.length === 1 || selectedSkuIndex === index}
            index={index}
            remove={() => removeSku(index)}
            setSelectedSkuIndex={setSelectedSkuIndex}
          />
        );
      })}
      <Button
        size='md'
        action='cta'
        leftIcon={<MdAdd size='1.5rem' />}
        isFullWidth
        onClick={() => {
          appendSku({
            skuId: '',
            barcode: '',
            name: '',
            description: '',
            amountOfProductInSku: null,
            productImageId: '',
            images: [],
            barcodeImages: [],
            dimensions: {
              width: null,
              height: null,
              length: null,
              dimensionsUnitOfMeasure: 'cm',
            },
            grossWeight: null,
            grossWeightUnitOfMeasure: 'Kg',
            listPrices: [],
            purchasePrices: [],
            palletising: [],
            unitOfMeasure: '',
          });
          setSelectedSkuIndex(skusFields.length);
        }}
      >
        Add SKU
      </Button>
    </>
  );
}

StepTwo.displayName = 'StepTwo';
