import { View } from '@views/View';
import moment from 'moment';
import { useMediaQuery } from 'usehooks-ts';
import { Button } from '@components/Button';
import {
  MdAdd,
  MdCheck,
  MdDelete,
  MdDownload,
  MdUploadFile,
} from 'react-icons/md';
import { Stack, Typography } from '@mui/material';
import { Dropzone } from '@components/Dropzone';
import { useState } from 'react';
import { Icon } from '@components/Icon';
import { useAuthHttp } from '../../hooks';
import { ADJUST_STOCK_BY_FILE, GET_SKU_STOCK } from '../../constants';

export function StockAdjustmentForm({
  warehouseMatchId,
  warehouseName,
  hasAdjustedStock,
}: {
  warehouseMatchId: string;
  warehouseName: string;
  hasAdjustedStock: boolean;
}) {
  const authHttp = useAuthHttp();
  const isSmall = useMediaQuery('(min-width: 36em)');
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const authHttpFormData = useAuthHttp('multipart/form-data');

  const [uploadFile, setUploadFile] = useState<any>(undefined);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [hasUploadError, setHasUploadError] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [isStockAdjusted, setIsStockAdjusted] = useState(hasAdjustedStock);

  const handleDownloadStockData = async () => {
    try {
      const { data } = await authHttp.get(GET_SKU_STOCK);
      const blob = new Blob([data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      const currentDate = moment();
      link.download = `StockData-${currentDate.format('yyyy-MM-DD')}.csv`;
      link.click();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  const handleUploadSkuStockData = async () => {
    try {
      setIsUploadLoading(true);
      const { data } = await authHttpFormData.post(
        ADJUST_STOCK_BY_FILE,
        {
          warehouseMatchId,
          stockLevelFile: uploadFile,
        },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      if (data) {
        setUploadFile(undefined);
        setHasUploadError(false);

        setIsStockAdjusted(true);
      }
    } catch (error: any) {
      const errorResponse = error as any;
      if (
        errorResponse.response.data.errors?.length > 0 &&
        !!errorResponse.response.data.errors[0].errorMessage &&
        errorResponse.response.data.errors[0].errorMessage.includes(
          `Stock adjustment for warehouse match ${warehouseMatchId} has already been made.`
        )
      ) {
        setUploadError(`Stock adjustment has already been made.`);
        setHasUploadError(true);
      }
    } finally {
      setIsUploadLoading(false);
    }
  };

  return isStockAdjusted ? (
    <View>
      <div className='flex flex-col items-center justify-center px-0'>
        <MdCheck size='8rem' aria-hidden='true' color='green' />
        <Typography variant='h4'>Stock adjusted successfully</Typography>
      </div>
    </View>
  ) : (
    <View>
      <div className='flex flex-row items-center justify-center px-0'>
        <Stack gap={12} className='pr-8'>
          <Stack gap={12} alignItems='left'>
            <p>
              Upload a file below to perform a one-time stock adjustment for{' '}
              {warehouseName}.
            </p>
            <p>
              Warning: This can only be done <b>once</b> per warehouse.
            </p>
            <p>
              You can access the sku template by clicking the download button
              below.
            </p>
          </Stack>
          <Button
            variant='primary'
            action='cta'
            size={isDesktop ? 'md' : 'sm'}
            leftIcon={<MdDownload size='1.5rem' />}
            isFullWidth={false}
            onClick={handleDownloadStockData}
            style={{ maxWidth: '50%' }}
          >
            Download excel template
          </Button>
        </Stack>
        <div style={{ maxWidth: '12rem' }}>
          {uploadFile ? (
            <Stack gap={12} width='100%'>
              <Stack direction='row' gap={6} alignItems='center'>
                <MdUploadFile size='1rem' />
                <Typography
                  variant='body2'
                  sx={{ fontWeight: 600, fontSize: '1rem' }}
                >
                  {uploadFile.name}
                </Typography>
              </Stack>
              <Button
                variant='primary'
                action='cta'
                size={isDesktop ? 'md' : 'sm'}
                leftIcon={<MdUploadFile size='1.5rem' />}
                onClick={handleUploadSkuStockData}
                isLoading={isUploadLoading}
                loadingIcon={<Icon name='loading' />}
                loadingText='Uploading...'
              >
                Upload Stock Data
              </Button>
              <Button
                variant='primary'
                action='danger'
                leftIcon={<MdDelete size='1rem' />}
                size={isDesktop ? 'md' : 'sm'}
                onClick={() => {
                  setHasUploadError(false);
                  setUploadFile(undefined);
                }}
              >
                Clear selected file
              </Button>
              {hasUploadError && (
                <Stack gap={2}>
                  <Typography
                    variant='body2'
                    sx={{ color: 'red', fontWeight: 600 }}
                  >
                    Error uploading file.{' '}
                    {uploadError
                      ? 'Please fix the following errors and try again.'
                      : 'Please try again.'}
                  </Typography>
                  <Typography
                    key={uploadError}
                    variant='body2'
                    sx={{ color: 'red' }}
                  >
                    {uploadError}
                  </Typography>
                </Stack>
              )}
            </Stack>
          ) : (
            <Dropzone
              id='product-master-dropzone'
              acceptAnyFile
              primaryAction={
                <Button
                  // eslint-disable-next-line no-nested-ternary
                  size={isDesktop ? 'xs' : isSmall ? 'sm' : 'xs'}
                  leftIcon={<MdAdd size={isSmall ? '1.25rem' : '1rem'} />}
                >
                  Upload Stock Data - {warehouseName}
                </Button>
              }
              onDrop={(acceptedFiles: any) => {
                setUploadFile(acceptedFiles[0]);
              }}
            />
          )}
        </div>
      </div>
    </View>
  );
}
