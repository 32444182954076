import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading } from '@components/Heading';
import {
  Board,
  BoardLane,
  BoardLaneHeader,
  BoardLaneTitleContainer,
  BoardLaneTitleButton,
  BoardLaneItemCount,
} from '@components/Board';
import { View } from '@views/View';
import InfiniteScroll from 'react-infinite-scroller';
import {
  InfiniteScrollEndMessage,
  InfiniteScrollLoading,
  InfiniteScrollErrorMessage,
} from '@components/InfiniteScroll';
import {
  InboundSupplyChainProcessEventList,
  OutboundSupplyChainProcessEventList,
  SupplyChainProcessEventCard,
} from '@components/SupplyChainProcessEventList';
import { useDocumentTitle, useMediaQuery } from 'usehooks-ts';
import { CreateDraftGoodsReceivedNoteForm } from '@forms/manager/CreateDraftGoodsReceivedNoteForm';
import { SignOffGoodsReceivedNoteForm } from '@forms/manager/SignOffGoodsReceivedNoteForm';
import { ConfirmPickingNoteForm } from '@forms/manager/ConfirmPickingNoteForm';
import { CreateOutboundShipmentNoteForm } from '@forms/manager/CreateOutboundShipmentNoteForm';
import {
  useInboundProcess,
  useGoodsReceivedNote,
  useOutboundProcess,
  usePickingNote,
  useOutboundShipmentNote,
  useProofOfDelivery,
  useAuthoriseForPicking,
  useAuthHttp,
} from '@hooks';
import { useAtom, useSetAtom } from 'jotai';
import { CreateProofOfDeliveryForm } from '@forms/manager/CreateProofOfDeliveryForm';
import { DialogModal } from '@components/DialogModal';
import { AuthoriseForPickingForm } from '@forms/manager/AuthoriseForPickingForm';
import { WarehouseClientsSelector } from '@components/WarehouseClientSelector';
import { WarehouseSelector } from '@components/WarehouseSelector';
import Stack from '@mui/material/Stack';
import { useAuth } from '@context';
import { InvoiceEventList } from '@components/InvoiceEventList/InvoiceEventList';
import { InvoicingEventCard } from '@components/InvoiceEventList/InvoiceEventCard';
import { InboundProcess, OutboundProcess } from '@features/supplyChain/types';
import {
  addInboundProcesses,
  addOutboundProcesses,
} from '@features/supplyChain';
import { ShipmentNoteDetails } from './ShipmentNoteDetails';
import { WarehouseManagerInboundSideDrawer } from './WarehouseManagerInboundSideDrawer';
import { WarehouseManagerOutboundSideDrawer } from './WarehouseManagerOutboundSideDrawer';
import { DespatchNoteDetails } from './DespatchNoteDetails';
import { GoodsReceivedNoteDetails } from './GoodsReceivedNoteDetails';
import { PickingConfirmationDetails } from './PickingConfirmationDetails';
import { OutboundShipmentNoteDetails } from './OutboundShipmentNoteDetails';
import { ProofOfDeliveryDetails } from './ProofOfDeliveryDetails';
import {
  isInboundSidebarOpenAtom,
  isOutboundSidebarOpenAtom,
  selectedInboundProcessIdAtom,
  selectedOutboundProcessIdAtom,
} from '../../../store/jotai';
import { useInvoices } from '../../../hooks/useInvoices';
import { Swimlane } from './Swimlane';
import {
  INBOUND_SHIPMENT_TYPE,
  LIST_SUPPLY_CHAIN_PROCESSES_URL,
  OUTBOUND_SHIPMENT_TYPE,
} from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../store';
import { FilterService } from '../../../services';

export function WarehouseManagerNoticeboard() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const authHttp = useAuthHttp();
  const dispatch = useAppDispatch();
  const { getFilterValue } = FilterService();

  const isDesktop = useMediaQuery('(min-width: 62em)');
  const isTablet = useMediaQuery('(min-width: 48em)');

  const noticeboardWarehouseId = getFilterValue('noticeboardWarehouseId');
  const noticeboardWarehouseClientId = getFilterValue(
    'noticeboardWarehouseClientId'
  );

  const [isInboundSidebarOpen, setIsInboundSidebarOpen] = useAtom(
    isInboundSidebarOpenAtom
  );
  const [isOutboundSidebarOpen, setIsOutboundSidebarOpen] = useAtom(
    isOutboundSidebarOpenAtom
  );
  const setSelectedInboundProcessId = useSetAtom(selectedInboundProcessIdAtom);
  const setSelectedOutboundProcessId = useSetAtom(
    selectedOutboundProcessIdAtom
  );

  const [includeArchived, setIncludeArchived] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);

  const supplyChainProcessId = searchParams.get('supplyChainProcessId');
  const processChildType = searchParams.get('processChildType');

  useDocumentTitle('FLOX - Noticeboard');

  const [isInitialInboundProcessesFetch, setIsInitialInboundProcessesFetch] =
    useState(import.meta.env.MODE === 'development');
  const [refreshInboundOnNextFetch, setRefreshInboundOnNextFetch] =
    useState(false);
  const [isLoadingInboundProcesses, setIsLoadingInboundProcesses] =
    useState(false);
  const [isLoadingInboundProcessesError, setIsLoadingInboundProcessesError] =
    useState(false);
  const lastInboundItem = useAppSelector(
    (state) =>
      state.supplyChain.supplyChain.inboundCurrentQuery.pagination?.lastItem
  );

  const [isInitialOutboundProcessesFetch, setIsInitialOutboundProcessesFetch] =
    useState(import.meta.env.MODE === 'development');
  const [refreshOutboundOnNextFetch, setRefreshOutboundOnNextFetch] =
    useState(false);
  const [isLoadingOutboundProcesses, setIsLoadingOutboundProcesses] =
    useState(false);
  const [isLoadingOutboundProcessesError, setIsLoadingOutboundProcessesError] =
    useState(false);
  const lastOutboundItem = useAppSelector(
    (state) =>
      state.supplyChain.supplyChain.outboundCurrentQuery.pagination?.lastItem
  );

  const {
    inboundProcesses,
    hasMoreInboundData,
    selectedInboundProcess,
    setSelectedInboundProcess,
    selectedInboundProcessHasDescription,
    handleScrollToTopOfInbound,
    isViewShipmentNoteDialogOpen,
    setIsViewShipmentNoteDialogOpen,
    handleCloseViewShipmentNote,
    selectedProcessChildType,
    setSelectedProcessChildType,
  } = useInboundProcess();

  const {
    isCreateDraftGoodsReceivedNoteFormOpen,
    setIsCreateDraftGoodsReceivedNoteFormOpen,
    handleCloseCreateDraftGoodsReceivedNoteForm,
    handleSubmitCreateDraftGoodsReceivedNote,
    isSignOffGoodsReceivedNoteFormOpen,
    setIsSignOffGoodsReceivedNoteFormOpen,
    handleCloseSignOffGoodsReceivedNoteForm,
    handleSubmitSignOffGoodsReceivedNote,
  } = useGoodsReceivedNote();

  const {
    outboundProcesses,
    hasMoreOutboundData,
    selectedOutboundProcess,
    setSelectedOutboundProcess,
    selectedOutboundProcessHasDescription,
    handleScrollToTopOfOutbound,
    isViewDespatchNoteDialogOpen,
    setIsViewDespatchNoteDialogOpen,
    handleCloseViewDespatchNote,
    isViewGoodsReceivedNoteDialogOpen,
    setIsViewGoodsReceivedNoteDialogOpen,
    handleCloseViewGoodsReceivedNote,
    isViewPickingConfirmationDialogOpen,
    setIsViewPickingConfirmationDialogOpen,
    handleCloseViewPickingConfirmation,
    isViewOutboundShipmentNoteDialogOpen,
    setIsViewOutboundShipmentNoteDialogOpen,
    handleCloseViewOutboundShipmentNote,
    isViewProofOfDeliveryDialogOpen,
    setIsViewProofOfDeliveryDialogOpen,
    handleCloseViewProofOfDelivery,
  } = useOutboundProcess();

  const {
    fetchInvoices,
    fetchPaidInvoices,
    billingCycles,
    paidBillingCycles,
    hasMoreInvoiceData,
    hasMorePaidInvoiceData,
    isLoadingInvoicesError,
    isLoadingPaidInvoicesError,
    getInvoiceScrollParent,
    getPaymentsScrollParent,
  } = useInvoices();

  const {
    isAuthoriseForPickingFormOpen,
    setIsAuthoriseForPickingFormOpen,
    handleCloseAuthoriseForPickingForm,
    handleSubmitAuthoriseForPickingForm,
  } = useAuthoriseForPicking();

  const {
    isConfirmPickingNoteFormOpen,
    setIsConfirmPickingNoteFormOpen,
    handleCloseConfirmPickingNoteForm,
    handleSubmitConfirmPickingNote,
  } = usePickingNote();

  const {
    isCreateOutboundShipmentNoteFormOpen,
    setIsCreateOutboundShipmentNoteFormOpen,
    handleCloseCreateOutboundShipmentNoteForm,
    handleSubmitOutboundShipmentNote,
  } = useOutboundShipmentNote();

  const {
    isCreateProofOfDeliveryFormOpen,
    setIsCreateProofOfDeliveryFormOpen,
    handleCloseCreateProofOfDeliveryForm,
    handleSubmitCreateProofOfDelivery,
  } = useProofOfDelivery();

  const clearProcesses = () => {
    setSelectedInboundProcess(null);
    setSelectedOutboundProcess(null);
    setSelectedInboundProcessId('');
    setSelectedOutboundProcessId('');
    setIsInboundSidebarOpen(false);
    setIsOutboundSidebarOpen(false);
  };

  useEffect(() => {
    clearProcesses();
    if (!inboundProcesses && !outboundProcesses) return;
    if (supplyChainProcessId) {
      const inboundProcess = inboundProcesses.find(
        (process) => process.id === supplyChainProcessId
      );
      const outboundProcess = outboundProcesses.find(
        (process) => process.id === supplyChainProcessId
      );

      if (inboundProcess) {
        setSelectedInboundProcess(inboundProcess);
        setSelectedInboundProcessId(inboundProcess.id);
        if (processChildType) {
          if (processChildType === 'PickingNoteConfirmed') {
            setIsViewPickingConfirmationDialogOpen(true);
          } else if (processChildType === 'GoodsReceivedNoteFinalised') {
            setIsViewGoodsReceivedNoteDialogOpen(true);
          }
        } else {
          setIsInboundSidebarOpen(true);
          setIsViewShipmentNoteDialogOpen(true);
        }
      } else if (outboundProcess) {
        setSelectedOutboundProcess(outboundProcess);
        setSelectedOutboundProcessId(outboundProcess.id);
        if (processChildType) {
          if (processChildType === 'PickingNoteConfirmed') {
            setIsViewPickingConfirmationDialogOpen(true);
          } else if (processChildType === 'GoodsReceivedNoteFinalised') {
            setIsViewGoodsReceivedNoteDialogOpen(true);
          }
        } else {
          setIsOutboundSidebarOpen(true);
          setIsViewDespatchNoteDialogOpen(true);
        }
      }
    }
  }, [
    supplyChainProcessId,
    processChildType,
    inboundProcesses,
    outboundProcesses,
  ]);

  const cardForInboundProcess = (inboundProcess: InboundProcess) => (
    <SupplyChainProcessEventCard
      key={inboundProcess.id}
      inboundProcess={inboundProcess}
      onClick={() => {
        setSelectedInboundProcess(inboundProcess);
        setSelectedInboundProcessId(inboundProcess.id);
        setIsInboundSidebarOpen(true);
        if (isOutboundSidebarOpen) {
          setIsOutboundSidebarOpen(false);
        }
      }}
    />
  );

  const cardForOutboundProcess = (outboundProcess: OutboundProcess) => (
    <SupplyChainProcessEventCard
      key={outboundProcess.id}
      outboundProcess={outboundProcess}
      onClick={() => {
        setSelectedOutboundProcess(outboundProcess);
        setSelectedOutboundProcessId(outboundProcess.id);
        setIsOutboundSidebarOpen(true);
        if (isInboundSidebarOpen) {
          setIsInboundSidebarOpen(false);
        }
      }}
    />
  );

  const fetchInboundSupplyChainProcesses = async () => {
    if (
      isInitialInboundProcessesFetch ||
      isLoadingInboundProcesses ||
      isLoadingInboundProcessesError
    ) {
      setIsInitialInboundProcessesFetch(false);
      return;
    }

    setIsLoadingInboundProcesses(true);

    try {
      const { data } = await authHttp.post(LIST_SUPPLY_CHAIN_PROCESSES_URL, {
        searchQuery: '',
        warehouseId:
          noticeboardWarehouseId === 'All' ? null : noticeboardWarehouseId,
        shipperBusinessId:
          noticeboardWarehouseClientId === 'All'
            ? null
            : noticeboardWarehouseClientId,
        type: INBOUND_SHIPMENT_TYPE,
        pagination: {
          lastItem: refreshInboundOnNextFetch ? null : lastInboundItem,
          itemsPerPage: 25,
        },
        archivedByLspStatus: includeArchived ? null : 'NotArchived',
      });

      data.hasMoreInboundData = !(data.supplyChainProcesses.length < 25);
      data.isRefresh = refreshInboundOnNextFetch;
      setRefreshInboundOnNextFetch(false);
      dispatch(addInboundProcesses(data));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error: ', error);
      setIsLoadingInboundProcessesError(true);
    } finally {
      setIsLoadingInboundProcesses(false);
    }
  };

  const fetchOutboundSupplyChainProcesses = async () => {
    if (
      isInitialOutboundProcessesFetch ||
      isLoadingOutboundProcesses ||
      isLoadingOutboundProcessesError
    ) {
      setIsInitialOutboundProcessesFetch(false);
      return;
    }

    setIsLoadingOutboundProcesses(true);

    try {
      const { data } = await authHttp.post(LIST_SUPPLY_CHAIN_PROCESSES_URL, {
        searchQuery: '',
        warehouseId:
          noticeboardWarehouseId === 'All' ? null : noticeboardWarehouseId,
        shipperBusinessId:
          noticeboardWarehouseClientId === 'All'
            ? null
            : noticeboardWarehouseClientId,
        type: OUTBOUND_SHIPMENT_TYPE,
        pagination: {
          lastItem: refreshOutboundOnNextFetch ? null : lastOutboundItem,
          itemsPerPage: 25,
        },
        archivedByLspStatus: includeArchived ? null : 'NotArchived',
      });

      data.hasMoreOutboundData = !(data.supplyChainProcesses.length < 25);
      data.isRefresh = refreshOutboundOnNextFetch;
      dispatch(addOutboundProcesses(data));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error: ', error);
      setIsLoadingOutboundProcessesError(true);
    } finally {
      setIsLoadingOutboundProcesses(false);
    }
  };

  useEffect(() => {
    setRefreshInboundOnNextFetch(true);
    setRefreshOutboundOnNextFetch(true);
    fetchInboundSupplyChainProcesses();
    fetchOutboundSupplyChainProcesses();
  }, [includeArchived]);

  return (
    <View>
      <Stack
        direction={isTablet ? 'row' : 'column'}
        gap={16}
        alignItems={isTablet ? 'center' : 'flex-start'}
      >
        <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
          Noticeboard
        </Heading>
        <WarehouseSelector />
        <WarehouseClientsSelector />
        <input
          type='checkbox'
          id='include-archived'
          name='include-archived'
          onClick={(e) => {
            setIncludeArchived(e.currentTarget.checked);
            setRefreshInboundOnNextFetch(true);
            setRefreshOutboundOnNextFetch(true);
          }}
        />
        <div>Include archived</div>
      </Stack>
      <Board>
        {/* Inbound */}
        <Swimlane<InboundProcess>
          title='Inbound shipments'
          name='inbound'
          items={inboundProcesses}
          hasMoreData={hasMoreInboundData}
          isLoadingError={isLoadingInboundProcessesError}
          loadMore={fetchInboundSupplyChainProcesses}
          swimlaneItem={cardForInboundProcess}
          handleScrollToTop={handleScrollToTopOfInbound}
          eventListElement={
            InboundSupplyChainProcessEventList as unknown as ReactNode
          }
        />
        {/* Outbound */}
        <Swimlane<OutboundProcess>
          title='Outbound shipments'
          name='outbound'
          items={outboundProcesses}
          hasMoreData={hasMoreOutboundData}
          isLoadingError={isLoadingOutboundProcessesError}
          loadMore={fetchOutboundSupplyChainProcesses}
          swimlaneItem={cardForOutboundProcess}
          handleScrollToTop={handleScrollToTopOfOutbound}
          eventListElement={
            OutboundSupplyChainProcessEventList as unknown as ReactNode
          }
        />
        {user?.roles.includes('FinanceAdmin') && (
          <>
            <BoardLane>
              <BoardLaneHeader>
                <BoardLaneTitleContainer sx={{ my: '0.3359rem' }}>
                  <BoardLaneTitleButton>Invoices</BoardLaneTitleButton>
                  <BoardLaneItemCount itemCount={billingCycles.length} />
                </BoardLaneTitleContainer>
              </BoardLaneHeader>
              <InfiniteScroll
                element={InvoiceEventList as unknown as ReactNode}
                initialLoad
                loadMore={fetchInvoices}
                hasMore={
                  hasMoreInvoiceData === null ||
                  (hasMoreInvoiceData === true && !isLoadingInvoicesError)
                }
                useWindow={false}
                getScrollParent={getInvoiceScrollParent}
                loader={
                  <InfiniteScrollLoading
                    key='invoice-loader'
                    loadingMessage='Loading invoice items...'
                  />
                }
              >
                {billingCycles.length > 0 &&
                  billingCycles.map((billingCycle) => (
                    <InvoicingEventCard
                      key={billingCycle.id}
                      billingCycle={billingCycle}
                      invoice={billingCycle.lspToFloxInvoice}
                      onClick={() => navigate(`/invoice/${billingCycle.id}`)}
                    />
                  ))}
                {hasMoreInvoiceData === false && (
                  <InfiniteScrollEndMessage
                    isVisible={billingCycles.length > 25}
                    direction='column'
                    endMessageText='End of invoices'
                    handleScrollToTop={() =>
                      getInvoiceScrollParent().scrollTo({
                        top: 0,
                        behavior: 'smooth',
                      })
                    }
                  />
                )}
                {isLoadingInvoicesError && (
                  <InfiniteScrollErrorMessage errorMessage='Sorry, there was an error fetching your invoices.' />
                )}
              </InfiniteScroll>
            </BoardLane>
            <BoardLane>
              <BoardLaneHeader>
                <BoardLaneTitleContainer sx={{ my: '0.3359rem' }}>
                  <BoardLaneTitleButton>Payments</BoardLaneTitleButton>
                  <BoardLaneItemCount itemCount={paidBillingCycles.length} />
                </BoardLaneTitleContainer>
              </BoardLaneHeader>
              <InfiniteScroll
                element={InvoiceEventList as unknown as ReactNode}
                initialLoad
                loadMore={fetchPaidInvoices}
                hasMore={
                  hasMorePaidInvoiceData === null ||
                  (hasMorePaidInvoiceData === true &&
                    !isLoadingPaidInvoicesError)
                }
                useWindow={false}
                getScrollParent={getPaymentsScrollParent}
                loader={
                  <InfiniteScrollLoading
                    key='payments-loader'
                    loadingMessage='Loading payments...'
                  />
                }
              >
                {paidBillingCycles.length > 0 &&
                  paidBillingCycles.map((billingCycle) => (
                    <InvoicingEventCard
                      key={billingCycle.id}
                      billingCycle={billingCycle}
                      invoice={billingCycle.lspToFloxInvoice}
                      payment
                      onClick={() => navigate(`/invoice/${billingCycle.id}`)}
                    />
                  ))}
                {hasMorePaidInvoiceData === false && (
                  <InfiniteScrollEndMessage
                    isVisible={paidBillingCycles.length > 25}
                    direction='column'
                    endMessageText='End of payments'
                    handleScrollToTop={() =>
                      getPaymentsScrollParent().scrollTo({
                        top: 0,
                        behavior: 'smooth',
                      })
                    }
                  />
                )}
                {isLoadingPaidInvoicesError && (
                  <InfiniteScrollErrorMessage errorMessage='Sorry, there was an error fetching your payments.' />
                )}
              </InfiniteScroll>
            </BoardLane>
          </>
        )}
        {/* Temporary empty board lanes until we define them */}
        <div style={{ width: '100%', minWidth: '13rem' }} />
        <div style={{ width: '100%', minWidth: '13rem' }} />
      </Board>
      {/* Sidebar for selected inbound process */}
      {selectedInboundProcess && (
        <WarehouseManagerInboundSideDrawer
          selectedInboundProcess={selectedInboundProcess}
          setSelectedInboundProcess={setSelectedInboundProcess}
          selectedInboundProcessHasDescription={
            selectedInboundProcessHasDescription
          }
          isOpen={isInboundSidebarOpen}
          setIsOpen={setIsInboundSidebarOpen}
          isDesktop={isDesktop}
          setIsViewShipmentNoteDialogOpen={setIsViewShipmentNoteDialogOpen}
          setIsSignOffGoodsReceivedNoteFormOpen={
            setIsSignOffGoodsReceivedNoteFormOpen
          }
          setIsCreateDraftGoodsReceivedNoteFormOpen={
            setIsCreateDraftGoodsReceivedNoteFormOpen
          }
          setIsViewGoodsReceivedNoteDialogOpen={
            setIsViewGoodsReceivedNoteDialogOpen
          }
          selectedProcessChildType={selectedProcessChildType}
          setSelectedProcessChildType={setSelectedProcessChildType}
        />
      )}
      {/* Sidebar for outbound process */}
      {selectedOutboundProcess && (
        <WarehouseManagerOutboundSideDrawer
          selectedOutboundProcess={selectedOutboundProcess}
          setSelectedOutboundProcess={setSelectedOutboundProcess}
          selectedOutboundProcessHasDescription={
            selectedOutboundProcessHasDescription
          }
          isOpen={isOutboundSidebarOpen}
          setIsOpen={setIsOutboundSidebarOpen}
          isDesktop={isDesktop}
          setIsViewDespatchNoteDialogOpen={setIsViewDespatchNoteDialogOpen}
          setIsAuthoriseForPickingFormOpen={setIsAuthoriseForPickingFormOpen}
          setIsConfirmPickingNoteFormOpen={setIsConfirmPickingNoteFormOpen}
          setIsViewPickingConfirmationDialogOpen={
            setIsViewPickingConfirmationDialogOpen
          }
          setIsCreateOutboundShipmentNoteFormOpen={
            setIsCreateOutboundShipmentNoteFormOpen
          }
          setIsViewOutboundShipmentNoteDialogOpen={
            setIsViewOutboundShipmentNoteDialogOpen
          }
          setIsCreateProofOfDeliveryFormOpen={
            setIsCreateProofOfDeliveryFormOpen
          }
          setIsViewProofOfDeliveryDialogOpen={
            setIsViewProofOfDeliveryDialogOpen
          }
          selectedProcessChildType={selectedProcessChildType}
          setSelectedProcessChildType={setSelectedProcessChildType}
        />
      )}
      {/* View shipment note */}
      <DialogModal
        open={isViewShipmentNoteDialogOpen}
        setOpen={setIsViewShipmentNoteDialogOpen}
        onClose={handleCloseViewShipmentNote}
        title='Shipment Note'
        id='view-shipment-note-form-title'
      >
        <ShipmentNoteDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedInboundProcess={selectedInboundProcess!}
          onClose={() => setIsViewShipmentNoteDialogOpen(false)}
          primaryAction={() => {
            setIsViewShipmentNoteDialogOpen(false);
            setIsCreateDraftGoodsReceivedNoteFormOpen(true);
          }}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* Create draft goods received note form */}
      <DialogModal
        open={isCreateDraftGoodsReceivedNoteFormOpen}
        setOpen={setIsCreateDraftGoodsReceivedNoteFormOpen}
        onClose={handleCloseCreateDraftGoodsReceivedNoteForm}
        title='Create Draft Goods Received Note'
        id='create-draft-goods-received-note-form-title'
      >
        <CreateDraftGoodsReceivedNoteForm
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedInboundProcess={selectedInboundProcess!}
          onClose={() => {
            setIsCreateDraftGoodsReceivedNoteFormOpen(false);
          }}
          onSubmit={handleSubmitCreateDraftGoodsReceivedNote}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* Sign off goods received note form */}
      <DialogModal
        open={isSignOffGoodsReceivedNoteFormOpen}
        setOpen={setIsSignOffGoodsReceivedNoteFormOpen}
        onClose={handleCloseSignOffGoodsReceivedNoteForm}
        title='Draft Goods Received Note'
        id='sign-off-goods-received-note-form-title'
      >
        <SignOffGoodsReceivedNoteForm
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedInboundProcess={selectedInboundProcess!}
          onClose={() => {
            setIsSignOffGoodsReceivedNoteFormOpen(false);
          }}
          onSubmit={handleSubmitSignOffGoodsReceivedNote}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* View despatch note details */}
      <DialogModal
        open={isViewDespatchNoteDialogOpen}
        setOpen={setIsViewDespatchNoteDialogOpen}
        onClose={handleCloseViewDespatchNote}
        title='Despatch Note'
        id='view-despatch-note-form-title'
      >
        <DespatchNoteDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => setIsViewDespatchNoteDialogOpen(false)}
          primaryAction={() => {
            setIsViewDespatchNoteDialogOpen(false);
            if (
              !selectedOutboundProcess?.despatchNote?.isAuthorisedForPicking
            ) {
              setIsAuthoriseForPickingFormOpen(true);
            } else if (
              selectedOutboundProcess?.despatchNote?.isAuthorisedForPicking &&
              !selectedOutboundProcess?.pickingConfirmation
            ) {
              setIsConfirmPickingNoteFormOpen(true);
            }
          }}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* View goods received note details */}
      <DialogModal
        open={isViewGoodsReceivedNoteDialogOpen}
        setOpen={setIsViewGoodsReceivedNoteDialogOpen}
        onClose={handleCloseViewGoodsReceivedNote}
        title='Goods Received Note'
        id='view-goods-received-note-form-title'
      >
        <GoodsReceivedNoteDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedInboundProcess={selectedInboundProcess!}
          onClose={() => setIsViewGoodsReceivedNoteDialogOpen(false)}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* Authorise for picking form */}
      <DialogModal
        open={isAuthoriseForPickingFormOpen}
        setOpen={setIsAuthoriseForPickingFormOpen}
        onClose={handleCloseAuthoriseForPickingForm}
        title='Authorise For Picking'
        id='authorise-for-picking-form-title'
      >
        <AuthoriseForPickingForm
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => setIsAuthoriseForPickingFormOpen(false)}
          onSubmit={handleSubmitAuthoriseForPickingForm}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* Confirm picking note form */}
      <DialogModal
        open={isConfirmPickingNoteFormOpen}
        setOpen={setIsConfirmPickingNoteFormOpen}
        onClose={handleCloseConfirmPickingNoteForm}
        title='Confirm Picking'
        id='confirm-picking-note-form-title'
      >
        <ConfirmPickingNoteForm
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => {
            setIsConfirmPickingNoteFormOpen(false);
          }}
          onSubmit={handleSubmitConfirmPickingNote}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* View picking confirmation */}
      <DialogModal
        open={isViewPickingConfirmationDialogOpen}
        setOpen={setIsViewPickingConfirmationDialogOpen}
        onClose={handleCloseViewPickingConfirmation}
        title='Picking Confirmation'
        id='view-picking-confirmation-form-title'
      >
        <PickingConfirmationDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => setIsViewPickingConfirmationDialogOpen(false)}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* Create outbound shipment note form */}
      <DialogModal
        open={isCreateOutboundShipmentNoteFormOpen}
        setOpen={setIsCreateOutboundShipmentNoteFormOpen}
        onClose={handleCloseCreateOutboundShipmentNoteForm}
        title='Create Outbound Shipment Note'
        id='create-outbound-shipment-note-form-title'
      >
        <CreateOutboundShipmentNoteForm
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => {
            setIsCreateOutboundShipmentNoteFormOpen(false);
          }}
          onSubmit={handleSubmitOutboundShipmentNote}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* View outbound shipment note */}
      <DialogModal
        open={isViewOutboundShipmentNoteDialogOpen}
        setOpen={setIsViewOutboundShipmentNoteDialogOpen}
        onClose={handleCloseViewOutboundShipmentNote}
        title='Outbound Shipment Note'
        id='view-outbound-shipment-note-form-title'
      >
        <OutboundShipmentNoteDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => setIsViewOutboundShipmentNoteDialogOpen(false)}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* Create proof of delivery form */}
      <DialogModal
        open={isCreateProofOfDeliveryFormOpen}
        setOpen={setIsCreateProofOfDeliveryFormOpen}
        onClose={handleCloseCreateProofOfDeliveryForm}
        title='Create Proof Of Delivery'
        id='create-proof-of-delivery-form-title'
      >
        <CreateProofOfDeliveryForm
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => {
            setIsCreateOutboundShipmentNoteFormOpen(false);
          }}
          onSubmit={handleSubmitCreateProofOfDelivery}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* View proof of delivery */}
      <DialogModal
        open={isViewProofOfDeliveryDialogOpen}
        setOpen={setIsViewProofOfDeliveryDialogOpen}
        onClose={handleCloseViewProofOfDelivery}
        title='Proof Of Delivery'
        id='view-proof-of-delivery-form-title'
      >
        <ProofOfDeliveryDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => setIsViewProofOfDeliveryDialogOpen(false)}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
    </View>
  );
}

WarehouseManagerNoticeboard.displayName = 'WarehouseManagerNoticeboard';
