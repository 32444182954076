import * as Yup from 'yup';

export const validationSchema = Yup.object({
  shipmentNoteNumber: Yup.string().required(
    'A shipment note number is required.'
  ),
  supplierName: Yup.string().required('A supplier name is required.'),
  skus: Yup.array()
    .of(
      Yup.object({
        skuId: Yup.string().required('A SKU id is required.'),
        skuName: Yup.string().required('A SKU name is required.'),
        productId: Yup.string().required('A product ID is required.'),
        palletisingId: Yup.string().required(
          'A palletising option is required.'
        ),
        palletising: Yup.array().of(
          Yup.object({
            id: Yup.string(),
            type: Yup.string(),
            amountOfLayersInPallet: Yup.number(),
            amountOfSkusInLayer: Yup.number(),
            dimensions: Yup.object({
              width: Yup.number().when('type', ([type], $schema) =>
                type === 'Custom Pallet'
                  ? $schema.required()
                  : $schema.nullable()
              ),
              length: Yup.number().when('type', ([type], $schema) =>
                type === 'Custom Pallet'
                  ? $schema.required()
                  : $schema.nullable()
              ),
              height: Yup.number().when('type', ([type], $schema) =>
                type === 'Custom Pallet'
                  ? $schema.required()
                  : $schema.nullable()
              ),
              dimensionsUnitOfMeasure: Yup.string().when(
                'type',
                ([type], $schema) =>
                  type === 'Custom Pallet'
                    ? $schema.required()
                    : $schema.nullable()
              ),
            }),
          })
        ),
        skuQuantity: Yup.number().required('A SKU quantity is required.'),
      })
    )
    .min(1, 'At least one SKU is required.'),
  comment: Yup.string(),
  estimatedTimeOfArrival: Yup.date()
    .typeError('An estimated time of arrival is required.')
    .required('An estimated time of arrival is required.'),

  warehouseId: Yup.string().required('A warehouse is required.'),
});

export const defaultValues = {
  shipmentNoteNumber: '',
  supplierName: '',
  skus: [],
  comment: '',
  estimatedTimeOfArrival: '',
  warehouseId: '',
};

export const calculateTotalPalletAmount = (
  amountOfSkusInLayer: number,
  amountOfLayersInPallet: number,
  skuQuantity: number
) => {
  const maxSkusPerPallet = amountOfSkusInLayer * amountOfLayersInPallet;
  const palletAmount = skuQuantity / maxSkusPerPallet;
  const palletAmountCeil = Math.ceil(palletAmount);

  const isExact = `${palletAmountCeil}` === `${palletAmount}`;

  return isExact
    ? palletAmountCeil
    : `${palletAmountCeil} (${
        palletAmount.toFixed(1) === '0.0'
          ? 'less than 1 pallet'
          : palletAmount.toFixed(1)
      })`;
};
