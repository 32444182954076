import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { Link, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  ArchiveBoxIcon,
  Bars3Icon,
  CheckIcon,
  ChevronDownIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useAtom, useAtomValue } from 'jotai';
import { Typography } from '@mui/material';
import { NotificationType } from '@components/Notification/Notification';
import { FLOX_LOGO_BLUE_SRC } from '../../constants';
import { useAuth } from '../../context';
import { notificationsAtom } from '../../store/jotai';
import { useEnableWebSockets, UserRoleService } from '../../services';
import { NavigationItemList } from './types';

const userNavigation = [
  { name: 'Account profile', href: '/profile' },
  { name: 'Legal Documents', href: '/legal-documents' },
  { name: 'Team', href: '/team' },
  { name: 'Log out', href: '#' },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface NavigationProps {
  items: NavigationItemList;
  children: React.ReactNode;
}

dayjs.extend(relativeTime);

function InboxItem({
  notification,
  notifications,
  isWarehouseClient,
  markNotificationAsRead,
  markNotificationAsArchived,
  setNotifications,
  setInboxOpen,
}: {
  notification: NotificationType;
  notifications: NotificationType[];
  isWarehouseClient: boolean;
  markNotificationAsRead: (notificationId: string) => void;
  markNotificationAsArchived: (notificationId: string) => void;
  setNotifications: (notifications: NotificationType[]) => void;
  setInboxOpen: (isOpen: boolean) => void;
}) {
  const handleMarkAsReadClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
    notificationId: string
  ) => {
    event.stopPropagation();
    markNotificationAsRead(notificationId);

    setNotifications(
      notifications.map((n) => {
        if (n.id === notificationId) {
          return { ...n, readAt: new Date().toISOString() };
        }
        return n;
      })
    );
  };

  const handleMarkAsArchivedClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
    notificationId: string
  ) => {
    event.stopPropagation();
    markNotificationAsArchived(notificationId);

    setNotifications(
      notifications.map((n) => {
        if (n.id === notificationId) {
          return {
            ...n,
            readAt: new Date().toISOString(),
            archivedAt: new Date().toISOString(),
          };
        }
        return n;
      })
    );
  };

  const buildNotificationLink = () => {
    const notificationTypePrefix = notification.notificationType.split('.')[0];
    const notificationTypeSuffix = notification.notificationType.split('.')[1];

    switch (notificationTypePrefix) {
      case 'WarehouseMatch':
        return `/warehouse/${notification.relatedEntities[0].id}`;
      case 'InventoryProduct':
        return isWarehouseClient
          ? `/product-sku-master?productId=${notification.relatedEntities[0].id}`
          : `/shipments`;
      case 'InventorySku':
        return `/shipments`;
      case 'SupplyChainProcess':
        switch (notificationTypeSuffix) {
          case 'PickingNoteConfirmed':
            return `/noticeboard?supplyChainProcessId=${notification.relatedEntities[0].id}&processChildType=${notificationTypeSuffix}`;
          case 'GoodsReceivedNoteFinalised':
            return `/noticeboard?supplyChainProcessId=${notification.relatedEntities[0].id}&processChildType=${notificationTypeSuffix}`;
          default:
            return `/noticeboard?supplyChainProcessId=${notification.relatedEntities[0].id}`;
        }
      case 'Vehicle':
        return `/fleet-master`;
      default:
        return `/noticeboard`;
    }
  };

  return (
    <div className='p-4 border-t border-gray-200'>
      <Link to={buildNotificationLink()} onClick={() => setInboxOpen(false)}>
        {!notification?.readAt && (
          <span className='inline-block w-2 h-2 mr-2 bg-primary rounded-full' />
        )}
        <div className='flex justify-between items-center'>
          <div className='font-bold text-md text-gray-800'>
            {notification.title}
          </div>
          <div className='text-xs text-gray-500 whitespace-nowrap'>
            {dayjs(notification.createdAt).fromNow()}
          </div>
        </div>
        <div className='text-sm text-gray-600 mt-1'>{notification.message}</div>
      </Link>
      {(!notification?.readAt || !notification?.archivedAt) && (
        <div className='flex items-center border border-gray-300 rounded mt-4 p-1 space-x-2 w-min'>
          {!notification?.readAt && (
            <button
              type='button'
              className='flex h-6 w-6 items-center justify-center hover:cursor-pointer hover:bg-gray-200 rounded'
              onClick={(e) => handleMarkAsReadClick(e, notification.id)}
            >
              <CheckIcon
                aria-hidden='true'
                className='h-4 w-4 text-black-600'
              />
            </button>
          )}
          {!notification?.archivedAt && (
            <button
              type='button'
              className='flex h-6 w-6 items-center justify-center hover:cursor-pointer hover:bg-gray-200 rounded'
              onClick={(e) => handleMarkAsArchivedClick(e, notification.id)}
            >
              <ArchiveBoxIcon
                aria-hidden='true'
                className='h-4 w-4 text-black-600'
              />
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export function Navigation({ items, children }: NavigationProps) {
  const { pathname } = useLocation();
  const { user, logOut } = useAuth();
  const { markNotificationAsRead, markNotificationAsArchived } =
    useEnableWebSockets();
  const { isWarehouseClient, isWarehouseManager } = UserRoleService();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isInboxOpen, setIsInboxOpen] = useState(false);
  const [notifications, setNotifications] = useAtom(notificationsAtom);

  return (
    <div>
      <Dialog
        open={sidebarOpen}
        onClose={setSidebarOpen}
        className='relative z-50 lg:hidden'
      >
        <DialogBackdrop
          transition
          className='fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0'
        />

        <div className='fixed inset-0 flex'>
          <DialogPanel
            transition
            className='relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full'
          >
            <TransitionChild>
              <div className='absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0'>
                <button
                  type='button'
                  onClick={() => setSidebarOpen(false)}
                  className='-m-2.5 p-2.5'
                >
                  <span className='sr-only'>Close sidebar</span>
                  <XMarkIcon
                    aria-hidden='true'
                    className='h-6 w-6 text-white'
                  />
                </button>
              </div>
            </TransitionChild>
            <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4'>
              <div>
                <a href='/' className='flex mt-4 shrink-0 items-center'>
                  <img
                    alt='FLOX'
                    src={FLOX_LOGO_BLUE_SRC}
                    className='h-8 w-auto'
                  />
                </a>
                <Typography sx={{ textDecoration: 'none' }} fontSize='0.85rem'>
                  BETA
                </Typography>
              </div>
              <nav className='flex flex-1 flex-col'>
                <ul className='flex flex-1 flex-col gap-y-7'>
                  <li>
                    <ul className='-mx-2 space-y-1'>
                      {items.map((item) => {
                        if (
                          item.id === 'finance' &&
                          !user?.roles.includes('FinanceAdmin')
                        )
                          return null;
                        const isActive = pathname === item.href;
                        if (item.sublinks) {
                          return (
                            <li key={item.id}>
                              <div className='text-sm font-semibold leading-6 text-gray-500'>
                                {item.label}
                              </div>
                              <ul className='mt-2 ml-2 space-y-1'>
                                {item.sublinks.map((sublink) => {
                                  const isSublinkActive =
                                    pathname === sublink.href;
                                  return (
                                    <li key={sublink.id}>
                                      <a
                                        href={sublink.href}
                                        className={classNames(
                                          isSublinkActive
                                            ? 'bg-floxBlue text-white'
                                            : 'hover:bg-floxBlue text-black hover:text-white',
                                          'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                                        )}
                                      >
                                        <span className='truncate'>
                                          {sublink.label}
                                        </span>
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            </li>
                          );
                        }
                        return (
                          <li key={item.id}>
                            <a
                              href={item.href}
                              className={classNames(
                                isActive
                                  ? 'bg-floxBlue text-white'
                                  : 'hover:bg-floxBlue text-black hover:text-white',
                                'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                              )}
                            >
                              {item.label}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      {/* Static sidebar for desktop */}
      <div className='hidden lg:fixed lg:inset-y-0 lg:z-[60] lg:flex lg:w-64 lg:flex-col'>
        <div className='flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4'>
          <div>
            <a href='/' className='flex mt-4 shrink-0 items-center'>
              <img alt='FLOX' src={FLOX_LOGO_BLUE_SRC} className='h-8 w-auto' />
            </a>
            <Typography sx={{ textDecoration: 'none' }} fontSize='0.85rem'>
              BETA
            </Typography>
          </div>
          <nav className='flex flex-1 flex-col'>
            <ul className='flex flex-1 flex-col gap-y-7'>
              <li className='-mx-2 space-y-1'>
                <button
                  type='button'
                  className='hover:bg-floxBlue text-black hover:text-white group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 w-full'
                  onClick={() => setIsInboxOpen(!isInboxOpen)}
                >
                  <span className='truncate mr-auto'>Inbox</span>
                  {notifications.some(
                    (notification) =>
                      notification?.readAt === null &&
                      notification?.archivedAt === null
                  ) && (
                    <span className='inline-flex items-center rounded-md bg-red-700 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-red-600/10'>
                      {
                        notifications.filter(
                          (notification) =>
                            notification?.readAt === null &&
                            notification?.archivedAt === null
                        ).length
                      }
                    </span>
                  )}
                </button>
              </li>
              <li>
                <ul className='-mx-2 space-y-1'>
                  {items.map((item) => {
                    if (
                      item.id === 'finance' &&
                      !user?.roles.includes('FinanceAdmin')
                    )
                      return null;
                    const isActive = pathname === item.href;
                    if (item.sublinks) {
                      return (
                        <li key={item.id}>
                          <div className='text-sm font-semibold leading-6 text-gray-500'>
                            {item.label}
                          </div>
                          <ul className='mt-2 ml-2 space-y-1'>
                            {item.sublinks.map((sublink) => {
                              const isSublinkActive = pathname === sublink.href;
                              return (
                                <li key={sublink.id}>
                                  <a
                                    href={sublink.href}
                                    className={classNames(
                                      isSublinkActive
                                        ? 'bg-floxBlue text-white'
                                        : 'hover:bg-floxBlue text-black hover:text-white',
                                      'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                                    )}
                                  >
                                    <span className='truncate'>
                                      {sublink.label}
                                    </span>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      );
                    }
                    return (
                      <li key={item.id}>
                        <a
                          href={item.href}
                          className={classNames(
                            isActive
                              ? 'bg-floxBlue text-white'
                              : 'hover:bg-floxBlue text-black hover:text-white',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                          )}
                        >
                          {item.label}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <Transition
        show={isInboxOpen}
        enter='transform transition ease-in-out duration-500 sm:duration-700'
        enterFrom='-translate-x-full'
        enterTo='translate-x-0'
        leave='transform transition ease-in-out duration-500 sm:duration-700'
        leaveFrom='translate-x-0'
        leaveTo='-translate-x-full'
      >
        <div
          className='hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-96 lg:flex-col lg:left-64'
          role='dialog'
          aria-modal='true'
        >
          <div className='flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4'>
            <TransitionChild>
              <div className='absolute end-0 top-0 flex w-16 justify-center pt-4 duration-300 ease-in-out data-[closed]:opacity-0'>
                <button
                  type='button'
                  onClick={() => setIsInboxOpen(false)}
                  className='-m-2.5 p-2.5'
                >
                  <span className='sr-only'>Close inbox</span>
                  <XMarkIcon
                    aria-hidden='true'
                    className='h-6 w-6 text-black'
                  />
                </button>
              </div>
            </TransitionChild>
            <div className='text-md font-semibold leading-6 text-gray-900 mt-6'>
              Inbox
            </div>
            <ul className='-mx-2 space-y-1'>
              {notifications
                .filter((notification) => notification?.archivedAt === null)
                .map((notification) => {
                  return (
                    <li key={notification.id}>
                      <InboxItem
                        notification={notification}
                        notifications={notifications}
                        markNotificationAsRead={markNotificationAsRead}
                        markNotificationAsArchived={markNotificationAsArchived}
                        setNotifications={setNotifications}
                        isWarehouseClient={isWarehouseClient(user)}
                        setInboxOpen={setIsInboxOpen}
                      />
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </Transition>
      {isInboxOpen && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          onClick={() => setIsInboxOpen(false)}
          className='fixed inset-0 z-10'
        />
      )}

      <div className='lg:pl-64'>
        <div className='sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8'>
          <button
            type='button'
            onClick={() => setSidebarOpen(true)}
            className='-m-2.5 p-2.5 text-gray-700 lg:hidden'
          >
            <span className='sr-only'>Open sidebar</span>
            <Bars3Icon aria-hidden='true' className='h-6 w-6' />
          </button>

          {/* Separator */}
          <div aria-hidden='true' className='h-6 w-px bg-gray-200 lg:hidden' />

          <div className='flex flex-1 gap-x-4 self-stretch lg:gap-x-6'>
            {/* <form action='#' method='GET' className='relative flex flex-1'> */}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            {/* <label htmlFor='search-field' className='sr-only'>
                Search
              </label>
              <MagnifyingGlassIcon
                aria-hidden='true'
                className='pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400'
              />
              <input
                id='search-field'
                name='search'
                type='search'
                placeholder='Search...'
                className='block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm'
              />
            </form> */}
            <div className='relative flex flex-1' />
            <div className='flex items-center gap-x-4 lg:gap-x-6'>
              {/* Separator */}
              <div
                aria-hidden='true'
                className='hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200'
              />
              {/* Profile dropdown */}
              <Menu as='div' className='relative'>
                <MenuButton className='-m-1.5 flex items-center p-1.5'>
                  <span className='sr-only'>Open user menu</span>
                  <span className='hidden lg:flex lg:items-center'>
                    <span
                      aria-hidden='true'
                      className='ml-4 text-sm font-semibold leading-6 text-gray-900'
                    >
                      User menu
                    </span>
                    <ChevronDownIcon
                      aria-hidden='true'
                      className='ml-2 h-5 w-5 text-gray-400'
                    />
                  </span>
                </MenuButton>
                <MenuItems
                  transition
                  className='absolute right-0 z-10 mt-2.5 w-36 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in'
                >
                  {userNavigation.map((item) => (
                    <MenuItem key={item.name}>
                      {item.name === 'Log out' ? (
                        <button
                          type='button'
                          className='block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50'
                          onClick={logOut}
                        >
                          {user ? 'Log out' : 'Log in'}
                        </button>
                      ) : (
                        <a
                          href={item.href}
                          className='block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50'
                        >
                          {item.name}
                        </a>
                      )}
                    </MenuItem>
                  ))}
                </MenuItems>
              </Menu>
            </div>
          </div>
        </div>

        <main>
          <div className='px-2 sm:px-4 lg:px-6'>{children}</div>
        </main>
      </div>
    </div>
  );
}
