import {
  AddProductFormValues,
  SelectedProduct,
} from '@forms/client/AddProductForm/types';
import { useAuthHttp, useCloseDialog } from '@hooks';
import { useAppDispatch, useAppSelector } from '@store';
import { useState } from 'react';
import {
  CREATE_PRODUCT_URL,
  GET_PRODUCT_URL,
  LIST_PRODUCTS_URL,
  UPDATE_PRODUCT_URL,
} from '@constants';
import { useMediaQuery } from 'usehooks-ts';
import {
  addInventory,
  refreshInventory,
  upsertSingleInventoryItem,
} from '@features/inventory';
import { AxiosResponse } from 'axios';

export const useInventory = () => {
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const authHttp = useAuthHttp();
  const authHttpFormData = useAuthHttp('multipart/form-data');
  const dispatch = useAppDispatch();

  const [isAddOrEditProductModalOpen, setIsAddOrEditProductModalOpen] =
    useState(false);

  const [isProductSummaryModalOpen, setIsProductSummaryModalOpen] =
    useState(false);

  const handleCloseProductSummaryModal = useCloseDialog(
    setIsProductSummaryModalOpen
  );

  const [isProductInventoryLoading, setIsProductInventoryLoading] =
    useState(false);
  const products = useAppSelector(
    (state) => state.inventory.inventory.products
  );

  const lastItem = useAppSelector(
    (state) =>
      state.inventory.inventory.inventoryCurrentQuery.pagination?.lastItem
  );

  const hasMoreInventoryData = useAppSelector(
    (state) => state.inventory.inventory.inventoryHasMoreData
  );

  const [isFetchingSelectedProduct, setIsFetchingSelectedProduct] =
    useState(false);

  const [selectedProduct, setSelectedProduct] = useState<
    SelectedProduct | undefined
  >(undefined);

  const [isInitialFetch, setIsInitialFetch] = useState(
    import.meta.env.MODE === 'development'
  );

  const fetchProductInventory = async () => {
    if (isProductInventoryLoading || isInitialFetch) {
      setIsInitialFetch(false);
      return;
    }

    setIsProductInventoryLoading(true);

    try {
      const { data } = await authHttp.post(LIST_PRODUCTS_URL, {
        pagination: {
          lastItem,
          itemsPerPage: isDesktop ? 100 : 25,
        },
      });

      data.hasMoreData = !(data.products.length < (isDesktop ? 100 : 25));
      dispatch(addInventory(data));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsProductInventoryLoading(false);
    }
  };

  const refreshProductInventory = async () => {
    if (isProductInventoryLoading) return;

    setIsProductInventoryLoading(true);

    try {
      const { data } = await authHttp.post(LIST_PRODUCTS_URL, {
        pagination: {
          lastItem: null,
          itemsPerPage: isDesktop ? 100 : 25,
        },
      });

      data.hasMoreData = !(data.products.length < (isDesktop ? 100 : 25));
      dispatch(refreshInventory(data));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsProductInventoryLoading(false);
    }
  };

  const handleSubmitProduct = async (
    values: AddProductFormValues,
    isUpdate: boolean,
    productId?: string
  ) => {
    console.log(values);
    try {
      const { data } = await authHttpFormData.post(
        isUpdate ? UPDATE_PRODUCT_URL : CREATE_PRODUCT_URL,
        {
          product: productId
            ? { ...values.product, inventoryProductId: productId }
            : values.product,
          productImages: values.productImages,
          skuImages: values.skuImages,
          skuBarcodeImages: values.skuBarcodeImages,
          productBarcodeImage: values.barcodeImage,
          deletedProductImageIds: values.deletedProductImageIds,
          deletedSkuImageIds: values.deletedSkuImageIds,
        },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      // TODO = update store with new product item on success:
      setIsAddOrEditProductModalOpen(false);
      dispatch(upsertSingleInventoryItem(data));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const handleViewProductSummary = async (id: string) => {
    setIsFetchingSelectedProduct(true);
    try {
      const { data }: AxiosResponse<SelectedProduct> = await authHttp.get(
        GET_PRODUCT_URL,
        {
          params: {
            productId: id,
          },
        }
      );
      setSelectedProduct(data);
      setIsProductSummaryModalOpen(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsFetchingSelectedProduct(false);
    }
  };

  const getProductsScrollParent = () =>
    document.getElementById('product-list') as HTMLElement;

  const handleScrollToTopOfProducts = () => {
    getProductsScrollParent().scrollTo({ top: 0, behavior: 'smooth' });
  };

  return {
    isAddOrEditProductModalOpen,
    setIsAddOrEditProductModalOpen,
    isProductSummaryModalOpen,
    setIsProductSummaryModalOpen,
    handleCloseProductSummaryModal,
    fetchProductInventory,
    isProductInventoryLoading,
    products,
    refreshProductInventory,
    hasMoreInventoryData,
    selectedProduct,
    setSelectedProduct,
    isFetchingSelectedProduct,
    handleSubmitProduct,
    handleViewProductSummary,
    getProductsScrollParent,
    handleScrollToTopOfProducts,
  };
};
