import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@store';
import { useAuthHttp } from '@hooks';
import { LIST_SUPPLY_CHAIN_PROCESSES_URL } from '@constants';
import {
  addInboundShipments,
  addOutboundShipments,
} from '@features/supplyChain';

export const useShipments = () => {
  const authHttp = useAuthHttp();
  const dispatch = useAppDispatch();

  const fetchShipments = async (direction: 'Inbound' | 'Outbound') => {
    const { data } = await authHttp.post(LIST_SUPPLY_CHAIN_PROCESSES_URL, {
      searchQuery: '',
      type: direction,
      hasShipmentNote: direction === 'Inbound' ? true : null,
      pagination: {
        lastItem: '',
        itemsPerPage: 50,
      },
      // archivedByShipperStatus: 'NotArchived', // Currently returns all - could filter by archived here
      // archivedByLspStatus: 'NotArchived',
    });

    return data;
  };

  const {
    supplyChain: { inboundShipments, outboundShipments },
  } = useAppSelector((state) => state.supplyChain);

  // Inbound shipments

  const lastInboundShipmentsItem = useAppSelector(
    (state) =>
      state.supplyChain.supplyChain.inboundShipmentsCurrentQuery.pagination
        ?.lastItem
  );

  const hasMoreInboundShipmentsData = useAppSelector(
    (state) => state.supplyChain.supplyChain.hasMoreInboundShipmentsData
  );

  const [isLoadingInboundShipments, setIsLoadingInboundShipments] =
    useState(false);

  const [isLoadingInboundShipmentsError, setIsLoadingInboundShipmentsError] =
    useState(false);

  const [isInitialInboundShipmentsFetch, setIsInitialInboundShipmentsFetch] =
    useState(import.meta.env.MODE === 'development');

  const fetchInboundShipments = async () => {
    if (isLoadingInboundShipments || isLoadingInboundShipmentsError) {
      return;
    }

    setIsLoadingInboundShipments(true);

    try {
      const data = await fetchShipments('Inbound');

      data.hasMoreInboundShipmentsData = data.totalCount > 5;

      dispatch(addInboundShipments(data));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error: ', error);
      setIsLoadingInboundShipmentsError(true);
    } finally {
      setIsLoadingInboundShipments(false);
    }
  };

  // Outbound

  const [isLoadingOutboundShipments, setIsLoadingOutboundShipments] =
    useState(false);

  const [isLoadingOutboundShipmentsError, setIsLoadingOutboundShipmentsError] =
    useState(false);

  const [isInitialOutboundShipmentsFetch, setIsInitialOutboundShipmentsFetch] =
    useState(import.meta.env.MODE === 'development');

  const fetchOutboundShipments = async () => {
    if (isLoadingInboundShipments || isLoadingInboundShipmentsError) {
      return;
    }

    setIsLoadingOutboundShipments(true);

    try {
      const data = await fetchShipments('Outbound');

      data.hasMoreOutboundShipmentsData = data.totalCount > 5;

      dispatch(addOutboundShipments(data));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error: ', error);
      setIsLoadingOutboundShipmentsError(true);
    } finally {
      setIsLoadingOutboundShipments(false);
    }
  };

  return {
    inboundShipments,
    fetchInboundShipments,
    isInitialInboundShipmentsFetch,
    setIsInitialInboundShipmentsFetch,
    isLoadingInboundShipments,
    isLoadingInboundShipmentsError,
    hasMoreInboundShipmentsData,
    outboundShipments,
    fetchOutboundShipments,
  };
};
