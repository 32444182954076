import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading } from '@components/Heading';
import { View } from '@views/View';
import {
  Board,
  BoardLane,
  BoardLaneHeader,
  BoardLaneTitleContainer,
  BoardLaneTitleButton,
  BoardLaneItemCount,
} from '@components/Board';
import { useDocumentTitle, useMediaQuery } from 'usehooks-ts';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { DialogModal } from '@components/DialogModal';
import InfiniteScroll from 'react-infinite-scroller';
import {
  InfiniteScrollEndMessage,
  InfiniteScrollLoading,
  InfiniteScrollErrorMessage,
} from '@components/InfiniteScroll';
import {
  InboundSupplyChainProcessEventList,
  OutboundSupplyChainProcessEventList,
  SupplyChainProcessEventCard,
} from '@components/SupplyChainProcessEventList';
import { CreateInboundShipmentNoteForm } from '@forms/client/CreateInboundShipmentNoteForm';
import {
  useInboundProcess,
  useInboundShipmentNote,
  useDespatchNote,
  useOutboundProcess,
  useAuthHttp,
} from '@hooks';
import { InvoiceEventList } from '@components/InvoiceEventList/InvoiceEventList';
import { InvoicingEventCard } from '@components/InvoiceEventList/InvoiceEventCard';
import { CreateDespatchNoteForm } from '@forms/client/CreateDespatchNoteForm';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { WarehouseSelector } from '@components/WarehouseSelector';
import { useAuth } from '@context';
import { Tooltip, Stack } from '@mui/material';
import { InboundProcess, OutboundProcess } from '@features/supplyChain/types';
import {
  addInboundProcesses,
  addOutboundProcesses,
} from '@features/supplyChain';
import { ShipmentNoteDetails } from './ShipmentNoteDetails';
import { GoodsReceivedNoteDetails } from './GoodsReceivedNoteDetails';
import { WarehouseClientInboundSideDrawer } from './WarehouseClientInboundSideDrawer';
import { WarehouseClientOutboundSideDrawer } from './WarehouseClientOutboundSideDrawer';
import { DespatchNoteDetails } from './DespatchNoteDetails';
import { PickingConfirmationDetails } from './PickingConfirmationDetails';
import { OutboundShipmentNoteDetails } from './OutboundShipmentNoteDetails';
import { ProofOfDeliveryDetails } from './ProofOfDeliveryDetails';
import {
  businessProfileAtom,
  isInboundSidebarOpenAtom,
  isOutboundSidebarOpenAtom,
  selectedInboundProcessIdAtom,
  selectedOutboundProcessIdAtom,
} from '../../../store/jotai';
import { useInvoices } from '../../../hooks/useInvoices';
import { Swimlane } from './Swimlane';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  INBOUND_SHIPMENT_TYPE,
  LIST_SUPPLY_CHAIN_PROCESSES_URL,
  OUTBOUND_SHIPMENT_TYPE,
} from '../../../constants';
import { FilterService } from '../../../services';

export function WarehouseClientNoticeboard() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const authHttp = useAuthHttp();
  const dispatch = useAppDispatch();
  const { getFilterValue } = FilterService();

  const isDesktop = useMediaQuery('(min-width: 62em)');
  const isTablet = useMediaQuery('(min-width: 48em)');
  const businessProfile = useAtomValue(businessProfileAtom);
  const [isInboundSidebarOpen, setIsInboundSidebarOpen] = useAtom(
    isInboundSidebarOpenAtom
  );
  const [isOutboundSidebarOpen, setIsOutboundSidebarOpen] = useAtom(
    isOutboundSidebarOpenAtom
  );
  const setSelectedInboundProcessId = useSetAtom(selectedInboundProcessIdAtom);
  const setSelectedOutboundProcessId = useSetAtom(
    selectedOutboundProcessIdAtom
  );
  const searchParams = new URLSearchParams(window.location.search);

  const supplyChainProcessId = searchParams.get('supplyChainProcessId');
  const processChildType = searchParams.get('processChildType');
  const noticeboardWarehouseId = getFilterValue('noticeboardWarehouseId');

  const [isInitialInboundProcessesFetch, setIsInitialInboundProcessesFetch] =
    useState(import.meta.env.MODE === 'development');
  const [isLoadingInboundProcesses, setIsLoadingInboundProcesses] =
    useState(false);
  const [refreshInboundOnNextFetch, setRefreshInboundOnNextFetch] =
    useState(false);
  const [isLoadingInboundProcessesError, setIsLoadingInboundProcessesError] =
    useState(false);
  const [includeArchived, setIncludeArchived] = useState(false);
  const lastInboundItem = useAppSelector(
    (state) =>
      state.supplyChain.supplyChain.inboundCurrentQuery.pagination?.lastItem
  );

  const [isInitialOutboundProcessesFetch, setIsInitialOutboundProcessesFetch] =
    useState(import.meta.env.MODE === 'development');
  const [isLoadingOutboundProcesses, setIsLoadingOutboundProcesses] =
    useState(false);
  const [refreshOutboundOnNextFetch, setRefreshOutboundOnNextFetch] =
    useState(false);
  const [isLoadingOutboundProcessesError, setIsLoadingOutboundProcessesError] =
    useState(false);
  const lastOutboundItem = useAppSelector(
    (state) =>
      state.supplyChain.supplyChain.outboundCurrentQuery.pagination?.lastItem
  );

  useDocumentTitle('FLOX - Noticeboard');

  const {
    isCreateInboundShipmentNoteFormOpen,
    setIsCreateInboundShipmentNoteFormOpen,
    handleCloseCreateInboundShipmentNoteForm,
    handleSubmitInboundShipmentNote,
  } = useInboundShipmentNote();

  const {
    inboundProcesses,
    hasMoreInboundData,
    selectedInboundProcess,
    setSelectedInboundProcess,
    selectedInboundProcessHasDescription,
    handleScrollToTopOfInbound,
    isViewShipmentNoteDialogOpen,
    setIsViewShipmentNoteDialogOpen,
    handleCloseViewShipmentNote,
    isViewGoodsReceivedNoteDialogOpen,
    setIsViewGoodsReceivedNoteDialogOpen,
    handleCloseViewGoodsReceivedNote,
    selectedProcessChildType,
    setSelectedProcessChildType,
  } = useInboundProcess();

  const {
    outboundProcesses,
    hasMoreOutboundData,
    selectedOutboundProcess,
    setSelectedOutboundProcess,
    selectedOutboundProcessHasDescription,
    handleScrollToTopOfOutbound,
    isViewDespatchNoteDialogOpen,
    setIsViewDespatchNoteDialogOpen,
    handleCloseViewDespatchNote,
    isViewPickingConfirmationDialogOpen,
    setIsViewPickingConfirmationDialogOpen,
    handleCloseViewPickingConfirmation,
    isViewOutboundShipmentNoteDialogOpen,
    setIsViewOutboundShipmentNoteDialogOpen,
    handleCloseViewOutboundShipmentNote,
    isViewProofOfDeliveryDialogOpen,
    setIsViewProofOfDeliveryDialogOpen,
    handleCloseViewProofOfDelivery,
  } = useOutboundProcess();

  const {
    fetchInvoices,
    fetchPaidInvoices,
    billingCycles,
    paidBillingCycles,
    hasMoreInvoiceData,
    hasMorePaidInvoiceData,
    isLoadingInvoicesError,
    isLoadingPaidInvoicesError,
    getInvoiceScrollParent,
    getPaymentsScrollParent,
  } = useInvoices();

  const {
    isCreateDespatchNoteFormOpen,
    setIsCreateDespatchNoteFormOpen,
    handleCloseCreateDespatchNoteForm,
    handleSubmitDespatchNote,
  } = useDespatchNote();

  const clearProcesses = () => {
    setSelectedInboundProcess(null);
    setSelectedOutboundProcess(null);
    setSelectedInboundProcessId('');
    setSelectedOutboundProcessId('');
    setIsInboundSidebarOpen(false);
    setIsOutboundSidebarOpen(false);
  };

  useEffect(() => {
    clearProcesses();
    if (!inboundProcesses && !outboundProcesses) return;
    if (supplyChainProcessId) {
      const inboundProcess = inboundProcesses.find(
        (process) => process.id === supplyChainProcessId
      );
      const outboundProcess = outboundProcesses.find(
        (process) => process.id === supplyChainProcessId
      );

      if (inboundProcess) {
        setSelectedInboundProcess(inboundProcess);
        setSelectedInboundProcessId(inboundProcess.id);
        if (processChildType) {
          if (processChildType === 'PickingNoteConfirmed') {
            setIsViewPickingConfirmationDialogOpen(true);
          } else if (processChildType === 'GoodsReceivedNoteFinalised') {
            setIsViewGoodsReceivedNoteDialogOpen(true);
          }
        } else {
          setIsInboundSidebarOpen(true);
          setIsViewShipmentNoteDialogOpen(true);
        }
      } else if (outboundProcess) {
        setSelectedOutboundProcess(outboundProcess);
        setSelectedOutboundProcessId(outboundProcess.id);
        if (processChildType) {
          if (processChildType === 'PickingNoteConfirmed') {
            setIsViewPickingConfirmationDialogOpen(true);
          } else if (processChildType === 'GoodsReceivedNoteFinalised') {
            setIsViewGoodsReceivedNoteDialogOpen(true);
          }
        } else {
          setIsOutboundSidebarOpen(true);
          setIsViewDespatchNoteDialogOpen(true);
        }
      }
    }
  }, [
    supplyChainProcessId,
    processChildType,
    inboundProcesses,
    outboundProcesses,
  ]);

  const cardForInboundProcess = (inboundProcess: InboundProcess) => (
    <SupplyChainProcessEventCard
      key={inboundProcess.id}
      inboundProcess={inboundProcess}
      onClick={() => {
        setSelectedInboundProcess(inboundProcess);
        setSelectedInboundProcessId(inboundProcess.id);
        setIsInboundSidebarOpen(true);
        if (isOutboundSidebarOpen) {
          setIsOutboundSidebarOpen(false);
        }
      }}
    />
  );

  const cardForOutboundProcess = (outboundProcess: OutboundProcess) => (
    <SupplyChainProcessEventCard
      key={outboundProcess.id}
      outboundProcess={outboundProcess}
      onClick={() => {
        setSelectedOutboundProcess(outboundProcess);
        setSelectedOutboundProcessId(outboundProcess.id);
        setIsOutboundSidebarOpen(true);
        if (isInboundSidebarOpen) {
          setIsInboundSidebarOpen(false);
        }
      }}
    />
  );

  const addButtonForInbound = () => (
    <Button
      variant='contained'
      size='small'
      startIcon={<AddIcon />}
      sx={{ textTransform: 'none' }}
      onClick={() => setIsCreateInboundShipmentNoteFormOpen(true)}
    >
      Add
    </Button>
  );

  const addButtonForOutbound = () => (
    <Button
      variant='contained'
      size='small'
      startIcon={<AddIcon />}
      sx={{ textTransform: 'none' }}
      onClick={() => setIsCreateDespatchNoteFormOpen(true)}
    >
      Add
    </Button>
  );

  const disabledAddButtonForOutbound = () => (
    <Tooltip
      title={
        user?.roles.includes('FinanceAdmin')
          ? `A bank account must be connected before you can create a despatch note. Please navigate to Account Profile to link a bank account.`
          : `A bank account must be connected before you can create a despatch note. Please contact your finance admin.`
      }
    >
      <span>
        <Button
          variant='contained'
          size='small'
          startIcon={<AddIcon />}
          sx={{ textTransform: 'none' }}
          disabled
        >
          Add
        </Button>
      </span>
    </Tooltip>
  );

  const fetchInboundSupplyChainProcesses = async () => {
    if (
      isInitialInboundProcessesFetch ||
      isLoadingInboundProcesses ||
      isLoadingInboundProcessesError
    ) {
      setIsInitialInboundProcessesFetch(false);
      return;
    }

    setIsLoadingInboundProcesses(true);

    try {
      const { data } = await authHttp.post(LIST_SUPPLY_CHAIN_PROCESSES_URL, {
        searchQuery: '',
        warehouseId:
          noticeboardWarehouseId === 'All' ? null : noticeboardWarehouseId,
        shipperBusinessId: null,
        type: INBOUND_SHIPMENT_TYPE,
        pagination: {
          lastItem: refreshInboundOnNextFetch ? null : lastInboundItem,
          itemsPerPage: 25,
        },
        archivedByShipperStatus: includeArchived ? null : 'NotArchived',
      });

      data.hasMoreInboundData = !(data.supplyChainProcesses.length < 25);
      data.isRefresh = refreshInboundOnNextFetch;
      setRefreshInboundOnNextFetch(false);
      dispatch(addInboundProcesses(data));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error: ', error);
      setIsLoadingInboundProcessesError(true);
    } finally {
      setIsLoadingInboundProcesses(false);
    }
  };

  const fetchOutboundSupplyChainProcesses = async () => {
    if (
      isInitialOutboundProcessesFetch ||
      isLoadingOutboundProcesses ||
      isLoadingOutboundProcessesError
    ) {
      setIsInitialOutboundProcessesFetch(false);
      return;
    }

    setIsLoadingOutboundProcesses(true);

    try {
      const { data } = await authHttp.post(LIST_SUPPLY_CHAIN_PROCESSES_URL, {
        searchQuery: '',
        warehouseId:
          noticeboardWarehouseId === 'All' ? null : noticeboardWarehouseId,
        shipperBusinessId: null,
        type: OUTBOUND_SHIPMENT_TYPE,
        pagination: {
          lastItem: refreshOutboundOnNextFetch ? null : lastOutboundItem,
          itemsPerPage: 25,
        },
        archivedByShipperStatus: includeArchived ? null : 'NotArchived',
      });

      data.hasMoreOutboundData = !(data.supplyChainProcesses.length < 25);
      data.isRefresh = refreshOutboundOnNextFetch;
      dispatch(addOutboundProcesses(data));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error: ', error);
      setIsLoadingOutboundProcessesError(true);
    } finally {
      setIsLoadingOutboundProcesses(false);
    }
  };

  useEffect(() => {
    setRefreshInboundOnNextFetch(true);
    setRefreshOutboundOnNextFetch(true);
    fetchInboundSupplyChainProcesses();
    fetchOutboundSupplyChainProcesses();
  }, [includeArchived]);

  return (
    <View>
      <Stack
        direction={isTablet ? 'row' : 'column'}
        gap={16}
        alignItems={isTablet ? 'center' : 'flex-start'}
      >
        <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
          Noticeboard
        </Heading>
        <WarehouseSelector />
        <input
          type='checkbox'
          id='include-archived'
          name='include-archived'
          onClick={(e) => {
            setIncludeArchived(e.currentTarget.checked);
            setRefreshInboundOnNextFetch(true);
            setRefreshOutboundOnNextFetch(true);
          }}
        />
        <div>Include archived</div>
      </Stack>
      <Board>
        {/* Inbound */}
        <Swimlane<InboundProcess>
          title='Inbound shipments'
          name='inbound'
          items={inboundProcesses}
          hasMoreData={hasMoreInboundData}
          isLoadingError={isLoadingInboundProcessesError}
          loadMore={fetchInboundSupplyChainProcesses}
          swimlaneItem={cardForInboundProcess}
          handleScrollToTop={handleScrollToTopOfInbound}
          addButtonElement={addButtonForInbound()}
          eventListElement={
            InboundSupplyChainProcessEventList as unknown as ReactNode
          }
        />
        {/* Outbound */}
        <Swimlane<OutboundProcess>
          title='Outbound shipments'
          name='outbound'
          items={outboundProcesses}
          hasMoreData={hasMoreOutboundData}
          isLoadingError={isLoadingOutboundProcessesError}
          loadMore={fetchOutboundSupplyChainProcesses}
          swimlaneItem={cardForOutboundProcess}
          handleScrollToTop={handleScrollToTopOfOutbound}
          addButtonElement={
            businessProfile?.hasConnectedBankAccount === true
              ? addButtonForOutbound()
              : disabledAddButtonForOutbound()
          }
          eventListElement={
            OutboundSupplyChainProcessEventList as unknown as ReactNode
          }
        />
        {user?.roles.includes('FinanceAdmin') && (
          <>
            <BoardLane>
              <BoardLaneHeader>
                <BoardLaneTitleContainer sx={{ my: '0.3359rem' }}>
                  <BoardLaneTitleButton>Invoices</BoardLaneTitleButton>
                  <BoardLaneItemCount itemCount={billingCycles.length} />
                </BoardLaneTitleContainer>
              </BoardLaneHeader>
              <InfiniteScroll
                element={InvoiceEventList as unknown as ReactNode}
                initialLoad
                loadMore={fetchInvoices}
                hasMore={
                  hasMoreInvoiceData === null ||
                  (hasMoreInvoiceData === true && !isLoadingInvoicesError)
                }
                useWindow={false}
                getScrollParent={getInvoiceScrollParent}
                loader={
                  <InfiniteScrollLoading
                    key='invoice-loader'
                    loadingMessage='Loading invoice items...'
                  />
                }
              >
                {billingCycles.length > 0 &&
                  billingCycles.map((billingCycle) => (
                    <InvoicingEventCard
                      key={billingCycle.id}
                      billingCycle={billingCycle}
                      invoice={billingCycle.floxToShipperInvoice}
                      onClick={() => navigate(`/invoice/${billingCycle.id}`)}
                    />
                  ))}
                {hasMoreInboundData === false && (
                  <InfiniteScrollEndMessage
                    isVisible={billingCycles.length > 25}
                    direction='column'
                    endMessageText='End of invoices'
                    handleScrollToTop={() =>
                      getInvoiceScrollParent().scrollTo({
                        top: 0,
                        behavior: 'smooth',
                      })
                    }
                  />
                )}
                {isLoadingInvoicesError && (
                  <InfiniteScrollErrorMessage errorMessage='Sorry, there was an error fetching your invoices.' />
                )}
              </InfiniteScroll>
            </BoardLane>
            <BoardLane>
              <BoardLaneHeader>
                <BoardLaneTitleContainer sx={{ my: '0.3359rem' }}>
                  <BoardLaneTitleButton>Payments</BoardLaneTitleButton>
                  <BoardLaneItemCount itemCount={paidBillingCycles.length} />
                </BoardLaneTitleContainer>
              </BoardLaneHeader>
              <InfiniteScroll
                element={InvoiceEventList as unknown as ReactNode}
                initialLoad
                loadMore={fetchPaidInvoices}
                hasMore={
                  hasMorePaidInvoiceData === null ||
                  (hasMorePaidInvoiceData === true &&
                    !isLoadingPaidInvoicesError)
                }
                useWindow={false}
                getScrollParent={getPaymentsScrollParent}
                loader={
                  <InfiniteScrollLoading
                    key='payments-loader'
                    loadingMessage='Loading payments...'
                  />
                }
              >
                {paidBillingCycles.length > 0 &&
                  paidBillingCycles.map((billingCycle) => (
                    <InvoicingEventCard
                      key={billingCycle.id}
                      billingCycle={billingCycle}
                      invoice={billingCycle.floxToShipperInvoice}
                      payment
                      onClick={() => navigate(`/invoice/${billingCycle.id}`)}
                    />
                  ))}
                {hasMorePaidInvoiceData === false && (
                  <InfiniteScrollEndMessage
                    isVisible={paidBillingCycles.length > 25}
                    direction='column'
                    endMessageText='End of payments'
                    handleScrollToTop={() =>
                      getPaymentsScrollParent().scrollTo({
                        top: 0,
                        behavior: 'smooth',
                      })
                    }
                  />
                )}
                {isLoadingPaidInvoicesError && (
                  <InfiniteScrollErrorMessage errorMessage='Sorry, there was an error fetching your payments.' />
                )}
              </InfiniteScroll>
            </BoardLane>
          </>
        )}
        {/* Temporary empty board lanes until we define them */}
        <div style={{ width: '100%', minWidth: '13rem' }} />
        <div style={{ width: '100%', minWidth: '13rem' }} />
      </Board>
      <div id='widgetRoot' />
      {/* Sidebar for selected inbound process */}
      {selectedInboundProcess && (
        <WarehouseClientInboundSideDrawer
          selectedInboundProcess={selectedInboundProcess}
          setSelectedInboundProcess={setSelectedInboundProcess}
          isOpen={isInboundSidebarOpen}
          setIsOpen={setIsInboundSidebarOpen}
          isDesktop={isDesktop}
          selectedInboundProcessHasDescription={
            selectedInboundProcessHasDescription
          }
          setIsViewShipmentNoteDialogOpen={setIsViewShipmentNoteDialogOpen}
          setIsViewGoodsReceivedNoteDialogOpen={
            setIsViewGoodsReceivedNoteDialogOpen
          }
          selectedProcessChildType={selectedProcessChildType}
          setSelectedProcessChildType={setSelectedProcessChildType}
        />
      )}
      {/* Sidebar for selected outbound process */}
      {selectedOutboundProcess && (
        <WarehouseClientOutboundSideDrawer
          selectedOutboundProcess={selectedOutboundProcess}
          setSelectedOutboundProcess={setSelectedOutboundProcess}
          isOpen={isOutboundSidebarOpen}
          setIsOpen={setIsOutboundSidebarOpen}
          isDesktop={isDesktop}
          selectedOutboundProcessHasDescription={
            selectedOutboundProcessHasDescription
          }
          setIsViewDespatchNoteDialogOpen={setIsViewDespatchNoteDialogOpen}
          setIsViewPickingConfirmationDialogOpen={
            setIsViewPickingConfirmationDialogOpen
          }
          setIsViewOutboundShipmentNoteDialogOpen={
            setIsViewOutboundShipmentNoteDialogOpen
          }
          setIsViewProofOfDeliveryDialogOpen={
            setIsViewProofOfDeliveryDialogOpen
          }
          selectedProcessChildType={selectedProcessChildType}
          setSelectedProcessChildType={setSelectedProcessChildType}
        />
      )}
      {/* View shipment note */}
      <DialogModal
        open={isViewShipmentNoteDialogOpen}
        setOpen={setIsViewShipmentNoteDialogOpen}
        onClose={handleCloseViewShipmentNote}
        title='Shipment Note'
        id='view-shipment-note-title'
      >
        <ShipmentNoteDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedInboundProcess={selectedInboundProcess!}
          onClose={() => setIsViewShipmentNoteDialogOpen(false)}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* Create inbound shipment note form */}
      <DialogModal
        open={isCreateInboundShipmentNoteFormOpen}
        setOpen={setIsCreateInboundShipmentNoteFormOpen}
        onClose={handleCloseCreateInboundShipmentNoteForm}
        title='Create Inbound Shipment Note'
        id='create-inbound-shipment-note-form-title'
      >
        <CreateInboundShipmentNoteForm
          onClose={() => {
            setIsCreateInboundShipmentNoteFormOpen(false);
          }}
          onSubmit={handleSubmitInboundShipmentNote}
        />
      </DialogModal>
      {/* View signed-off goods received note */}
      <DialogModal
        open={isViewGoodsReceivedNoteDialogOpen}
        setOpen={setIsViewGoodsReceivedNoteDialogOpen}
        onClose={handleCloseViewGoodsReceivedNote}
        title='Goods Received Note'
        id='view-goods-received-note-form-title'
      >
        <GoodsReceivedNoteDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedInboundProcess={selectedInboundProcess!}
          onClose={() => {
            setIsViewGoodsReceivedNoteDialogOpen(false);
          }}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* Create despatch note */}
      <DialogModal
        open={isCreateDespatchNoteFormOpen}
        setOpen={setIsCreateDespatchNoteFormOpen}
        onClose={handleCloseCreateDespatchNoteForm}
        title='Create Despatch Note'
        id='despatch-note-form-title'
      >
        <CreateDespatchNoteForm
          onClose={() => {
            setIsCreateDespatchNoteFormOpen(false);
          }}
          onSubmit={handleSubmitDespatchNote}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* View despatch note details */}
      <DialogModal
        open={isViewDespatchNoteDialogOpen}
        setOpen={setIsViewDespatchNoteDialogOpen}
        onClose={handleCloseViewDespatchNote}
        title='Despatch Note'
        id='view-despatch-note-form-title'
      >
        <DespatchNoteDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => setIsViewDespatchNoteDialogOpen(false)}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* View picking confirmation */}
      <DialogModal
        open={isViewPickingConfirmationDialogOpen}
        setOpen={setIsViewPickingConfirmationDialogOpen}
        onClose={handleCloseViewPickingConfirmation}
        title='Picking Confirmation'
        id='view-picking-confirmation-form-title'
      >
        <PickingConfirmationDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => setIsViewPickingConfirmationDialogOpen(false)}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* View outbound shipment note */}
      <DialogModal
        open={isViewOutboundShipmentNoteDialogOpen}
        setOpen={setIsViewOutboundShipmentNoteDialogOpen}
        onClose={handleCloseViewOutboundShipmentNote}
        title='Outbound Shipment Note'
        id='view-outbound-shipment-note-form-title'
      >
        <OutboundShipmentNoteDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => setIsViewOutboundShipmentNoteDialogOpen(false)}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* View proof of delivery */}
      <DialogModal
        open={isViewProofOfDeliveryDialogOpen}
        setOpen={setIsViewProofOfDeliveryDialogOpen}
        onClose={handleCloseViewProofOfDelivery}
        title='Proof of Delivery'
        id='view-proof-of-delivery-form-title'
      >
        <ProofOfDeliveryDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => setIsViewProofOfDeliveryDialogOpen(false)}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
    </View>
  );
}

WarehouseClientNoticeboard.displayName = 'WarehouseClientNoticeboard';
