import { View } from '@views/View';
import { Heading } from '@components/Heading';
import { PageLink } from '@components/PageLink';
import { CreateInboundShipmentNoteForm } from '@forms/client/CreateInboundShipmentNoteForm';
import { DialogModal } from '@components/DialogModal';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import { ShipmentNoteDetails } from '@views/Noticeboard/components/ShipmentNoteDetails';
import { useAuth } from '@context';
import { UserRoleService } from '@services';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {
  useCloseDialog,
  useDespatchNote,
  useInboundProcess,
  useInboundShipmentNote,
  useOutboundProcess,
  useShipmentFilter,
  useShipments,
} from '@hooks';
import { InboundProcess, OutboundProcess } from '@features/supplyChain/types';
import { useMediaQuery } from 'usehooks-ts';
import ToggleButton from '@mui/material/ToggleButton';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { CreateDespatchNoteForm } from '@forms/client/CreateDespatchNoteForm';
import { Tooltip } from '@mui/material';
import {
  ShipmentFiltersContainer,
  ShipmentDateFromFilter,
  ShipmentDateToFilter,
  ShipmentExceptionsFilter,
  ShipmentSearchFilter,
  ShipmentStatusFilter,
  ShipmentTypeFilter,
  InboundShipmentsTable,
  OutboundShipmentsTable,
} from './components';
import {
  businessProfileAtom,
  selectedShipmentNoteNumberAtom,
} from '../../store/jotai';

dayjs.extend(advancedFormat);

export function Shipments() {
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const isTablet = useMediaQuery('(min-width: 48em)');
  const { user } = useAuth();
  const { isWarehouseClient, isWarehouseManager } = UserRoleService();
  const businessProfile = useAtomValue(businessProfileAtom);
  const [selectedShipmentNoteNumber, setSelectedShipmentNoteNumber] = useAtom(
    selectedShipmentNoteNumberAtom
  );
  const [isShipmentNoteModalOpen, setIsShipmentNoteModalOpen] = useState(false);
  const [selectedInboundProcess, setSelectedInboundProcess] = useState<
    InboundProcess | undefined
  >(undefined);
  const [selectedOutboundProcess, setSelectedOutboundProcess] = useState<
    OutboundProcess | undefined
  >(undefined);
  const {
    isCreateDespatchNoteFormOpen,
    setIsCreateDespatchNoteFormOpen,
    handleCloseCreateDespatchNoteForm,
    handleSubmitDespatchNote,
  } = useDespatchNote();

  const {
    searchFilter,
    handleChangeSearchFilter,
    shipmentType,
    handleChangeShipmentType,
    selectedStatus,
    handleChangeStatus,
    dateFrom,
    handleChangeDateFrom,
    dateTo,
    handleChangeDateTo,
    exceptions,
    handleToggleExceptions,
    isFilterContainerOpen,
    handleToggleFilterContainerOpen,
  } = useShipmentFilter();

  const {
    isCreateInboundShipmentNoteFormOpen,
    setIsCreateInboundShipmentNoteFormOpen,
    handleCloseCreateInboundShipmentNoteForm,
    handleSubmitInboundShipmentNote,
    supplyChainProcessResult,
    isShipmentNoteSuccessOpen,
    setIsShipmentNoteSuccessOpen,
    handleCloseShipmentNoteSuccess,
  } = useInboundShipmentNote();

  const {
    inboundShipments,
    fetchInboundShipments,
    isInitialInboundShipmentsFetch,
    setIsInitialInboundShipmentsFetch,
    isLoadingInboundShipments,
    isLoadingInboundShipmentsError,
    hasMoreInboundShipmentsData,
    outboundShipments,
    fetchOutboundShipments,
  } = useShipments();

  const { inboundProcesses } = useInboundProcess();

  const { outboundProcesses } = useOutboundProcess();

  // useEffect(() => {
  //   fetchInboundShipments();
  //   return () => {};
  // }, [inboundProcesses]);

  // useEffect(() => {
  //   fetchOutboundShipments();
  //   return () => {};
  // }, [outboundProcesses]);

  useEffect(() => {
    if (shipmentType === 'Inbound') {
      fetchInboundShipments();
    } else {
      fetchOutboundShipments();
    }
  }, [inboundProcesses, outboundProcesses, shipmentType]);

  useEffect(() => {
    if (selectedShipmentNoteNumber) {
      setIsShipmentNoteModalOpen(true);
      if (shipmentType === 'Inbound') {
        setSelectedInboundProcess(
          inboundShipments.find(
            (shipment) =>
              shipment.shipmentNote.shipmentNoteNumber ===
              selectedShipmentNoteNumber
          )
        );
      } else {
        setSelectedOutboundProcess(
          outboundShipments.find(
            (shipment) =>
              shipment.shipmentNote.shipmentNoteNumber ===
              selectedShipmentNoteNumber
          )
        );
      }
    } else {
      setIsShipmentNoteModalOpen(false);
      if (shipmentType === 'Inbound') {
        setSelectedInboundProcess(undefined);
      } else {
        setSelectedOutboundProcess(undefined);
      }
    }
  }, [selectedShipmentNoteNumber]);

  useEffect(() => {
    if (!isShipmentNoteModalOpen) {
      setSelectedShipmentNoteNumber('');
    }
  }, [isShipmentNoteModalOpen]);

  return (
    <View>
      <PageLink to='/noticeboard'>Noticeboard</PageLink>
      <Stack
        gap={16}
        direction={isTablet ? 'row' : 'column'}
        justifyContent='space-between'
        alignItems={isTablet ? 'center' : 'flex-start'}
      >
        <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
          {shipmentType === 'Inbound'
            ? 'Inbound Shipment Notes'
            : 'Outbound Shipments'}
        </Heading>
        <Stack direction='row' gap={16} width={isTablet ? 'auto' : '100%'}>
          {isWarehouseClient(user) && (
            <>
              <Button
                variant='outlined'
                size='large'
                startIcon={<UploadIcon />}
                sx={{ textTransform: 'none' }}
                fullWidth
              >
                Import
              </Button>
              {shipmentType === 'Outbound' &&
                !businessProfile?.hasConnectedBankAccount && (
                  <Tooltip
                    title={
                      user?.roles.includes('FinanceAdmin')
                        ? 'A bank account must be connected before you can create a despatch note. Please navigate to Account Profile to link a bank account.'
                        : 'A bank account must be connected before you can create a despatch note. Please contact your finance admin.'
                    }
                  >
                    <span>
                      <Button
                        variant='contained'
                        size='large'
                        startIcon={<AddIcon />}
                        sx={{ textTransform: 'none' }}
                        fullWidth
                        disabled
                      >
                        Create
                      </Button>
                    </span>
                  </Tooltip>
                )}
              {!(
                shipmentType === 'Outbound' &&
                !businessProfile?.hasConnectedBankAccount
              ) && (
                <Button
                  variant='contained'
                  size='large'
                  startIcon={<AddIcon />}
                  onClick={() =>
                    shipmentType === 'Inbound'
                      ? setIsCreateInboundShipmentNoteFormOpen(true)
                      : setIsCreateDespatchNoteFormOpen(true)
                  }
                  sx={{ textTransform: 'none' }}
                  fullWidth
                >
                  Create
                </Button>
              )}
            </>
          )}
          {!isDesktop && (
            <ToggleButton
              size='medium'
              value='Exceptions'
              selected={isFilterContainerOpen}
              onChange={handleToggleFilterContainerOpen}
              sx={{
                py: 8,
                color: 'var(--colors-gray12)',
                textTransform: 'none',
              }}
            >
              <TuneIcon />
            </ToggleButton>
          )}
        </Stack>
      </Stack>
      {/* Shipment filters */}
      <ShipmentFiltersContainer
        isDesktop={isDesktop}
        isOpen={isFilterContainerOpen}
      >
        <ShipmentSearchFilter
          value={searchFilter}
          onChange={handleChangeSearchFilter}
          sx={{
            gridColumn: isWarehouseManager(user) ? '6 / 7' : '5 / 7',
            gridRow: 1,
          }}
        />
        <ShipmentTypeFilter
          value={shipmentType}
          onChange={handleChangeShipmentType}
        />
        <ShipmentStatusFilter
          value={selectedStatus}
          onChange={handleChangeStatus}
        />
        <ShipmentDateFromFilter
          value={dateFrom}
          onChange={handleChangeDateFrom}
        />
        <ShipmentDateToFilter value={dateTo} onChange={handleChangeDateTo} />
        {isWarehouseManager(user) && (
          <ShipmentExceptionsFilter
            selected={exceptions}
            onChange={handleToggleExceptions}
          />
        )}
        {!isDesktop && (
          <Button
            variant='contained'
            size='medium'
            startIcon={<CloseIcon />}
            onClick={handleToggleFilterContainerOpen}
            sx={{ textTransform: 'none' }}
            fullWidth
          >
            Close filters
          </Button>
        )}
      </ShipmentFiltersContainer>
      {/* Shipments table here */}
      {shipmentType === 'Inbound' && (
        <InboundShipmentsTable inboundProcesses={inboundShipments} />
      )}
      {shipmentType === 'Outbound' && (
        <OutboundShipmentsTable outboundProcesses={outboundShipments} />
      )}
      {/* Shipper - Create Inbound Shipment Note Form */}
      {isWarehouseClient(user) && (
        <DialogModal
          open={isCreateInboundShipmentNoteFormOpen}
          setOpen={setIsCreateInboundShipmentNoteFormOpen}
          onClose={handleCloseCreateInboundShipmentNoteForm}
          title='Create Inbound Shipment Note'
          id='create-inbound-shipment-note-form-title'
        >
          <CreateInboundShipmentNoteForm
            onClose={() => {
              setIsCreateInboundShipmentNoteFormOpen(false);
            }}
            onSubmit={handleSubmitInboundShipmentNote}
          />
        </DialogModal>
      )}
      {isWarehouseClient(user) && (
        <DialogModal
          open={isCreateDespatchNoteFormOpen}
          setOpen={setIsCreateDespatchNoteFormOpen}
          onClose={handleCloseCreateDespatchNoteForm}
          title='Create Outbound Shipment Note'
          id='create-outbound-shipment-note-form-title'
        >
          <CreateDespatchNoteForm
            onClose={() => {
              setIsCreateDespatchNoteFormOpen(false);
            }}
            onSubmit={handleSubmitDespatchNote}
            isDesktop={isDesktop}
            isTablet={isTablet}
          />
        </DialogModal>
      )}
      {/* Shipper - Inbound Shipment Note Form Submission Success Alert Dialog */}
      {isWarehouseClient(user) && supplyChainProcessResult && (
        <DialogModal
          open={isShipmentNoteSuccessOpen}
          setOpen={setIsShipmentNoteSuccessOpen}
          onClose={handleCloseShipmentNoteSuccess}
          title='Shipment Note'
          id='view-shipment-note-title'
        >
          <ShipmentNoteDetails
            selectedInboundProcess={supplyChainProcessResult}
            onClose={() => setIsShipmentNoteSuccessOpen(false)}
            isDesktop={isDesktop}
            isTablet={isTablet}
          />
        </DialogModal>
      )}
      {isShipmentNoteModalOpen && selectedInboundProcess && (
        <DialogModal
          open={isShipmentNoteModalOpen}
          setOpen={setIsShipmentNoteModalOpen}
          onClose={() => {
            setIsShipmentNoteModalOpen(false);
            setSelectedShipmentNoteNumber('');
          }}
          title='Shipment Note'
          id='view-shipment-note-title'
        >
          <ShipmentNoteDetails
            selectedInboundProcess={selectedInboundProcess}
            onClose={() => {
              setIsShipmentNoteModalOpen(false);
              setSelectedShipmentNoteNumber('');
            }}
            isDesktop={isDesktop}
            isTablet={isTablet}
          />
        </DialogModal>
      )}
      {isShipmentNoteModalOpen && selectedOutboundProcess && (
        <DialogModal
          open={isShipmentNoteModalOpen}
          setOpen={setIsShipmentNoteModalOpen}
          onClose={() => {
            setIsShipmentNoteModalOpen(false);
            setSelectedShipmentNoteNumber('');
          }}
          title='Shipment Note'
          id='view-shipment-note-title'
        >
          <ShipmentNoteDetails
            selectedInboundProcess={
              selectedOutboundProcess as unknown as InboundProcess
            }
            onClose={() => {
              setIsShipmentNoteModalOpen(false);
              setSelectedShipmentNoteNumber('');
            }}
            isDesktop={isDesktop}
            isTablet={isTablet}
          />
        </DialogModal>
      )}
    </View>
  );
}
